import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Pasted from "../../../assests/Calculater Theme.jpg";
import Home from "../../../assests/Calculator.jpg";
import bgImg from "../../../assests/Maths.jpg";
import Header from "../../../components/Header";
import HomeFooter from "../HomeFooter";
import { useLocation, useNavigate, useParams } from "react-router-dom";
const TermsAndConditions = () => {
  const navigate = useNavigate();
  const email = "info@investpulse.com.au";

  return (
    <>
      <Header />
      <Container maxWidth="lg">
        <Grid container mb={10}>
          <Grid item xs={12} md={12}>
            <Stack mt={3} alignItems={"center"} justifyContent={"center"}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                WEBSITE TERMS AND CONDITIONS
              </Typography>
            </Stack>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                1
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  INTRODUCTION
                </Typography>
              </Stack>
              {/* <Stack spacing={1}>
                <Typography mt={2}>
                  This document sets out the privacy policy of Gritpro Pty Ltd
                  ACN 613 057 097 (referred to in this privacy policy as
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    ‘we’, ‘us’,
                  </span>{" "}
                  or{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    ‘our’
                  </span>{" "}
                  )
                </Typography>
              </Stack> */}
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    These terms and conditions&nbsp;
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      (Terms)
                    </span>
                    &nbsp;apply when you use this website,&nbsp;
                    <a
                      style={{
                        color: "#33c7c1",
                      }}
                      href="https://investpulse.com.au/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.investpulse.com.au &nbsp;
                    </a>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      (Website).&nbsp;
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    You agree to be bound by these Terms which form a binding
                    contractual agreement between you and us, Gritpro Pty Ltd
                    ACN 613 057 097&nbsp;{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      (Investpulse, our, we or us).
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    If you don’t agree to these Terms, you must refrain from
                    using the Website.
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(d)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    We may change these Terms at any time by updating this page
                    of the Website, and your continued use of the Website
                    following such an update will represent an agreement by you
                    to be bound by the Terms as amended.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                2
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  ACCESS AND USE OF THE WEBSITE
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={2}>
                  You must only use the Website in accordance with these Terms
                  and any applicable laws, and must ensure that your employees,
                  sub-contractors and any other agents who use or access the
                  Website comply with the Terms and any applicable laws.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                3
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  YOUR OBLIGATIONS{" "}
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={2}>You must not:</Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    copy, mirror, reproduce, translate, adapt, vary, modify,
                    sell, decipher or decompile any part or aspect of the
                    Website without our express consent;
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    use the Website for any purpose other than the purposes of
                    browsing, selecting or purchasing goods;
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    use, or attempt to use, the Website in a manner that is
                    illegal or fraudulent or facilitates illegal or fraudulent
                    activity;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(d)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    use, or attempt to use, the Website in a manner that may
                    interfere with, disrupt or create undue burden on the
                    Website or the servers or networks that host the Website;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(e)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    use the Website with the assistance of any automated
                    scripting tool or software;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(f)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    act in a way that may diminish or adversely impact our
                    reputation, including by linking to the Website on any other
                    website; and{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(g)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    attempt to breach the security of the Website, or otherwise
                    interfere with the normal functions of the Website,
                    including by:{" "}
                  </Typography>
                  <Grid container spacing={1} mt={2}>
                    <Grid item xs={1}>
                      <Typography>(i)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        gaining unauthorised access to Website accounts or data;
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(ii)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        scanning, probing or testing the Website for security
                        vulnerabilities;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(iii)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        overloading, flooding, mailbombing, crashing or
                        submitting a virus to the Website; or
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(iv)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        instigate or participate in a denial-of-service attack
                        against the Website.{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Stack spacing={1} mt={1}>
                <Typography mt={2}>
                  We may also collect personal information from you when you use
                  or access our website or our social media pages. This may be
                  done through use of web analytics tools, ‘cookies’ or other
                  similar tracking technologies that allow us to track and
                  analyse your website usage. Cookies are small files that store
                  information on your computer, mobile phone or other device and
                  enable and allow the creator of the cookie to identify when
                  you visit different websites. If you do not wish information
                  to be stored as a cookie, you can disable cookies in your web
                  browser.{" "}
                </Typography>
                <Typography>
                  We may use Google Analytics to collect and process data,
                  including when you use third party websites or apps. To find
                  out more see&nbsp;
                  <span>
                    <a
                      style={{
                        color: "#33c7c1",
                      }}
                      href="https://policies.google.com/technologies/partner-sites"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      How Google uses data when you use our partners’ sites or
                      apps.
                    </a>
                  </span>
                </Typography>
              </Stack> */}
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                4
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  INFORMATION ON THE WEBSITE{" "}
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={2}>
                  While we will use our best endeavours to ensure the Website is
                  as up-to-date and accurate as possible, you acknowledge and
                  agree that from time to time, you may encounter the following
                  issues:{" "}
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    the Website may have errors or defects;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    the Website may not be accessible at times;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    messages sent through the Website may not be delivered
                    promptly, or delivered at all;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(d)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    information you receive or supply through the Website may
                    not be secure or confidential; or{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(e)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    any information provided through the Website may not be
                    accurate or true.{" "}
                  </Typography>
                </Grid>
              </Grid>
              <Stack spacing={1} mt={1}>
                <Typography mt={2}>
                  We reserve the right to change any information or
                  functionality on the Website by updating the Website at any
                  time without notice, including product descriptions, prices
                  and other Website Content.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                5
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  INTELLECTUAL PROPERTY
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    We retain ownership of the Website and all materials on the
                    Website (including text, graphics, logos, design, icons,
                    images, sound and video recordings, pricing, downloads and
                    software){" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      (Website Content)
                    </span>{" "}
                    and reserves all rights in any intellectual property rights
                    owned or licensed by it not expressly granted to you.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    You may make a temporary electronic copy of all or part of
                    the Website for the sole purpose of viewing it. You must not
                    otherwise reproduce, transmit, adapt, distribute, sell,
                    modify or publish the Website or any Website Content without
                    prior written consent from us or as permitted by law.{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                6
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  LINKS TO OTHER WEBSITES{" "}
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    The Website may contain links to other websites that are not
                    our responsibility. We have no control over the content of
                    the linked websites, and we are not responsible for it.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    Inclusion of any linked website on the Website does not
                    imply our approval or endorsement of the linked website.{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                7
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  SECURITY{" "}
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={3}>
                  We do not accept responsibility for loss or damage to computer
                  systems, mobile phones or other electronic devices arising in
                  connection with use of the Website. You should take your own
                  precautions to ensure that the process that you employ for
                  accessing the Website does not expose you to risk of viruses,
                  malicious computer code or other forms of interference.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                8
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  REPORTING MISUSE
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={3}>
                  If you become aware of misuse of the Website by any person,
                  any errors in the material on the Website or any difficulty in
                  accessing or using the Website, please contact us immediately
                  using the contact details or form provided on our Website.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                9
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  PRIVACY
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={3}>
                  You agree to be bound by our Privacy Policy, which can be
                  found www.investpulse.com.au/privacy{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                10
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  LIABILITY
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={3}>
                  We make no warranties or representations about this Website or
                  any of its content and will not be responsible to you or any
                  third party for any direct or consequential loss suffered in
                  connection with the use of this Website. To the maximum extent
                  permitted by law, we each exclude each other from any
                  liability that may arise due to your use of our Website and/or
                  the information or materials contained on it.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                11
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  GENERAL
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontSize: "16px",
                }}
              >
                11.1
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontSize: "16px",
                  }}
                >
                  GOVERNING LAW AND JURISDICTION
                </Typography>
              </Stack>
              <Stack spacing={1} mt={1}>
                <Typography mt={3}>
                  This agreement is governed by the law applying in New South
                  Wales, Australia. Each party irrevocably submits to the
                  exclusive jurisdiction of the courts of New South Wales,
                  Australia and courts of appeal from them in respect of any
                  proceedings arising out of or in connection with this
                  agreement. Each party irrevocably waives any objection to the
                  venue of any legal process on the basis that the process has
                  been brought in an inconvenient forum.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontSize: "16px",
                }}
              >
                11.2
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontSize: "16px",
                  }}
                >
                  WAIVER{" "}
                </Typography>
              </Stack>
              <Stack spacing={1} mt={1}>
                <Typography mt={3}>
                  No party to this agreement may rely on the words or conduct of
                  any other party as a waiver of any right unless the waiver is
                  in writing and signed by the party granting the waiver.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontSize: "16px",
                }}
              >
                11.3
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontSize: "16px",
                  }}
                >
                  SEVERANCE{" "}
                </Typography>
              </Stack>
              <Stack spacing={1} mt={1}>
                <Typography mt={3}>
                  Any term of this agreement which is wholly or partially void
                  or unenforceable is severed to the extent that it is void or
                  unenforceable. The validity and enforceability of the
                  remainder of this agreement is not limited or otherwise
                  affected.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontSize: "16px",
                }}
              >
                11.4
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontSize: "16px",
                  }}
                >
                  JOINT AND SEVERAL LIABILITY{" "}
                </Typography>
              </Stack>
              <Stack spacing={1} mt={1}>
                <Typography mt={3}>
                  An obligation or a liability assumed by, or a right conferred
                  on, two or more persons binds or benefits them jointly and
                  severally.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontSize: "16px",
                }}
              >
                11.5
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontSize: "16px",
                  }}
                >
                  ASSIGNMENT{" "}
                </Typography>
              </Stack>
              <Stack spacing={1} mt={1}>
                <Typography mt={3}>
                  A party cannot assign, novate or otherwise transfer any of its
                  rights or obligations under this agreement without the prior
                  written consent of the other party.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontSize: "16px",
                }}
              >
                11.6
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontSize: "16px",
                  }}
                >
                  ENTIRE AGREEMENT{" "}
                </Typography>
              </Stack>
              <Stack spacing={1} mt={1}>
                <Typography mt={3}>
                  This agreement embodies the entire agreement between the
                  parties and supersedes any prior negotiation, conduct,
                  arrangement, understanding or agreement, express or implied,
                  in relation to the subject matter of this agreement.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontSize: "16px",
                }}
              >
                11.7
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontSize: "16px",
                  }}
                >
                  INTERPRETATION
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      (singular and plural)
                    </span>{" "}
                    words in the singular includes the plural (and vice versa);{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (gender){" "}
                    </span>{" "}
                    words indicating a gender includes the corresponding words
                    of any other gender;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (defined terms)
                    </span>{" "}
                    if a word or phrase is given a defined meaning, any other
                    part of speech or grammatical form of that word or phrase
                    has a corresponding meaning;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(d)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (person)
                    </span>{" "}
                    a reference to “person” or “you” includes an individual, the
                    estate of an individual, a corporation, an authority, an
                    association, consortium or joint venture (whether
                    incorporated or unincorporated), a partnership, a trust and
                    any other entity;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(e)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (party){" "}
                    </span>{" "}
                    a reference to a party includes that party’s executors,
                    administrators, successors and permitted assigns, including
                    persons taking by way of novation and, in the case of a
                    trustee, includes any substituted or additional trustee;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(f)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (this agreement){" "}
                    </span>{" "}
                    a reference to a party, clause, paragraph, schedule,
                    exhibit, attachment or annexure is a reference to a party,
                    clause, paragraph, schedule, exhibit, attachment or annexure
                    to or of this agreement, and a reference to this agreement
                    includes all schedules, exhibits, attachments and annexures
                    to it;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(g)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (document)
                    </span>{" "}
                    a reference to a document (including this agreement) is to
                    that document as varied, novated, ratified or replaced from
                    time to time;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(h)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (headings){" "}
                    </span>{" "}
                    headings and words in bold type are for convenience only and
                    do not affect interpretation;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(i)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (includes){" "}
                    </span>{" "}
                    the word “includes” and similar words in any form is not a
                    word of limitation;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(j)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (adverse interpretation){" "}
                    </span>{" "}
                    no provision of this agreement will be interpreted adversely
                    to a party because that party was responsible for the
                    preparation of this agreement or that provision; and{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(k)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (adverse interpretation){" "}
                    </span>{" "}
                    no provision of this agreement will be interpreted adversely
                    to a party because that party was responsible for the
                    preparation of this agreement or that provision; and{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <HomeFooter />
    </>
  );
};

export default TermsAndConditions;
