import React, { useEffect, useState } from "react";
import Home from "../../../components/SidebarWithTopBar";
import "./index.css";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import History from "../../../assests/history.png";
import Home1 from "../../../assests/homee.jpg";
import Home2 from "../../../assests/homrr.jpg";
import Home3 from "../../../assests/homrre.jpg";
import Slide1 from "../../../assests/slide1.jpg";
import Slide2 from "../../../assests/slide2.jpg";
import Slide3 from "../../../assests/slide3.jpg";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import CollectionsIcon from "@mui/icons-material/Collections";
import VideocamIcon from "@mui/icons-material/Videocam";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const data = [
  { id: 1, img: Home1 },
  { id: 2, img: Home2 },
  { id: 3, img: Home3 },
  { id: 4, img: Home1 },
  { id: 5, img: Home2 },
  { id: 6, img: Home3 },
];

const dataProperty = [
  { id: 1, img: "Ensuite" },
  { id: 2, img: "Remote Garage" },
  { id: 3, img: "Air Conditioning" },
  { id: 4, img: "Alarm System" },
  { id: 5, img: "Intercom" },
  { id: 6, img: "Pool In-Ground" },
  { id: 6, img: "Balcony" },
  { id: 6, img: "Deck" },
  { id: 6, img: "Fully Fenced" },
  { id: 6, img: "Broadband" },
  { id: 6, img: "Built-in Robes" },
  { id: 6, img: "Dishwasher" },
  { id: 6, img: "Ducted Cooling" },
  { id: 6, img: "Ducted Heating" },
  { id: 6, img: "Floorboards" },
  { id: 6, img: "Gas Heating" },
  { id: 6, img: "Rumpus Room" },
  { id: 6, img: "Study" },
];
const MarketSearchResults = () => {
  const [btnCapital, setBtnCapital] = useState(false);
  const [cardHower, setCardHower] = useState(false);

  return (
    <Home>
      <Stack marginTop={10}>
        <Grid container>
          <Grid item xs={3}>
            <Stack
              padding={1}
              sx={{
                backgroundColor: "#fff",
                maxHeight: "calc(100vh - 100px)",
                overflowY: "auto",
              }}
            >
              <Stack direction={"row"} spacing={1}>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  sx={{
                    backgroundColor: "#eeeeee",
                    padding: "5px",
                    borderRadius: 3,
                  }}
                >
                  <Stack
                    sx={{
                      padding: "5px",
                      width: "100%",
                      fontWeight: 700,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize:'13px',
                      backgroundColor:
                        btnCapital === false ? "#fff" : "#eeeeee",
                      //   borderColor: "#7030a0",
                      borderRadius: 3,
                      boxShadow: btnCapital === false ? 1 : 0,
                      color: btnCapital === false ? "#00004d" : "#99979c",
                      cursor: "pointer",
                    }}
                    onClick={() => setBtnCapital(false)}
                  >
                    Single Card
                  </Stack>
                  <Stack
                    sx={{
                      padding: "5px",
                      width: "100%",
                      fontWeight: 700,
                      fontSize:'13px',
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      //   borderColor: "#7030a0",
                      backgroundColor: btnCapital === true ? "#fff" : "#eeeeee",
                      color: btnCapital === true ? "#00004d" : "#99979c",
                      borderRadius: 3,
                      boxShadow: btnCapital === true ? 1 : 0,
                      cursor: "pointer",
                    }}
                    onClick={() => setBtnCapital(true)}
                  >
                    View all
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    backgroundColor: "#eeeeee",
                    padding: 1,
                    borderRadius: 3,
                    width: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={History} alt="" height={20} width={20} />
                </Stack>
              </Stack>

              <Stack
                mt={2}
                sx={{
                  border: "solid",
                  borderWidth: 1,
                  borderColor: "#dddddd",
                  borderRadius: 4,
                }}
              >
                <Stack
                  padding={1}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack
                      sx={{
                        backgroundColor: "#eeeeee",
                        padding: 1,
                        borderRadius: 3,
                        width: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      m
                    </Stack>
                    <Stack>
                      <Typography>Match profile for</Typography>
                      <Typography
                        sx={{
                          color: "#00004d",
                          fontWeight: 600,
                        }}
                      >
                        Guest User
                      </Typography>
                    </Stack>
                  </Stack>
                  <Typography
                    sx={{
                      color: "#24003D",
                      fontWeight: 600,
                    }}
                  >
                    Reset
                  </Typography>
                </Stack>
                <Divider />

                <Stack>
                  <Stack padding={2}>
                    <Typography
                      sx={{
                        color: "#00004d",
                        fontWeight: 600,
                        textAlign: "center",
                        lineHeight: 1,
                      }}
                    >
                      Save your profile and get instant property alerts for new
                      matches
                    </Typography>
                  </Stack>

                  <Stack
                    width={"100%"}
                    padding={2}
                    direction={"row"}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Stack
                      padding={1}
                      width={"40%"}
                      sx={{
                        border: "solid",
                        borderWidth: 1,
                        borderColor: "#dddddd",
                        borderRadius: 3,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#24003D",
                        color: "#fff",
                        cursor:'pointer'
                      }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>Sign up</Typography>
                    </Stack>
                    <Stack
                      padding={1}
                      width={"40%"}
                      sx={{
                        border: "solid",
                        borderWidth: 1,
                        borderColor: "#dddddd",
                        borderRadius: 3,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#777",
                        cursor:'pointer'

                      }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>Log in</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

              <Typography mt={2} mb={1}>
                Your ideal requirements
              </Typography>
              <Stack
                sx={{
                  border: "solid",
                  borderWidth: 1,
                  borderColor: "#dddddd",
                  borderRadius: 4,
                }}
              >
                <Stack
                  padding={1}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack>
                      <Typography>You would like to live in</Typography>
                      <Typography
                        sx={{
                          color: "#00004d",
                          fontWeight: 700,
                        }}
                      >
                        De Grey
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Divider />
                <Stack
                  padding={1}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack>
                      <Typography>You are looking to</Typography>
                      <Typography
                        sx={{
                          color: "#00004d",
                          fontWeight: 700,
                        }}
                      >
                        Buy at any price
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Divider />
                <Stack
                  padding={1}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack>
                      <Typography>Bedrooms, bathrooms and carparks</Typography>
                      <Typography
                        sx={{
                          color: "#00004d",
                          fontWeight: 700,
                        }}
                      >
                        No bedroom Preferences
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Divider />
                <Stack
                  padding={1}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack>
                      <Typography>Property type</Typography>
                      <Typography
                        sx={{
                          color: "#00004d",
                          fontWeight: 700,
                        }}
                      >
                        All property types
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

              <Typography mt={2} mb={1}>
                Other features you're looking for
              </Typography>
              <Stack
                sx={{
                  border: "solid",
                  borderWidth: 1,
                  borderColor: "#dddddd",
                  borderRadius: 4,
                }}
              >
                <Stack
                  padding={1}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack>
                      <Typography>Features & amenities</Typography>
                      <Typography
                        sx={{
                          color: "#00004d",
                          fontWeight: 700,
                        }}
                      >
                        Add features
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Divider />
                <Stack
                  padding={1}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack>
                      <Typography>Land size</Typography>
                      <Typography
                        sx={{
                          color: "#00004d",
                          fontWeight: 700,
                        }}
                      >
                        Add land size
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

              <Typography mt={2} mb={1}>
                Show travel times to
              </Typography>
              <Stack
                sx={{
                  border: "solid",
                  borderWidth: 1,
                  borderColor: "#dddddd",
                  borderRadius: 4,
                }}
              >
                <Stack
                  padding={1}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack>
                      <Typography>Favourite destinations</Typography>
                      <Typography
                        sx={{
                          color: "#00004d",
                          fontWeight: 700,
                        }}
                      >
                        Add favourite destination
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

              <Typography mt={2} mb={1}>
                Your property journey
              </Typography>
              <Stack
                sx={{
                  border: "solid",
                  borderWidth: 1,
                  borderColor: "#dddddd",
                  borderRadius: 4,
                }}
              >
                <Stack
                  padding={1}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack>
                      <Typography>Current status</Typography>
                      <Typography
                        sx={{
                          color: "#00004d",
                          fontWeight: 700,
                        }}
                      >
                        Just browsing for a new home without finances approved
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Grid>

          {btnCapital === true ? (
            <>
              <Grid item xs={9}>
                <Stack
                
                  sx={{
                    backgroundColor: "#fff",
                    maxHeight: "calc(100vh - 100px)",
                    overflowY: "auto",
                  }}
                >
                  <Stack
                  mt={1}
                    direction={"row"}
                    spacing={1}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontWeight: 700 }}>
                      6 matches to rank
                    </Typography>
                    <img
                      src="https://soho.com.au/_nextassets/icons/info_DCDADF.svg"
                      alt=""
                      height={15}
                      width={15}
                    />
                  </Stack>
                  <Stack py={2} width={"20%"}>
                    <BorderLinearProgress
                      variant="determinate"
                      sx={{
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#00bfa8",
                        },
                      }}
                      value={10}
                    />
                  </Stack>
                  <Grid container spacing={2}>
                    {data &&
                      data.map((item, index) => {
                        return (
                          <>
                            <Grid item xs={4}>
                              <Stack
                                key={item.id}
                                onMouseEnter={() => setCardHower(true)}
                                onMouseLeave={() => setCardHower(false)}
                                sx={{
                                  border: "solid",
                                  borderWidth: 1,
                                  borderColor: "#dddddd",
                                  borderRadius: 4,
                                  cursor: "pointer",
                                  "&:hover": {
                                    borderColor: "#dddddd",
                                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                                    zIndex: 1,
                                  },
                                }}
                              >
                                <Box>
                                  <img
                                    src={item.img}
                                    alt=""
                                    width={"100%"}
                                    style={{
                                      borderTopLeftRadius: "15px",
                                      borderTopRightRadius: "15px",
                                    }}
                                  />
                                </Box>
                                <Stack padding={2}>
                                  <Stack direction={"row"} spacing={1}>
                                    <Typography
                                      sx={{ color: "#00bfa8", fontWeight: 700 }}
                                    >
                                      For Sale
                                    </Typography>
                                    <Typography
                                      sx={{ color: "#99979c", fontWeight: 700 }}
                                    >
                                      House
                                    </Typography>
                                    <Typography
                                      sx={{ color: "#99979c", fontWeight: 700 }}
                                    >
                                      3w
                                    </Typography>
                                  </Stack>
                                  <Typography
                                    sx={{
                                      color: "#00004d",
                                      fontWeight: 700,
                                      fontSize: 18,
                                    }}
                                  >
                                    Contact Agent
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#00004d",
                                      fontWeight: 500,
                                      fontSize: 13,
                                    }}
                                  >
                                    78 Foxall Road, North Kellyville NSW 2155
                                  </Typography>
                                  <Stack direction={"row"} spacing={2}>
                                    <Stack direction={"row"} spacing={1}>
                                      <Typography>4</Typography>
                                      <Typography>mm</Typography>
                                    </Stack>
                                    <Stack direction={"row"} spacing={1}>
                                      <Typography>4</Typography>
                                      <Typography>mm</Typography>
                                    </Stack>
                                    <Stack direction={"row"} spacing={1}>
                                      <Typography>4</Typography>
                                      <Typography>mm</Typography>
                                    </Stack>
                                  </Stack>
                                </Stack>
                                <Divider />
                                <Stack
                                  width={"100%"}
                                  direction={"row"}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Stack
                                    py={1}
                                    direction={"row"}
                                    width={"100%"}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor:
                                        cardHower === true
                                          ? "#591fff"
                                          : "#f6f6f6",
                                      borderBottomLeftRadius: "15px",
                                    }}
                                  >
                                    <CloseIcon
                                      sx={{
                                        color:
                                          cardHower === true
                                            ? "#fff"
                                            : "#591fff",
                                        fontSize: 15,
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        color:
                                          cardHower === true
                                            ? "#fff"
                                            : "#591fff",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Nope
                                    </Typography>
                                  </Stack>
                                  <Divider
                                    orientation="vertical"
                                    flexItem
                                  ></Divider>
                                  <Stack
                                    width={"100%"}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{ color: "#99979c", fontWeight: 700 }}
                                    >
                                      Maybe
                                    </Typography>
                                  </Stack>
                                  <Divider
                                    orientation="vertical"
                                    flexItem
                                  ></Divider>
                                  <Stack
                                    py={1}
                                    direction={"row"}
                                    width={"100%"}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor:
                                        cardHower === true
                                          ? "#00bfa8"
                                          : "#f6f6f6",
                                      borderBottomRightRadius: "15px",
                                    }}
                                  >
                                    <DoneIcon
                                      sx={{
                                        fontSize: 15,
                                        color:
                                          cardHower === true
                                            ? "#fff"
                                            : "#00bfa8",
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        color:
                                          cardHower === true
                                            ? "#fff"
                                            : "#00bfa8",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Shortlist
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Stack>
                            </Grid>
                          </>
                        );
                      })}
                  </Grid>
                </Stack>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6}>
                
                <Stack
                  padding={1}
                  sx={{
                    backgroundColor: "#fff",
                    maxHeight: "calc(100vh - 100px)",
                    overflowY: "auto",
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={1}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontWeight: 700 }}>
                      6 matches to rank
                    </Typography>
                    <img
                      src="https://soho.com.au/_nextassets/icons/info_DCDADF.svg"
                      alt=""
                      height={15}
                      width={15}
                    />
                  </Stack>
                  <Stack py={2} width={"20%"}>
                    <BorderLinearProgress
                      variant="determinate"
                      sx={{
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#00bfa8",
                        },
                      }}
                      value={10}
                    />
                  </Stack>
                  <Stack>
                    <Stack
                      sx={{
                        boxShadow: 7,
                        borderRadius: 20,
                        position: "relative",
                      }}
                    >
                      <img
                        src={Slide1}
                        alt=""
                        height={300}
                        style={{ borderRadius: 20 }}
                      />
                      <Stack
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          padding: 2,
                        }}
                      >
                        <Typography sx={{ color: "#fff" }}>
                          House • 4mo
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontWeight: 700,
                            fontSize: 18,
                          }}
                        >
                          Contact Agent
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontWeight: 500,
                            fontSize: 15,
                          }}
                        >
                          24 Eden Road, North Kellyville NSW 2155
                        </Typography>
                        <Stack direction={"row"} spacing={1}>
                          <Typography sx={{ color: "#fff" }}>1</Typography>
                          <Typography sx={{ color: "#fff" }}>2</Typography>
                          <Typography sx={{ color: "#fff" }}>3</Typography>
                        </Stack>
                        <Stack direction={"row"}>
                          <Stack
                            direction={"row"}
                            boxShadow={3}
                            mr={"1px"}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              borderTopLeftRadius: 3,
                              borderBottomLeftRadius: 3,
                              width: "40px",
                            }}
                          >
                            <CollectionsIcon
                              sx={{
                                color: "#fff",
                                width: "13px",
                                height: "13px",
                              }}
                            />
                            <Typography sx={{ color: "#fff" }}>20</Typography>
                          </Stack>
                          <Stack
                            boxShadow={3}
                            direction={"row"}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              width: "40px",
                            }}
                          >
                            <VideocamIcon
                              sx={{
                                color: "#fff",
                                width: "13px",
                                height: "13px",
                              }}
                            />
                            <Typography sx={{ color: "#fff" }}>5</Typography>
                          </Stack>
                          <Stack
                            boxShadow={3}
                            ml={"1px"}
                            direction={"row"}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              borderTopRightRadius: 3,
                              borderBottomRightRadius: 3,
                              width: "40px",
                            }}
                          >
                            <SpaceDashboardIcon
                              sx={{
                                color: "#fff",
                                width: "13px",
                                height: "13px",
                              }}
                            />
                            <Typography sx={{ color: "#fff" }}>1</Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    py={4}
                    spacing={2}
                    direction={"row"}
                    width={"100%"}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      sx={{
                        boxShadow: 1,
                        borderRadius: 2,
                        width: "100px",
                        height: "41px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#591fff",
                        cursor: "pointer",
                      }}
                    >
                      <CloseIcon
                        sx={{
                          color: "#fff",
                          fontSize: 15,
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 700,
                        }}
                      >
                        Nope
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      sx={{
                        boxShadow: 1,
                        borderRadius: 2,
                        width: "100px",
                        height: "41px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <CloseIcon
                        sx={{
                          color: "#777",
                          fontSize: 15,
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#777",
                          fontWeight: 700,
                        }}
                      >
                        Maybe
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      sx={{
                        boxShadow: 1,
                        borderRadius: 2,
                        width: "100px",
                        height: "41px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#00bfa8",
                        cursor: "pointer",
                      }}
                    >
                      <DoneIcon
                        sx={{
                          color: "#fff",
                          fontSize: 15,
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 700,
                        }}
                      >
                        Shortlist
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={3}>
                <Stack
                  padding={1}
                  sx={{
                    backgroundColor: "#fff",
                    maxHeight: "calc(100vh - 100px)",
                    overflowY: "auto",
                    boxShadow: 3,
                  }}
                >
                  <Stack>
                    <Stack
                      mt={2}
                      sx={{
                        border: "solid",
                        borderWidth: 1,
                        borderColor: "#dddddd",
                        borderRadius: 4,
                      }}
                    >
                      <Stack
                        width={"100%"}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography>name</Typography>
                      </Stack>
                      <Divider />

                      <Stack
                        padding={1}
                        direction={"row"}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Stack
                          direction={"row"}
                          spacing={2}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Stack
                            sx={{
                              backgroundColor: "#eeeeee",
                              padding: 1,
                              borderRadius: 3,
                              width: "40px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            m
                          </Stack>
                          <Stack>
                            <Typography>Match profile for</Typography>
                            <Stack direction={"row"} spacing={1}>
                              <Typography
                                sx={{
                                  fontWeight: 700,
                                  color: "#f4b63e",
                                }}
                              >
                                4.9
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#00004d",
                                  fontWeight: 700,
                                }}
                              >
                                (34 reviews)
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack mt={2}>Property features</Stack>
                  <Grid container spacing={2}>
                    {dataProperty &&
                      dataProperty.map((item, index) => {
                        return (
                          <>
                            <Grid item xs={6} key={index}>
                              <Stack
                                direction={"row"}
                                sx={{
                                  borderRadius: 2,
                                  border: "solid",
                                  borderWidth: 1,
                                  borderColor: "#f4f2f7",
                                  width: "100%",
                                  height: "41px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#000",
                                    fontWeight: 700,
                                    fontSize: 12,
                                  }}
                                >
                                  {item.img}
                                </Typography>
                              </Stack>
                            </Grid>
                          </>
                        );
                      })}
                  </Grid>
                  <Stack mt={2}>Your travel time to</Stack>
                  <Stack>
                    <img
                      src="	https://assets.soho.com.au/production/uploads/loca…0132/map_4bc112e9-3c45-47e5-925f-52900a7a43f2.png"
                      alt=""
                    />
                  </Stack>
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
      </Stack>
    </Home>
  );
};

export default MarketSearchResults;
