import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Pasted from "../../../assests/Calculater Theme.jpg";
import Home from "../../../assests/Calculator.jpg";
import bgImg from "../../../assests/Maths.jpg";
import Header from "../../../components/Header";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import HomeFooter from "../HomeFooter";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    minWidth: 150,
    border: "1px solid #000",
    borderRadius: 0,
  },
  verticalHeader: {
    backgroundColor: "lightgray",
    color: "#000",
    fontWeight: "bold",
    width: "20%",
    // borderBottom: "1px solid #000", // Border between data cells
    // borderRight: "1px solid #000", // Border between header cells
    alignItems: "start",
  },
  normalCell: {
    // textAlign: 'center',
    // borderBottom: "1px solid #000", // Border between data cells
  },
});

const SaasAgreement = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const email = "info@investpulse.com.au";

  return (
    <>
      <Header />
      <Container maxWidth="lg">
        <Grid container mb={10}>
          <Grid item xs={12} md={12}>
            <Stack mt={3} alignItems={"center"} justifyContent={"center"}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                TERMS AND CONDITIONS
              </Typography>
            </Stack>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <Typography mt={2}>
                  Welcome to Gritpro Pty Ltd ACN 613 057 097 trading as
                  ‘Investpulse’ (
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    ‘we’, ‘us’,
                  </span>{" "}
                  or{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    ‘our’
                  </span>
                  ) and we are a data analytics and software company offering
                  services for the management of investment property portfolio
                  with all financials in one centralised platform for real
                  estate investors, as described on our Website{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    (Software).
                  </span>
                </Typography>
                <Typography>
                  These terms and conditions (
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Terms
                  </span>
                  ) govern your access to the Software and us providing you any
                  other goods and services as set out in these Terms (
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Subscription
                  </span>
                  ). You can view the most updated version of our Terms at{" "}
                  <a
                    style={{
                      color: "#33c7c1",
                    }}
                    href="https://www.investpulse.com.au/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.investpulse.com.au
                  </a>
                  &nbsp; (
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Website
                  </span>
                  ). Please read these terms and conditions carefully before
                  agreeing to proceed with your Subscription.{" "}
                </Typography>
                <Typography fontWeight={"bold"}>
                  Please note that your Subscription will continue to renew
                  indefinitely, and you will continue to incur Subscription
                  Fees, unless you notify us that you want to cancel your
                  Subscription in accordance with clause 14. Please ensure you
                  contact us if you want to cancel your Subscription.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                1
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  READING AND ACCEPTING THESE TERMS
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    In these Terms, capitalised words and phrases have the
                    meanings given to them where they are followed by bolded
                    brackets, or as set out in the Definitions table at the end
                    of these Terms.
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    By clicking the tick box below or clicking the “I accept
                    these Terms” button on our Website, paying for your
                    Subscription or otherwise accepting the benefit of any part
                    of your Subscription, you agree to be bound by these Terms
                    which form a binding contractual agreement between you the
                    person acquiring a Subscription or the company you represent
                    and are acquiring the Subscription on behalf of ({" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      ‘you’
                    </span>
                    or{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      ‘your’
                    </span>
                    ) and us.
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    We may change these Terms at any time by notifying you, and
                    your continued use of the Solution following such an update
                    will represent an agreement by you to be bound by the Terms
                    as amended.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* 2>>>>>>> */}
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                2
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  TERM{" "}
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={2}>
                  These Terms commence on the date you agree to be bound by
                  these Terms (as set out 1(b) and continues for the Trial
                  Period, Subscription Period and any Renewal Periods
                  applicable, unless terminated earlier in accordance with
                  clause 14.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          {/* 3 */}
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                3
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  ELIGIBILITY
                </Typography>
              </Stack>
              {/* <Stack spacing={1}>
                <Typography mt={2}>
                  We may collect personal information either directly from you,
                  or from third parties, including where you{" "}
                </Typography>
              </Stack> */}
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    By accepting these Terms, you represent and warrant that:
                  </Typography>
                  <Grid container spacing={1} mt={2}>
                    <Grid item xs={1}>
                      <Typography>(i)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      you have the legal capacity and authority to enter into a
                      binding contract with us; and{" "}
                    </Grid>
                    <Grid item xs={1}>
                      (ii)
                    </Grid>
                    <Grid item xs={11}>
                      you are authorised to use the payment you provided when
                      purchasing a Subscription.{" "}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    The Software is not intended for unsupervised use by any
                    person under the age of 18 years old or any person who has
                    previously been suspended or prohibited from using the
                    Software. By using the Software, you represent and warrant
                    that you are either:{" "}
                  </Typography>
                  <Grid container spacing={1} mt={2}>
                    <Grid item xs={1}>
                      <Typography>(i)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      over the age of 18 years and accessing the Software for
                      personal and commercial use; or{" "}
                    </Grid>
                    <Grid item xs={1}>
                      (ii)
                    </Grid>
                    <Grid item xs={11}>
                      accessing the Software on behalf of someone under the age
                      of 18 years old and consent to that person’s use of the
                      Software.{" "}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    Please do not access the Software if you are under the age
                    of 18 years old and do not have your parent or guardian’s
                    consent, if you are under 16 or if you have previously been
                    suspended or prohibited from using the Software.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(d)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    If you are signing up not as an individual but on behalf of
                    your company, your employer, an organisation, government or
                    other legal entity (Represented Entity), then “you” or
                    “your” means the Represented Entity and you are binding the
                    Represented Entity to this agreement. If you are accepting
                    this agreement and using our Solution on behalf of a
                    Represented Entity, you represent and warrant that you are
                    authorised to do so.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* 4 */}
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                4
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  DURATION OF YOUR SUBSCRIPTION
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    Subject to clause 4(b), upon expiration of the Subscription
                    Period, this agreement will automatically and indefinitely
                    renew on an ongoing basis for a period equal to the
                    Subscription Period{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      (Renewal Period).{" "}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    This agreement will not automatically renew on expiry of the
                    Subscription or Renewal Period{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (Renewal Date),{" "}
                    </span>{" "}
                    if either party provides a written cancellation notice at
                    least{" "}
                    <span
                      // style={{
                      //   backgroundColor: "yellow",
                      // }}
                    >
                      [30 days]
                    </span>{" "}
                    prior to the Renewal Date.{" "}
                    {/* <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      ‘you’
                    </span>
                    or{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      ‘your’
                    </span>
                    ) and us. */}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    At least{" "}
                    <span
                      // style={{
                      //   backgroundColor: "yellow",
                      // }}
                    >
                      {" "}
                      [30 days]{" "}
                    </span>
                    prior to the expiry of the Renewal Date, we will provide you
                    with advanced written notice of the agreement renewing and
                    any applicable changes to the Subscription Fees or these
                    Terms{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (Renewal Notice).
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* 5 */}
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                5
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  THE SOLUTION
                </Typography>
              </Stack>
              {/* <Stack spacing={1}>
                <Typography mt={3}>
                  We take reasonable steps to ensure your personal information
                  is secure and protected from misuse or unauthorised access.
                  Our information technology systems are password protected, and
                  we use a range of administrative and technical measures to
                  protect these systems. However, we cannot guarantee the
                  security of your personal information.
                </Typography>
              </Stack> */}
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                5.1
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  SCOPE OF YOUR SUBSCRIPTION AND THE SOLUTION
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    We will provide you, to the extent described in your
                    Subscription, the Software and the Documentation (Solution).{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      (Solution).{" "}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    Your Subscription includes the benefits and limitations of
                    your Subscription as set out on our Website, or as otherwise
                    communicated to you when you subscribe for your Subscription
                    (and as amended from time to time by notice to you).{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                5.2
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  ACCOUNTS{" "}
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      (Accounts)
                    </span>{" "}
                    To use the Solution, you may be required to sign-up,
                    register and receive an account through the Website{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      (an Account).{" "}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      (Provide Information)
                    </span>{" "}
                    As part of the Account registration process and as part of
                    your continued use of the Website, you may be required to
                    provide personal information and details, such as your email
                    address, first and last name, preferred username, a secure
                    password, billing, postal and physical addresses, mobile
                    phone number, photos and video, audio files, profile
                    information, payment details, ratings and reviews, verified
                    identifications, verified certifications and authentication,
                    and other information as determined by us from time to time.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      (Warranty){" "}
                    </span>{" "}
                    You warrant that any information you give to us in the
                    course of completing the Account registration process is
                    accurate, honest, correct and up-to-date{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      (Acceptance)
                    </span>{" "}
                    Once you complete the Account registration process, we may,
                    in our absolute discretion, choose to accept you as a
                    registered user within the Website and provide you with an
                    Account.{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                5.3
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  ACCURACY DISCLAIMER
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    The Software utilises algorithms and machine learning
                    techniques to provide the Solution and any associated
                    Software Content. While we strive to ensure the highest
                    level of data accuracy, errors may occur due to the inherent
                    limitations of artificial intelligence or variations in the
                    quality of user input to the Solution. Therefore, we do not
                    warrant or guarantee the accuracy, completeness, or
                    reliability of the Software or its associated Software
                    Content. You acknowledge and agree that any reliance on
                    content is at your own risk{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    You are encouraged to review and verify the accuracy of any
                    Software Content developed using the Solution independently.
                    We will not be held liable for any errors, omissions, or
                    damages arising from the use of the Solution or reliance on
                    the Software Content, including but not limited to financial
                    losses, loss of business opportunities, or reputational
                    damage.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    By using our Solution, you acknowledge that errors may occur
                    and accept the risk associated with potential inaccuracies.
                    You agree to hold us harmless and indemnify us from any
                    claims, liabilities, losses, or expenses arising out of or
                    related to the use of our Software or reliance on its
                    associated Software Content.{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                5.4
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  GENERAL DISCLAIMER
                </Typography>
              </Stack>
              <Stack mt={2}>
                <Typography>You acknowledge and agree that:</Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    any information provided to you as part of or in connection
                    with the Solution is general in nature, may not be suitable
                    for your circumstances and does not constitute financial,
                    legal or any other kind of professional advice;
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    it is your responsibility to comply with applicable Laws
                    relevant to your business, including industrial relations
                    Laws and privacy Laws;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    that we merely act as a passive conduit for the online
                    distribution of the Solution and the associated Software
                    Content and we have no obligation to screen the data and
                    information that you input into the Solution;{" "}
                  </Typography>
                </Grid>

                <Grid item xs={1}>
                  <Typography>(d)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    the information on the Solution is not to be construed as an
                    endorsement, warranty, or representation of a particular
                    business, its products, services, or business activities;{" "}
                  </Typography>
                </Grid>

                <Grid item xs={1}>
                  <Typography>(e)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    the information presented on the Website or the Solution may
                    not always reflect the most current state of affairs and you
                    are encouraged to verify critical information independently;{" "}
                  </Typography>
                </Grid>

                <Grid item xs={1}>
                  <Typography>(f)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    information given to you through the Solution is general in
                    nature and we take no responsibility for anything caused by
                    any actions you take in reliance on that information;{" "}
                  </Typography>
                </Grid>

                <Grid item xs={1}>
                  <Typography>(g)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    you are solely responsible for all acts and omissions
                    related to your use of the Solution and associated Software
                    Content;{" "}
                  </Typography>
                </Grid>

                <Grid item xs={1}>
                  <Typography>(h)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    we have no liability in respect of any damage, loss or
                    expense which arises in connection with the Software and
                    Software Content and you release and hold us harmless from
                    damage, loss or expense which arises from the Software or
                    Software Content;{" "}
                  </Typography>
                </Grid>

                <Grid item xs={1}>
                  <Typography>(i)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    we do not accept responsibility for any unauthorised use,
                    destruction, loss, damage or alteration to the Software or
                    Software Content or any other information, your computer
                    systems, mobile phones or other electronic devices arising
                    in connection with use of the Solution; and{" "}
                  </Typography>
                </Grid>

                <Grid item xs={1}>
                  <Typography>(j)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    you must take your own precautions to ensure that the
                    process which you employ for accessing the Software or
                    Software Content does not expose you to the risk of hacking,
                    malware, ransomware, viruses, malicious computer code or
                    other forms of interference.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                5.5
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  SOFTWARE
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    While your Subscription is maintained, we grant to you a
                    non-exclusive, non-transferable licence to use the Software
                    and Documentation for the Number of Solution Users. If your
                    Subscription on the Website does not specify a Number of
                    Solution Users, your licence to use the Solution under this
                    clause will be limited to one User (i.e., the Number of
                    Solution Users will be one). You are expressly prohibited
                    from sharing your Subscription with any other third party.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    We may from time to time, in our absolute discretion,
                    release enhancements to the Software, meaning an upgraded,
                    improved, modified or new versions of the Software
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      (Enhancements)
                    </span>
                    . Any Enhancements to the Software will not limit or
                    otherwise affect these Terms. Enhancements may cause
                    downtime or delays from time to time, and credits will not
                    be provided for such downtime.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    We may change any features of the Solution at any time on
                    notice to you.{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                5.6
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  SUPPORT SERVICES
                </Typography>
              </Stack>
              <Stack mt={1}>
                <Typography>
                  We will provide general support where reasonably necessary to
                  resolve technical issues with the Software{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    (Support Services).
                  </span>
                  Unless otherwise agreed in writing:
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    we will take reasonable steps to provide Support Services
                    where necessary (you must first endeavour to resolve any
                    issues with the Software internally and we will not assist
                    with issues that are beyond our reasonable control);{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    we will use our best endeavours to respond to requests for
                    Support Services and you acknowledge that we may not be
                    available 24/7 or respond within a particular time frame;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    you are responsible for all internal administration and
                    managing access, including storing back-up passwords and
                    assisting your Users to access and use the Software; and{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(d)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    you will not have any claim for delay to your access to the
                    Software due to any failure or delay in Support Services.{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                6
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  DATA HOSTING
                </Typography>
              </Stack>
              <Stack mt={1}>
                <Typography>
                  We will store User Data you upload to the Software using a
                  third party hosting service (such as Amazon Cloud) selected by
                  us{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    (Hosting Services){" "}
                  </span>
                  , subject to the following terms:{" "}
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      (hosting location){" "}
                    </span>
                    You acknowledge and agree that we may use storage servers to
                    host the Software through cloud-based services, and
                    potentially other locations outside Australia.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (service quality)
                    </span>{" "}
                    While we will use our best efforts to select an appropriate
                    hosting provider, we do not guarantee that the Hosting
                    Services will be free from errors or defects or that User
                    Data will be accessible or available at all times.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (security){" "}
                    </span>{" "}
                    We will use our best efforts to ensure that User Data is
                    stored securely. However, we do not accept responsibility or
                    liability for any unauthorised use, destruction, loss,
                    damage or alteration to User Data, including due to hacking,
                    malware, ransomware, viruses, malicious computer code or
                    other forms of interference.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(d)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (backups & disaster recovery){" "}
                    </span>{" "}
                    In the event that User Data is lost due to a system failure
                    (e.g. a database or webserver crash), we cannot guarantee
                    that any backup will be available, or if available that such
                    a backup will be free from errors or defects.{" "}
                  </Typography>
                </Grid>
                {/* e>>>>> */}
                <Grid item xs={1}>
                  <Typography>(e)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (Data Storage Allocation):{" "}
                    </span>{" "}
                    The Client acknowledges that they are granted a default
                    allocation of 250 MB of cloud data storage, unless otherwise
                    specified in the Subscription{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      (Default Allocation).
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(f)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (Subscription Upgrade):{" "}
                    </span>{" "}
                    The Client may choose to purchase additional cloud data
                    storage plans offered by us from time to time. Upon payment
                    of the applicable fee, the Client will be granted access to
                    store data up to the allocated limit, as specified in the
                    chosen Subscription.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(g)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (Subscription Renewal):{" "}
                    </span>{" "}
                    At the end of the Subscription Period, the Client may choose
                    to renew or discontinue their Subscription. If the Client
                    chooses not to renew the Subscription, the data allowance
                    will revert to the Default Allocation of 250 MB.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(h)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (Data Storage Limitation):{" "}
                    </span>{" "}
                    In the event that the Client's Subscription is not renewed,
                    and its data allowance is reduced to the Default Allocation,
                    the Client will not be permitted to add any new documents
                    exceeding this total data limit.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(i)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (Grace Period for Data Retrieval):{" "}
                    </span>{" "}
                    Upon the expiration of the Subscription Period and the
                    reduction of the Client's data allowance to the Default
                    Allocation, the Provider shall grant the Customer a grace
                    period of 60 days to download all stored data exceeding the
                    Default Allocation. After the expiration of this grace
                    period, the Provider reserves the right to delete all files
                    exceeding the Default Allocation from its servers.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(j)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (Client Responsibility):{" "}
                    </span>{" "}
                    The Client acknowledges and agrees that it is their
                    responsibility to manage their data storage allocation and
                    to ensure timely renewal of the Subscription to maintain
                    access to stored data.{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* 7>>>. */}
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                7
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  CLIENT OBLIGATIONS
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                7.1
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  INFORMATION LIASION
                </Typography>
              </Stack>
              <Stack mt={1}>
                <Typography>You agree to:</Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    provide us with all documentation, information and
                    assistance reasonably required by us to provide the
                    Solution; and{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    provide us with access to any third party or other accounts
                    used by you (including log-in details and passwords), as is
                    reasonably required by us to provide the Solution.{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                7.2
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  CLIENT MATERIAL
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    You warrant that all information, documentation, User Data
                    and other Material you provide to us for the purpose of
                    receiving the Solution is complete, accurate, up-to-date and
                    that you have complied with any required authorisations to
                    provide such information (including but not limited to
                    compliance with privacy obligations, Intellectual Property
                    Rights, copyrights, trademarks, and patents).{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    You release us from all liability in relation to any loss or
                    damage arising out of or in connection with the Solution, to
                    the extent such loss or damage is caused or contributed to
                    by information, documentation, User Data or any other
                    Material provided by you being incomplete, inaccurate or
                    out-of-date.{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                7.3
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  YOUR OBLIGATIONS
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    You must, and must ensure that all Users, comply with these
                    Terms at all times. You acknowledge and agree that we will
                    have no liability in respect of any damage, loss or expense
                    which arises in connection with your, your Personnel’s, or
                    any User’s, breach of these Terms, and you indemnify us in
                    respect of any such damage, loss or expense.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    You must not, and must not encourage or permit any User,
                    Personnel or any third party to, without our prior written
                    approval:{" "}
                  </Typography>
                  <Grid container spacing={1} mt={2}>
                    <Grid item xs={1}>
                      <Typography>(i)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        upload sensitive information or commercial secrets using
                        the Software;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(ii)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        upload any inappropriate, offensive, illicit, illegal,
                        pornographic, sexist, homophobic or racist material
                        using the Software;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(iii)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        use the Software for any purpose other than for the
                        purpose for which it was designed, including you must
                        not use the Solution in a manner that is illegal or
                        fraudulent or facilitates illegal or fraudulent activity
                        (including requesting or accepting a job which includes
                        illegal activities or purposes;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(iv)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        upload any material that is owned or copyrighted by a
                        third party;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(v)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        make copies of the Documentation or the Software;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(vi)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        adapt, modify or tamper in any way with the Software;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(vii)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        remove or alter any copyright, trade mark or other
                        notice on or forming part of the Software or
                        Documentation;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(viii)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        act in any way that may harm our reputation or that of
                        associated or interested parties or do anything at all
                        contrary to the interests of us or the Software;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(ix)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        use the Software in a way which infringes the
                        Intellectual Property Rights of any third party;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(x)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        create derivative works from or translate the Software
                        or Documentation;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(xi)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        publish or otherwise communicate the Software or
                        Documentation to the public, including by making it
                        available online or sharing it with third parties;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(xii)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        integrate the Software with third party data or
                        Software, or make additions or changes to the Software,
                        (including by incorporating APIs into the Software)
                        other than integrating in accordance with any
                        Documentation or instructions provided by us in writing;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(xiii)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        intimidate, harass, impersonate, stalk, threaten, bully
                        or endanger any other User or distribute unsolicited
                        commercial content, junk mail, spam, bulk content or
                        harassment in connection with the Software;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(xiv)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        sell, loan, transfer, sub-licence, hire or otherwise
                        dispose of the Software or Documentation to any third
                        party, other than granting a User access as permitted
                        under these Terms;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(xv)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        decompile or reverse engineer the Software or any part
                        of it, or otherwise attempt to derive its source code;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(xvi)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        share your Account or Account information, including log
                        in details or passwords, with any other person and that
                        any use of your Account by any person who is not the
                        account holder is strictly prohibited. You must
                        immediately notify us of any unauthorised use of your
                        Account, password or email, or any other breach or
                        potential breach of the Solution’s security;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(xvii)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        use the Solution for any purpose other than for the
                        purpose for which it was designed, including you must
                        not use the Solution in a manner that is illegal or
                        fraudulent or facilitates illegal or fraudulent activity
                        (including requesting or accepting a job which includes
                        illegal activities or purposes);{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(xviii)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        make any automated use of the Solution and you must not
                        copy, reproduce, translate, adapt, vary or modify the
                        Solution without our express written consent;{" "}
                      </Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography>(xix)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        attempt to circumvent any technological protection
                        mechanism or other security feature of the Software; or{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(xx)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        permit any use of the Solution in addition to the Number
                        of Solution Users.{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    If you become aware of misuse of your Subscription by any
                    person, any errors in the material on your Subscription or
                    any difficulty in accessing or using your Subscription,
                    please contact us immediately using the contact details or
                    form provided on our Website.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(d)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    You agree, and you must ensure that all Users agree:{" "}
                  </Typography>
                  <Grid container spacing={1} mt={2}>
                    <Grid item xs={1}>
                      <Typography>(i)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        to comply with each of your obligations in these Terms;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(ii)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        to sign up for an Account in order to use the Solution;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(iii)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        that information given to you through the Software, by
                        us or another User, is general in nature and we take no
                        responsibility for anything caused by any actions you
                        take in reliance on that information; and{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(iv)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        that we may cancel your, or any User’s, Account at any
                        time if we consider, in our absolute discretion, that
                        you or they are in breach of, or are likely to breach,
                        this clause 7.{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* 8>>>>> */}

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                8
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  FEES AND PAYMENT
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                8.1
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  TRIAL PERIOD
                </Typography>
              </Stack>
              <Stack mt={1}>
                <Typography>
                  We may from time to time offer a free trial period of the
                  Solution (Free Trial Period). No payments will be due during
                  any Free Trial Period and your first payment will be due
                  immediately after the expiry of the Free Trial Period.
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                8.2
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  SUBSCRIPTION FEES
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    You must pay subscription fees to us in the amounts
                    specified on the Website for your Subscription, or as
                    otherwise agreed in writing (Subscription Fees).{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    All Subscription Fees must be paid in advance and are
                    non-refundable for change of mind.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    Unless otherwise agreed in writing, the Subscription Fees
                    are due and payable on a recurring basis for the duration of
                    your Subscription, with the first payment being due on the
                    first day of the Subscription Period (or immediately after
                    the expiry of any applicable Free Trial Period) and at the
                    beginning of every Renewal Period thereafter.{" "}
                  </Typography>
                </Grid>

                <Grid item xs={1}>
                  <Typography>(d)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    Unless otherwise agreed in writing, the Subscription Fees
                    are due and payable at the times specified in the Website
                    (Billing Cycle) on a recurring basis for the duration of
                    your Subscription, with the first payment being due on the
                    first day of the Subscription Period (or immediately after
                    the expiry of any applicable Free Trial Period) and at the
                    beginning of every Billing Cycle thereafter.{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                8.3
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  AUTOMATIC RECURRING BILLING
                </Typography>
              </Stack>
              <Stack>
                <Typography>Subject to clauses 8.4 and 8.5:</Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    Your Subscription will continue to renew on an automatic
                    indefinite basis unless you notify us that you wish to
                    cancel in accordance with this clause 8.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    While your Subscription is maintained, your Subscription
                    Fees will continue to be debited at the beginning of each
                    Renewal Period from the payment method you nominated when
                    you registered for an Account.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    By signing up for a recurring Subscription, you acknowledge
                    and agree that your Subscription has an initial and
                    recurring payment feature, and you accept responsibility for
                    all recurring charges prior to your cancellation of your
                    Subscription.{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                8.4
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  GRACE PERIOD
                </Typography>
              </Stack>
              <Stack mt={1}>
                <Typography>
                  If you fail to cancel your Subscription prior to a Renewal
                  Period and you are charged recurring charges, you have up to
                  10 Business Days from the date of that renewal to cancel your
                  Subscription by contacting us through our Website (Grace
                  Period). If you cancel your Subscription within the Grace
                  Period, please contact us via our Website to request a refund
                  for any recurring fees charged to you during the Grace Period.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                8.5
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  CHANGES TO SUBSCRIPTION FEES
                </Typography>
              </Stack>
              <Stack mt={1}>
                <Typography>
                  We may, from time to time, change our Subscription Fees and
                  provide you with 10 Business Days’ notice prior to the
                  changes. During this time, you have the opportunity to cancel
                  your Subscription with us. If you do not cancel your
                  Subscription before the new Subscription Fees take effect, the
                  Grace Period in clause 8.4 will apply.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                8.6
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  LATE PAYMENTS
                </Typography>
              </Stack>
              <Stack mt={1}>
                <Typography>
                  We reserve the right to suspend all or part of the Solution
                  indefinitely if you fail to pay any Fees in accordance with
                  this clause 8.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                8.7
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  GST
                </Typography>
              </Stack>
              <Stack mt={1}>
                <Typography>
                  Unless otherwise indicated, the Fees do not include GST. In
                  relation to any GST payable for a taxable supply by us, you
                  must pay the GST subject to us providing a tax invoice.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                8.8
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  CARD SURCHARGES
                </Typography>
              </Stack>
              <Stack mt={1}>
                <Typography>
                  We reserve the right to charge credit card surcharges in the
                  event payments are made using a credit, debit or charge card
                  (including Visa, MasterCard or American Express).{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                8.9
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  ONLINE PAYMENT PARTNER
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    We may use third-party online payment partner, currently
                    Stripe (Online Payment Partner) to collect Subscription
                    Fees.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    Provided that the Service Provider has notified the Client
                    of such Third Party Terms and provided the Client with a
                    copy of those terms, you acknowledge agree that:{" "}
                  </Typography>
                  <Grid container spacing={1} mt={2}>
                    <Grid item xs={1}>
                      <Typography>(i)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        the processing of payments by the Online Payment Partner
                        will be, in addition to this agreement, subject to the
                        terms, conditions and privacy policies of the Online
                        Payment Partner, which can be found:
                        https://stripe.com/au/privacy
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      (ii)
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        you release us and our Personnel in respect of all
                        liability for loss, damage or injury which may be
                        suffered by any person arising from any act or omission
                        of the Online Payment Partner, including any issue with
                        security or performance of the Online Payment Partner’s
                        platform or any error or mistake in processing your
                        payment; and{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      (iii)
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        We reserve the right to correct, or to instruct our
                        Online Payment Partner to correct, any errors or
                        mistakes in collecting your payment.{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    You have the right to reject any terms and conditions of the
                    Online Payment Partner. If you reject those terms, we cannot
                    provide you with the Subscription and clause 14 will apply.{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* 9 */}

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                9
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  INTELLECTUAL PROPERTY AND DATA
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                9.1
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  SOFTWARE CONTENT INTELLECTUAL PROPERTY
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    (Our ownership) We retain ownership of all Software Content
                    and reserve all rights in any Intellectual Property Rights
                    owned or licensed by us in the Software Content not
                    expressly granted to you.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    (Licence to you) You are granted a licence to the Software
                    Content and you may make a temporary electronic copy of all
                    or part of any materials provided to you for the sole
                    purpose of viewing them and using them in relation to the
                    Software. You must not otherwise reproduce, transmit, adapt,
                    distribute, sell, modify or publish those materials or any
                    Software Content without prior written consent from us or as
                    otherwise permitted by law.{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                9.2
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  INFRINGMENT ACKNOWLEDGEMENT{" "}
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    By using the Solution or uploading or creating any User
                    Data, you acknowledge, agree, and warrant that you are not
                    infringing on any privacy obligations or Intellectual
                    Property Rights, including but not limited to copyrights,
                    trademarks, and patents.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    You agree to use and upload content entirely at your own
                    risk and acknowledge that we play no part in verifying the
                    lawfulness of uploads and ensuring Intellectual Property
                    Rights are not being breached.{" "}
                  </Typography>
                  {/* <Grid container spacing={1} mt={2}>
                    <Grid item xs={1}>
                      <Typography>(i)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        the processing of payments by the Online Payment Partner
                        will be, in addition to this agreement, subject to the
                        terms, conditions and privacy policies of the Online
                        Payment Partner, which can be found:
                        https://stripe.com/au/privacy
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      (ii)
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        you release us and our Personnel in respect of all
                        liability for loss, damage or injury which may be
                        suffered by any person arising from any act or omission
                        of the Online Payment Partner, including any issue with
                        security or performance of the Online Payment Partner’s
                        platform or any error or mistake in processing your
                        payment; and{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      (iii)
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        We reserve the right to correct, or to instruct our
                        Online Payment Partner to correct, any errors or
                        mistakes in collecting your payment.{" "}
                      </Typography>
                    </Grid>
                  </Grid> */}
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    You agree that we shall not be liable for any infringement
                    of Intellectual Property Rights resulting from your use of
                    the Solution or your uploaded or created content.{" "}
                  </Typography>
                </Grid>

                <Grid item xs={1}>
                  <Typography>(d)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    Any additional Users or Personnel who are not a party to
                    these Terms acknowledge, agree and warrant that they are not
                    infringing on any Intellectual Property Rights by using the
                    Solution or uploading or creating any content, and that if
                    they are, they are solely responsible for any legal
                    consequences arising from such infringement.{" "}
                  </Typography>
                </Grid>

                <Grid item xs={1}>
                  <Typography>(e)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    Your additional Users or Personnel acknowledge that they use
                    the Solution entirely at their own risk and that we are not
                    liable for any infringement of Intellectual Property Rights
                    resulting from their use of the Solution or their uploaded
                    or created content.{" "}
                  </Typography>
                </Grid>

                <Grid item xs={1}>
                  <Typography>(f)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    For the avoidance of doubt, by uploading User Data to our
                    Website or otherwise using the Solution, you confirm that:{" "}
                  </Typography>
                  <Grid container spacing={1} mt={2}>
                    <Grid item xs={1}>
                      <Typography>(i)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        you are the creator and owner of the User Data, or you
                        have the necessary licenses, rights, consents, releases,
                        and permissions to use and to authorise us to use your
                        uploaded User Data in any manner contemplated by our
                        Solution;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      (ii)
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        you have the written consent, release, and/or permission
                        of each and every identifiable individual person in your
                        uploaded User Data to use the name or likeness of each
                        and every such identifiable individual person to enable
                        inclusion and use of your uploaded content in any manner
                        contemplated by our Solution; and
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      (iii)
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        you are solely responsible for any content generated
                        using uploaded content you do not own or do not have
                        permission to use.{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* 10 */}
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                10
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  THIRD PARTY SOFTWARE & TERMS
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                10.1
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  THIRD PARTY SOFTWARE INTEGRATIONS
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    You acknowledge and agree that issues can arise when data is
                    uploaded to software, when data is transferred between
                    different software programs, and when different software
                    programs are integrated together. We cannot guarantee that
                    integration processes between the Software and other
                    software programs will be free from errors, defects or
                    delay.{" "}
                  </Typography>
                </Grid>

                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    You agree that we will not be liable for the functionality
                    of any third party goods or services, including any third
                    party software, or for the functionality of the Software if
                    you integrate it with third party software, or change or
                    augment the Software, including by making additions or
                    changes to the Software code, and including by incorporating
                    APIs into the Software.{" "}
                  </Typography>
                </Grid>

                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    If you add third party software or software code to the
                    Software, integrate the Software with third party software,
                    or make any other changes to the Software, including the
                    Software code (User Software Changes), then:{" "}
                  </Typography>
                  <Grid container spacing={1} mt={2}>
                    <Grid item xs={1}>
                      <Typography>(i)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        you acknowledge and agree that User Software Changes can
                        have adverse effects on the Solution, including the
                        Software;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      (ii)
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        you will indemnify us in relation to any loss or damage
                        that arises in connection with the User Software
                        Changes;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      (iii)
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        we will not be liable for any failure in the Solution,
                        to the extent such failure is caused or contributed to
                        by a User Software Change;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      (iv)
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        we may require you to change or remove User Software
                        Changes, at our discretion, and if we do so, you must
                        act promptly;{" "}
                      </Typography>
                    </Grid>

                    <Grid item xs={1}>
                      (v)
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        we may suspend your access to the Solution until you
                        have changed or removed User Software Change; and/or{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      (vi)
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        we may change or remove any User Software Change, in our
                        absolute discretion. We will not be liable for loss of
                        data or any other loss or damage you may suffer in
                        relation to our amendment to, or removal of, any User
                        Software Change.{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* 11 */}

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                11
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  CONFIDENTIALITY
                </Typography>
              </Stack>
              <Grid container mt={3}>
                <Grid item xs={11}>
                  <Grid container mt={1} spacing={1}>
                    <Grid item xs={1}>
                      <Typography>(a)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        Except as contemplated by these Terms, a party must not,
                        and must not permit any of its Personnel, use or
                        disclose to any person any Confidential Information
                        disclosed to it by the other party without the
                        disclosing party’s prior written consent.{" "}
                      </Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography>(b)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        Each party must promptly notify the other party if it
                        learns of any potential, actual or suspected loss,
                        misappropriation or unauthorised access to, or
                        disclosure or use of Confidential Information or other
                        compromise of the security, confidentiality, or
                        integrity of Confidential Information.
                      </Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography>(c)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        The notifying party will investigate each potential,
                        actual or suspected breach of confidentiality and assist
                        the other party in connection with any related
                        investigation.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* 12 */}
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                12
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  PRIVACY
                </Typography>
              </Stack>
              <Grid container mt={3}>
                <Grid item xs={11}>
                  <Grid container mt={1} spacing={1}>
                    <Grid item xs={1}>
                      <Typography>(a)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        We collect personal information about you in the course
                        of providing you with the Solution, to contact and
                        communicate with you, to respond to your enquiries and
                        for other purposes set out in our Privacy Policy which
                        can be found at{" "}
                        <a
                          // style={{
                          //   backgroundColor: "yellow",
                          // }}
                          href="https://investpulse.com.au/privacy"
                        >
                          [https://investpulse.com.au/privacy].
                        </a>
                      </Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography>(b)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        Our Privacy Policy contains more information about how
                        we use, disclose and store your personal information and
                        details how you can access and correct your personal
                        information.{" "}
                      </Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography>(c)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        By agreeing to these Terms, you agree to our handling of
                        personal information in accordance with our Privacy
                        Policy.{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* 13   */}
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                13
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  LIABILITY{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                13.1
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  WARRANTIES AND LIMITATIONS{" "}
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      (Warranties){" "}
                    </span>{" "}
                    We warrant that:
                  </Typography>

                  <Grid container spacing={1} mt={2}>
                    <Grid item xs={1}>
                      <Typography>(i)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        during the Subscription Period, the Solution will be
                        provided as described to you in, and subject to, these
                        Terms; and{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      (ii)
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        to our knowledge, the use of the Software in accordance
                        with these Terms will not infringe the Intellectual
                        Property Rights of any third party.{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (Errors){" "}
                    </span>{" "}
                    We will correct any errors, bugs or defects in the Software
                    which arise during your Subscription and which are notified
                    to us by you, unless the errors, bugs or defects:{" "}
                  </Typography>

                  <Grid container spacing={1} mt={2}>
                    <Grid item xs={1}>
                      <Typography>(i)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        result from the interaction of the Software with any
                        other solution or computer hardware, software or
                        services not approved in writing by us;{" "}
                      </Typography>{" "}
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(ii)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        result from any misuse of the Software; or
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(iii)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        result from the use of the Software by you other than in
                        accordance with these Terms or the Software Content.{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (Service Limitations){" "}
                    </span>{" "}
                    While we will use our best endeavours to ensure the Solution
                    is working for its intended purpose, you acknowledge and
                    agree that from time to time, you may encounter the
                    following issues:{" "}
                  </Typography>

                  <Grid container spacing={1} mt={2}>
                    <Grid item xs={1}>
                      <Typography>(i)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        the Solution may have errors or defects;{" "}
                      </Typography>{" "}
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(ii)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        the Solution may not be accessible at times;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(iii)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        messages sent through the Solution may not be delivered
                        promptly, or delivered at all;{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(iv)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        information you receive or supply through the Solution
                        may not be secure or confidential; or{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>(v)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        any information provided through the Solution may not be
                        accurate or true.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(d)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (Exclusion){" "}
                    </span>{" "}
                    To the maximum extent permitted by applicable law, all
                    express or implied representations and warranties not
                    expressly stated in these Terms are excluded.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(e)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (Consumer law){" "}
                    </span>{" "}
                    Nothing in these Terms is intended to limit the operation of
                    the Australian Consumer Law contained in the Competition and
                    Consumer Act 2010 (Cth) (ACL). Under the ACL, the Client may
                    be entitled to certain remedies (like a refund, replacement
                    or repair) if there is a failure with the goods or services
                    provided.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                13.2
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  LIABILITY
                </Typography>
              </Stack>
              <Stack mt={1}>
                <Typography>
                  To the maximum extent permitted by law the total liability of
                  each party in respect of loss or damage sustained by the other
                  party in connection with these Terms or the Solution is
                  limited to the total Fees paid to us by you in the 6 months
                  preceding the date of the event giving rise to the relevant
                  liability.
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                13.3
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  CONSEQUENTIAL LOSS
                </Typography>
              </Stack>
              <Stack mt={1}>
                <Typography>
                  To the maximum extent permitted by law, neither party will be
                  liable for any incidental, special or consequential loss or
                  damages, or damages for loss of data, business or business
                  opportunity, goodwill, anticipated savings, profits or revenue
                  in connection with these Terms or any goods or services
                  provided by us, except:{" "}
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    in relation to a party’s liability for fraud, personal
                    injury, death or loss or damage to tangible property; or
                  </Typography>
                </Grid>

                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    to the extent this liability cannot be excluded under the
                    Competition and Consumer Act 2010 (Cth).{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* 14>>>>>>>>> */}

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                14
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  CANCELLATION
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                14.1
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  CANCELLATION BY US{" "}
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    We may terminate these Terms or any Subscription in whole or
                    in part immediately by written notice to you, at any time
                    for any reason, including if:
                  </Typography>

                  <Grid container spacing={1} mt={2}>
                    <Grid item xs={1}>
                      <Typography>(i)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        you, a member of your Personnel, or a User, are in
                        breach of any term of these Terms; or{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      (ii)
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        you become subject to any form of insolvency or
                        bankruptcy administration.{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    If we terminate these Terms under this clause 14.1in
                    circumstances where you have not breached any of these
                    Terms, we will issue you with a refund of a pro-rata amount
                    of the remainder of the current Subscription of Renewal
                    Period’s Subscription Fee in which you will not have access
                    to your Subscription.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                14.2
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  CANCELLING YOUR SUBSCRIPTION{" "}
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    You may cancel your Subscription for convenience by
                    following the prompts on the Website, or by contacting us in
                    writing. The termination of your Subscription will take
                    effect at the end of the then current Subscription Period or
                    Renewal Period{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      (Cancellation Period).{" "}
                    </span>{" "}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    Any Subscription Fees that would otherwise have been payable
                    during the Cancellation Period will remain payable and, to
                    the maximum extent permitted by law, no Subscription Fees
                    already paid for the Cancellation Period will be refundable.{" "}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    We will continue to provide you the Solution during the
                    Cancellation Period (including your licence to the Software)
                    and you may retrieve any necessary data during this time.{" "}
                  </Typography>{" "}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                14.3
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  TERMINATION FOR CAUSE{" "}
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    If a party (the{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Notifying Party{" "}
                    </span>
                    ) considers that the other party is in breach of this
                    agreement (the
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Breach{" "}
                    </span>
                    ), the Notifying Party may provide a notice to the other
                    party the nature and details of the Breach.{" "}
                  </Typography>

                  {/* <Grid container spacing={1} mt={2}>
                    <Grid item xs={1}>
                      <Typography>(i)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        you, a member of your Personnel, or a User, are in
                        breach of any term of these Terms; or{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      (ii)
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        you become subject to any form of insolvency or
                        bankruptcy administration.{" "}
                      </Typography>
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    The other party will have 10 Business Days (or longer, in
                    the Notifying Party’s discretion) to rectify the Breach (the
                    Rectification Period).{" "}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    After the Rectification Period, the Notifying Party will
                    notify the other party that:
                  </Typography>

                  <Grid container spacing={1} mt={2}>
                    <Grid item xs={1}>
                      <Typography>(i)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        where the Breach has been successfully rectified, that
                        the agreement will continue; or{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      (ii)
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        where the Breach has not been successfully rectified,
                        that this agreement is terminated{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          (Termination for Breach Notice).{" "}
                        </span>{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(d)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    Following a Termination for Breach Notice:{" "}
                  </Typography>

                  <Grid container spacing={1} mt={2}>
                    <Grid item xs={1}>
                      <Typography>(i)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        your Subscription will immediately terminate and you and
                        your Users will no longer have access to the Solution,
                        User Data or your Account; and{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      (ii)
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        if you are the Notifying Party, no further fees will be
                        payable by you (unless later found that such termination
                        was invalid); or{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      (iii)
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        if we are the Notifying Party, you must pay any
                        outstanding Fees for the Subscription provided (or that
                        would have been provided but for the termination notice)
                        up to the date of termination.{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                14.4
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  OTHER CONSEQUENCES FOR TERMINATION{" "}
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    Upon termination of these Terms you will no longer have
                    access to the Software, your Account or your User Data and
                    we will have no responsibility to store or otherwise retain
                    any User Data. You release us in respect of any loss or
                    damage which may arise out of us not retaining any User Data
                    beyond that point.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    Notwithstanding termination of these Terms, any clause
                    contained in these Terms that by its nature would reasonably
                    be expected to be performed after the termination or expiry
                    of these Terms will survive and be enforceable after such
                    termination or expiry, including but not limited to clauses
                    5.3, 5.4, 7.3, 9, 11, 13, 15, 18{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* 15 >>>> */}

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                15
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  DISPUTE RESOLUTION
                </Typography>
              </Stack>
              <Grid container mt={3}>
                <Grid item xs={11}>
                  <Grid container mt={1} spacing={1}>
                    <Grid item xs={1}>
                      <Typography>(a)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        A party claiming that a dispute has arisen under or in
                        connection with this agreement must not commence court
                        proceedings arising from or relating to the dispute,
                        other than a claim for urgent interlocutory relief,
                        unless that party has complied with the requirements of
                        this clause.{" "}
                      </Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography>(b)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        A party that requires resolution of a dispute which
                        arises under or in connection with this agreement must
                        give the other party or parties to the dispute written
                        notice containing reasonable details of the dispute and
                        requiring its resolution under this clause.{" "}
                      </Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography>(c)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        Once the dispute notice has been given, each party to
                        the dispute must then use its best efforts to resolve
                        the dispute in good faith. If the dispute is not
                        resolved within a period of 14 days (or such other
                        period as agreed by the parties in writing) after the
                        date of the notice, any party to the dispute may take
                        legal proceedings to resolve the dispute.{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* 16>>>> */}
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                16
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  FORCE MAJEURE
                </Typography>
              </Stack>
              <Grid container mt={3}>
                <Grid item xs={11}>
                  <Grid container mt={1} spacing={1}>
                    <Grid item xs={1}>
                      <Typography>(a)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        We will not be liable for any delay or failure to
                        perform its obligations under this agreement if such
                        delay or failure arises out of a Force Majeure Event.{" "}
                      </Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography>(b)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        If a Force Majeure Event occurs, we must use reasonable
                        endeavours to notify you of:
                      </Typography>

                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={1}>
                          <Typography>(i)</Typography>
                        </Grid>
                        <Grid item xs={11}>
                          <Typography>
                            reasonable details of the Force Majeure Event; and{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          (ii)
                        </Grid>
                        <Grid item xs={11}>
                          <Typography>
                            so far as is known, the probable extent to which We
                            will be unable to perform or be delayed in
                            performing its obligations under this agreement.{" "}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography>(c)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        Subject to compliance with clause 16(b), our relevant
                        obligation will be suspended during the Force Majeure
                        Event to the extent that it is affected by the Force
                        Majeure Event.{" "}
                      </Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography>(d)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        For the purposes of this agreement, a ‘Force Majeure
                        Event’ means any:
                      </Typography>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={1}>
                          <Typography>(i)</Typography>
                        </Grid>
                        <Grid item xs={11}>
                          <Typography>
                            act of God, lightning strike, meteor strike,
                            earthquake, storm, flood, landslide, explosion or
                            fire;{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          (ii)
                        </Grid>
                        <Grid item xs={11}>
                          <Typography>
                            strikes or other industrial action outside of the
                            control of us;{" "}
                          </Typography>
                        </Grid>

                        <Grid item xs={1}>
                          (iii)
                        </Grid>
                        <Grid item xs={11}>
                          <Typography>
                            war, terrorism, sabotage, blockade, revolution,
                            riot, insurrection, civil commotion, epidemic,
                            pandemic; or{" "}
                          </Typography>
                        </Grid>

                        <Grid item xs={1}>
                          (iii)
                        </Grid>
                        <Grid item xs={11}>
                          <Typography>
                            any decision of a government authority in relation
                            to COVID-19, or any threat of COVID-19 beyond the
                            reasonable control of us, to the extent it affects
                            our ability to perform our obligations.{" "}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* 17>>>>>>> */}

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                17
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  NOTICES
                </Typography>
              </Stack>
              <Grid container mt={3}>
                <Grid item xs={11}>
                  <Grid container mt={1} spacing={1}>
                    <Grid item xs={1}>
                      <Typography>(a)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        A notice or other communication to a party under these
                        Terms must be:
                      </Typography>

                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={1}>
                          <Typography>(i)</Typography>
                        </Grid>
                        <Grid item xs={11}>
                          <Typography>
                            in writing and in English; and{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          (ii)
                        </Grid>
                        <Grid item xs={11}>
                          <Typography>
                            delivered via email to the other party, to the email
                            address specified in the Order, or if no email
                            address is specified in the Order, then the email
                            address most regularly used by the parties to
                            correspond regarding the subject matter of this
                            agreement as at the date of this agreement{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              (Email Address).
                            </span>{" "}
                            The parties may update their Email Address by notice
                            to the other party.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography>(b)</Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        Unless the party sending the notice knows or reasonably
                        ought to suspect that an email was not delivered to the
                        other party’s Email Address, notice will be taken to be
                        given:{" "}
                      </Typography>

                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={1}>
                          <Typography>(i)</Typography>
                        </Grid>
                        <Grid item xs={11}>
                          <Typography>
                            24 hours after the email was sent, unless that falls
                            on a Saturday, Sunday or a public holiday in the
                            state or territory whose laws govern this agreement,
                            in which case the notice will be taken to be given
                            on the next occurring business day in that state or
                            territory; or{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          (ii)
                        </Grid>
                        <Grid item xs={11}>
                          <Typography>
                            when replied to by the other party,{" "}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Typography mt={1}>whichever is earlier</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* 18>>>> */}

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                18
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  GENERAL
                </Typography>
              </Stack>
            </Grid>

            <Grid container mt={3}>
              <Grid item xs={1}>
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  18.1
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Stack>
                  {" "}
                  <Typography
                    style={{
                      // fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    GOVERNING LAW AND JURISDICTION
                  </Typography>
                </Stack>
                <Stack mt={1}>
                  <Typography>
                    This agreement is governed by the law applying in New South
                    Wales, Australia. Each party irrevocably submits to the
                    exclusive jurisdiction of the courts of New South Wales,
                    Australia and courts of appeal from them in respect of any
                    proceedings arising out of or in connection with this
                    agreement. Each party irrevocably waives any objection to
                    the venue of any legal process on the basis that the process
                    has been brought in an inconvenient forum.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>

            <Grid container mt={3}>
              <Grid item xs={1}>
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  18.2
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Stack>
                  {" "}
                  <Typography
                    style={{
                      // fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    WAIVER
                  </Typography>
                </Stack>
                <Stack mt={1}>
                  <Typography>
                    No party to this agreement may rely on the words or conduct
                    of any other party as a waiver of any right unless the
                    waiver is in writing and signed by the party granting the
                    waiver.{" "}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>

            <Grid container mt={3}>
              <Grid item xs={1}>
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  18.3
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Stack>
                  {" "}
                  <Typography
                    style={{
                      // fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    SEVERANCE
                  </Typography>
                </Stack>
                <Stack mt={1}>
                  <Typography>
                    Any term of this agreement which is wholly or partially void
                    or unenforceable is severed to the extent that it is void or
                    unenforceable. The validity and enforceability of the
                    remainder of this agreement is not limited or otherwise
                    affected.{" "}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid container mt={3}>
              <Grid item xs={1}>
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  18.4
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Stack>
                  {" "}
                  <Typography
                    style={{
                      // fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    JOINT AND SEVERAL LIABILITY
                  </Typography>
                </Stack>
                <Stack mt={1}>
                  <Typography>
                    An obligation or a liability assumed by, or a right
                    conferred on, two or more persons binds or benefits them
                    jointly and severally.{" "}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid container mt={3}>
              <Grid item xs={1}>
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  18.5
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Stack>
                  {" "}
                  <Typography
                    style={{
                      // fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    ASSIGNMENT
                  </Typography>
                </Stack>
                <Stack mt={1}>
                  <Typography>
                    A party cannot assign, novate or otherwise transfer any of
                    its rights or obligations under this agreement without the
                    prior written consent of the other party.{" "}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>

            <Grid container mt={3}>
              <Grid item xs={1}>
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  18.6
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Stack>
                  {" "}
                  <Typography
                    style={{
                      // fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    ENTIRE AGREEMENT
                  </Typography>
                </Stack>
                <Stack mt={1}>
                  <Typography>
                    This agreement embodies the entire agreement between the
                    parties and supersedes any prior negotiation, conduct,
                    arrangement, understanding or agreement, express or implied,
                    in relation to the subject matter of this agreement.{" "}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>

            <Grid container mt={3}>
              <Grid item xs={1}>
                <Typography
                  style={{
                    // fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  18.7
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Stack>
                  {" "}
                  <Typography
                    style={{
                      // fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    INTERPRETATION
                  </Typography>
                </Stack>
                <Grid container mt={3}>
                  <Grid item xs={11}>
                    <Grid container mt={1} spacing={1}>
                      <Grid item xs={1}>
                        <Typography>(a)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography>
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            (singular and plural){" "}
                          </span>{" "}
                          words in the singular includes the plural (and vice
                          versa);
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(b)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography>
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            (currency)
                          </span>{" "}
                          a reference to $; or “dollar” is to Australian
                          currency;{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(c)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography>
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            (gender){" "}
                          </span>{" "}
                          words indicating a gender includes the corresponding
                          words of any other gender;{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(d)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography>
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            (defined terms){" "}
                          </span>{" "}
                          if a word or phrase is given a defined meaning, any
                          other part of speech or grammatical form of that word
                          or phrase has a corresponding meaning;{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(e)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography>
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            (person){" "}
                          </span>{" "}
                          a reference to “person” or “you” includes an
                          individual, the estate of an individual, a
                          corporation, an authority, an association, consortium
                          or joint venture (whether incorporated or
                          unincorporated), a partnership, a trust and any other
                          entity;{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(f)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography>
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            (party){" "}
                          </span>{" "}
                          a reference to a party includes that party’s
                          executors, administrators, successors and permitted
                          assigns, including persons taking by way of novation
                          and, in the case of a trustee, includes any
                          substituted or additional trustee;{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(g)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography>
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            (this agreement){" "}
                          </span>{" "}
                          a reference to a party, clause, paragraph, schedule,
                          exhibit, attachment or annexure is a reference to a
                          party, clause, paragraph, schedule, exhibit,
                          attachment or annexure to or of this agreement, and a
                          reference to this agreement includes all schedules,
                          exhibits, attachments and annexures to it;{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(h)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography>
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            (document){" "}
                          </span>{" "}
                          a reference to a document (including this agreement)
                          is to that document as varied, novated, ratified or
                          replaced from time to time;{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(i)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography>
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            (headings){" "}
                          </span>{" "}
                          headings and words in bold type are for convenience
                          only and do not affect interpretation;{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(j)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography>
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            (includes){" "}
                          </span>{" "}
                          the word “includes” and similar words in any form is
                          not a word of limitation; and{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(k)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography>
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            (adverse interpretation){" "}
                          </span>{" "}
                          no provision of this agreement will be interpreted
                          adversely to a party because that party was
                          responsible for the preparation of this agreement or
                          that provision.{" "}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* DEFINITIONS????? */}

          <Grid container>
            <Grid item xs={12} mt={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginBottom: 5,
                }}
              >
                DEFINITIONS
              </Typography>

              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  //   aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.verticalHeader}>
                        <Typography fontWeight={"bold"}>Term</Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "lightgray",
                          color: "#000",
                        }}
                      >
                        <Typography fontWeight={"bold"}>Definition</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Confidential Information
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.normalCell}>
                        <Typography>
                          means information of or provided by a party that is by
                          its nature is confidential information, is designated
                          by that party as confidential, or that the other party
                          knows or ought to know is confidential, but does not
                          include information, which is or becomes, without a
                          breach of confidentiality, public knowledge{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Documentation
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.normalCell}>
                        <Typography>
                          means all manuals, help files and other documents
                          supplied by us to you relating to the Software,
                          whether in electronic or hardcopy form
                        </Typography>{" "}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Hosting Services
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.normalCell}>
                        <Typography>
                          has the meaning given in clause 6.{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Intellectual Property Rights
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.normalCell}>
                        <Typography>
                          means any and all present and future intellectual and
                          industrial property rights throughout the world
                          (whether registered or unregistered), including
                          copyright, trade marks, designs, patents, moral
                          rights, semiconductor and circuit layout rights,
                          trade, business, company and domain names, and other
                          proprietary rights, trade secrets, know-how, technical
                          data, confidential information and the right to have
                          information kept confidential, or any rights to
                          registration of such rights (including renewal),
                          whether created before or after the date of this
                          agreement.{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>Material</Typography>
                      </TableCell>
                      <TableCell className={classes.normalCell}>
                        <Typography>
                          means tangible and intangible information, documents,
                          reports, software (including source and object code),
                          inventions, data and other materials in any media
                          whatsoever.{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Number of Solution Users
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.normalCell}>
                        <Typography>
                          means the number of Users that you may make the
                          Solution available to, in accordance with your
                          Subscription Tier.{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>Personnel</Typography>
                      </TableCell>
                      <TableCell className={classes.normalCell}>
                        <Typography>
                          means, in respect of a party, its officers, employees,
                          contractors (including subcontractors) and agents.{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>Software</Typography>
                      </TableCell>
                      <TableCell className={classes.normalCell}>
                        <Typography>
                          has the meaning given in the first paragraph of these
                          Terms.{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Software Content
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.normalCell}>
                        <Typography>
                          means Material provided to you throughout the course
                          of your Subscription in connection with the Software
                          (including text, graphics, logos, design, icons,
                          images, sound and video recordings, pricing, downloads
                          and software), whether in electronic or hardcopy form,
                          including any User Data integrated documents supplied
                          through the Solution.
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>Solution</Typography>
                      </TableCell>
                      <TableCell className={classes.normalCell}>
                        <Typography>
                          has the meaning set out in clause 5.1.{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Subscription
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.normalCell}>
                        <Typography>
                          means the goods and services (including access to the
                          Solution) to be provided by us to you as agreed
                          between the parties in writing.{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Subscription Fees
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.normalCell}>
                        <Typography>
                          has the meaning set out in clause 8 of these Terms.{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Subscription Period
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.normalCell}>
                        <Typography>
                          means the period of your Subscription to the Solution
                          as agreed on the Website or in writing.{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Support Services
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.normalCell}>
                        <Typography>
                          has the meaning given in clause 5.6.{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>User</Typography>
                      </TableCell>
                      <TableCell className={classes.normalCell}>
                        <Typography>
                          means you and any third party end user of the Software
                          who you make the Software available to.{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>User Data</Typography>
                      </TableCell>
                      <TableCell className={classes.normalCell}>
                        <Typography>
                          means any files, data, document, information or any
                          other Materials, which is uploaded to the Software by
                          you or any other User or which you, your Personnel or
                          Users otherwise provide to us under or in connection
                          with these Terms, including any Intellectual Property
                          Rights attaching to those materials{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>Website</Typography>
                      </TableCell>
                      <TableCell className={classes.normalCell}>
                        <Typography>
                          means the website at the URL set out in the first
                          paragraph of these Terms, and any other website
                          operated by us in connection with the Solution.{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={3}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Schedule 2
                </Typography>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Service Level Agreement{" "}
                </Typography>
              </Stack>
            </Grid>

            <Grid container mt={2}>
              <Grid item xs={1}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  1
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  SUPPORT SERVICES
                </Typography>
                <Grid container>
                  <Grid item xs={1}>
                    <Typography>(i)</Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <Stack spacing={1} mb={2}>
                      <Typography>
                        Investpulse will use its best endeavours to provide the
                        Support in accordance with the Service Levels in Table 1{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          (Service Levels).
                        </span>
                      </Typography>

                      <Typography
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Table 1: Service Levels{" "}
                        {/* <span
                          style={{
                            backgroundColor: "yellow",
                          }}
                        >
                          [Below is an example, please review and amend if
                          needed]{" "}
                        </span>{" "} */}
                      </Typography>
                    </Stack>

                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        //   aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.verticalHeader}>
                              <Typography fontWeight={"bold"}>
                                Priority
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor: "lightgray",
                                color: "#000",
                              }}
                            >
                              <Typography fontWeight={"bold"}>
                                Response Target
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor: "lightgray",
                                color: "#000",
                              }}
                            >
                              <Typography fontWeight={"bold"}>
                                Resolution Target
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.verticalHeader}>
                              <Typography fontWeight={"bold"}>
                                Critical
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.normalCell}>
                              <Typography>2 business hours </Typography>
                            </TableCell>
                            <TableCell className={classes.normalCell}>
                              <Typography>24 hours </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell className={classes.verticalHeader}>
                              <Typography fontWeight={"bold"}>High</Typography>
                            </TableCell>
                            <TableCell className={classes.normalCell}>
                              <Typography>4 business hours </Typography>
                            </TableCell>
                            <TableCell className={classes.normalCell}>
                              <Typography>48 hours </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell className={classes.verticalHeader}>
                              <Typography fontWeight={"bold"}>
                                Medium
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.normalCell}>
                              <Typography>8 business hours </Typography>
                            </TableCell>
                            <TableCell className={classes.normalCell}>
                              <Typography>72 hours </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell className={classes.verticalHeader}>
                              <Typography fontWeight={"bold"}>Low</Typography>
                            </TableCell>
                            <TableCell className={classes.normalCell}>
                              <Typography>24 business hours </Typography>
                            </TableCell>
                            <TableCell className={classes.normalCell}>
                              <Typography>120 hours </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                <Grid container mt={1}>
                  <Grid item xs={1}>
                    <Typography>(m)</Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <Stack spacing={1} mb={2}>
                      <Typography>
                        The severity and priority of calls are classified as
                        follows:
                      </Typography>
                    </Stack>

                    <Grid container mt={1} spacing={1}>
                      <Grid item xs={1}>
                        <Typography>(i)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Stack spacing={1}>
                          <Typography>
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              Critical
                            </span>
                            – business critical issues only, which prevent the
                            majority of users from accessing the Services or
                            where the module functionality is materially
                            restricted with no feasible workaround
                          </Typography>
                        </Stack>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(ii)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Stack spacing={1}>
                          <Typography>
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              High
                            </span>
                            –Solution issues affecting multiple users and issues
                            affecting business operation, which prevents a small
                            group of users from accessing the Services or where
                            the module functionality is restricted but a
                            feasible workaround exists{" "}
                          </Typography>
                        </Stack>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(iii)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Stack spacing={1}>
                          <Typography>
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              Medium
                            </span>
                            – notification of image/data capture issues/trends;
                            non-critical general, operation and technical
                            enquiries{" "}
                          </Typography>
                        </Stack>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(iv)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Stack spacing={1}>
                          <Typography>
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              Low
                            </span>
                            – feedback; non-essential system set-up changes, no
                            compliance or cash flow impact.{" "}
                          </Typography>{" "}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container mt={2}>
              <Grid item xs={1}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  1
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  CLIENT ACKNOWLEDGMENTS
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography>
                      The Client acknowledges and agrees that:
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container mt={1} spacing={1}>
                  <Grid item xs={1}>
                    <Typography>(a)</Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <Stack spacing={1}>
                      <Typography>
                        Service Levels do not apply to any performance or
                        availability issues arising from:{" "}
                      </Typography>
                    </Stack>

                    <Grid container mt={1} spacing={1}>
                      <Grid item xs={1}>
                        <Typography>(i)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Stack spacing={1}>
                          <Typography>
                            factors outside our reasonable control (for example,
                            natural disaster, war, acts of terrorism, riots,
                            government action, or a network or device failure
                            external to our data centres, including at your site
                            or between your site and our data centre);
                          </Typography>
                        </Stack>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(ii)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Stack spacing={1}>
                          <Typography>
                            any information technology systems, hardware or
                            software not provided by the Investpulse;
                          </Typography>
                        </Stack>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(iii)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Stack spacing={1}>
                          <Typography>
                            the Client failing to implement and maintain the
                            Dependencies;
                          </Typography>
                        </Stack>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(iv)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Stack spacing={1}>
                          <Typography>
                            the Client utilising the Solution contrary to the
                            Investpulse’s advice on utilisation of the Solution
                            (including any policies or guidelines provided to
                            the Client by the Investpulse or a Third Party
                            Service);
                          </Typography>{" "}
                        </Stack>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(v)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Stack spacing={1}>
                          <Typography>
                            during or with respect to a preview, pre-release,
                            beta test or trial versions of the Solution
                            (including any particular feature);{" "}
                          </Typography>{" "}
                        </Stack>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(vi)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Stack spacing={1}>
                          <Typography>
                            the Client or a User’s action or inaction to a
                            reasonable request from the Investpulse in relation
                            to an issue; or{" "}
                          </Typography>{" "}
                        </Stack>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography>(vii)</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Stack spacing={1}>
                          <Typography>
                            the Client or a User’s failure to maintain and
                            following appropriate security practices,{" "}
                          </Typography>{" "}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={1}>
                    <Typography>(b)</Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <Stack spacing={1}>
                      <Typography>
                        the Investpulse cannot guarantee that all issues,
                        problems or security threats will be identified;{" "}
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={1}>
                    <Typography>(c)</Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <Stack spacing={1}>
                      <Typography>
                        final solution may differ from initial diagnosis;{" "}
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={1}>
                    <Typography>(d)</Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <Stack spacing={1}>
                      <Typography>
                        priority levels are determined at the Investpulse’s sole
                        discretion;{" "}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={2}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                2
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                DOWNTIME
              </Typography>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Stack spacing={1}>
                    <Typography>
                      Service Levels are indicative and if the Investpulse is
                      not able to meet a Service Level set out in this clause,
                      for any reason, the Client will not have any claim for
                      delay and the the Investpulse will use its best endeavours
                      to respond to or resolve the relevant issue as soon as
                      possible; and{" "}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Stack spacing={1}>
                    <Typography>
                      The Investpulse will use its best endeavours to provide
                      the Client with [insert e.g. 8 hours] prior notice of any
                      downtime.{" "}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid
                  // style={{
                  //   backgroundColor: "lightgreen",
                  // }}
                  item
                  xs={1}
                >
                  <Typography>(c)</Typography>
                </Grid>
                <Grid
                  // style={{
                  //   backgroundColor: "lightgreen",
                  // }}
                  item
                  xs={11}
                  md={1}
                >
                  <Stack spacing={1}>
                    <Typography>[OR] </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={10}></Grid>

                <Grid item xs={1}>
                  <Typography>(n)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Stack spacing={1}>
                    <Typography>
                      Subject to clause 18.7(o), if the Investpulse is not able
                      to meet a Service Level, for any reason, the Client will
                      not have any claim for delay and the Investpulse will use
                      its best endeavours to respond to or resolve the relevant
                      issue as soon as possible.{" "}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={1}>
                  <Typography>(o)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Stack spacing={1} mb={2}>
                    <Typography>
                      The Investpulse will use its best endeavours to provide
                      the Client with 8 hours prior notice of any downtime.
                      Where downtime occurs without prior notice to the Client{" "}
                      <span style={{ fontWeight: "bold" }}>
                        (Unscheduled Downtime)
                      </span>{" "}
                      , the Client will be credited with the lost service time
                      by reducing the fees in accordance with Table 2.{" "}
                    </Typography>

                    <Typography fontWeight={"bold"}>
                      Table 2: Unscheduled Downtime Credits{" "}
                      {/* <span
                        style={{
                          backgroundColor: "yellow",
                        }}
                      >
                        [Below is an example, please review and amend if needed]{" "}
                      </span> */}
                    </Typography>
                  </Stack>

                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      //   aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              backgroundColor: "lightgray",
                              color: "#000",
                            }}
                          >
                            <Typography fontWeight={"bold"}>
                              Unscheduled Downtime
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "lightgray",
                              color: "#000",
                            }}
                          >
                            <Typography fontWeight={"bold"}>Credit</Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <Typography>Less than 4 hours</Typography>
                          </TableCell>
                          <TableCell className={classes.normalCell}>
                            <Typography>
                            Nil
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography>Between 4 and 24 hours</Typography>
                          </TableCell>
                          <TableCell className={classes.normalCell}>
                            <Typography>
                              5% of one twelfth of the Annual Fee{" "}
                            </Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <Typography>More than 24 hours</Typography>
                          </TableCell>
                          <TableCell className={classes.normalCell}>
                            <Typography>
                              10% of one twelfth of the Annual Fee{" "}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={2}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                3
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                SUPPORT SERVICE HOURS AND CONTACT METHODS
              </Typography>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Stack spacing={1}>
                    <Typography>
                      Unless otherwise set out in this Schedule 2, the Support
                      Services will be available from [9 AM] - [5 PM] on
                      Business Days <span style={{
                        fontWeight:"bold"
                      }}>
                         (Business Hours).
                      </span>
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Stack spacing={1}>
                    <Typography>
                      All Response Times and Resolutions Targets do not include
                      any time that falls outside a Business Hours
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Stack spacing={1} mb={2}>
                    <Typography>
                      Support Services can be accessed via the following
                      methods:
                    </Typography>
                  </Stack>

                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      //   aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              backgroundColor: "lightgray",
                              color: "#000",
                            }}
                          >
                            <Typography fontWeight={"bold"}>
                              Assistance Method
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "lightgray",
                              color: "#000",
                            }}
                          >
                            <Typography fontWeight={"bold"}>Details</Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <Typography>Support Portal</Typography>
                          </TableCell>
                          <TableCell className={classes.normalCell}>
                            <Typography></Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <Typography>Email</Typography>
                          </TableCell>
                          <TableCell className={classes.normalCell}>
                            <Typography> </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <HomeFooter />
    </>
  );
};

export default SaasAgreement;
