import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

const RefinanceChart = ({ currentLoanData, newLoanData,loanAmount }) => {
  const combinedData = currentLoanData.map((entry, index) => ({
    month: entry?.month,
    currentRepayments: parseFloat(entry?.outstandingLoanBalance),
    newRepayments: parseFloat(newLoanData[index]?.outstandingLoanBalance),
  }));
  const chartData = combinedData.map((entry) => ({
    years: entry.month / 12,
    repayments: entry?.currentRepayments,
    newRepayments: entry?.newRepayments,
    amt: entry?.currentRepayments - entry?.newRepayments,
  }));

  const existingChartData = [
    {
      years: 0,
      repayments: loanAmount,
      newRepayments:loanAmount,
      amt:loanAmount,
    },
  ];
  const combinedChartData = [...existingChartData, ...chartData];

  const formatTotalValue = (total) => {
    let formattedTotal = "";
    const isValidTotal = typeof total === "number" && !isNaN(total);
    if (isValidTotal) {
      if (total >= 1000000000) {
        formattedTotal =
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(total / 1000000000) + " B";
      } else if (total >= 1000000) {
        formattedTotal =
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(total / 1000000) + " M";
      } else if (total >= 1000) {
        formattedTotal =
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(total / 1000) + " K";
      } else {
        formattedTotal = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(total);
      }
    }
    return formattedTotal;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      const years = Math.floor(data.years);
      const months = Math.round((data.years - years) * 12);
      const yearMonth = `${years}.${months}`;
      const repayments = data.repayments.toLocaleString();
      const newRepayments =
        payload[0]?.payload?.newRepayments?.toLocaleString();
      return (
        <div className="custom-tooltip" style={tooltipStyle}>
          <div style={tooltipContentStyle}>
            <p>Years: {yearMonth}</p>
            <p style={{ color: "#52565f" }}>Current Loan: ${repayments}</p>
            <p style={{ color: "#ea1777" }}>New Loan: ${newRepayments}</p>
          </div>
        </div>
      );
    }

    return null;
  };

  const tooltipStyle = {
    background: "#fff",
    width: "200px",
  };

  const tooltipContentStyle = {
    paddingTop: "5%",
    paddingBottom: "5%",
    paddingLeft: "5%",
  };
  const yearsToShowDotsFor = [...new Set(chartData?.map((item) => item.years))];

  const CustomDot = (props) => {
    const { cx, cy, payload, r } = props;
    if (payload.shouldHaveActiveDot) {
      return <circle cx={cx} cy={cy} r={r} stroke="#ea1777" fill="#ea1777" />;
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={400} maxWidth={640}>
      <LineChart
        width={500}
        height={300}
        data={combinedChartData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="years" height={50} padding={{ left: 0, right: 0 }}>
        {/* ticks={yearsToShowDotsFor}> */}
          <Label
            value="Years"
            position="insideBottom"
            style={{
              fontWeight: "bold",
            }}
          />
        </XAxis>
        <YAxis tickFormatter={formatTotalValue} width={110}>
          <Label
            value="Loan Amount"
            position="insideLeft"
            angle={-90}
            style={{ textAnchor: "middle", fontWeight: "bold" }}
          />
        </YAxis>
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line
          type="monotone"
          dataKey="repayments"
          stroke="#52565f"
          fill="#52565f"
          name="Current Loan"
          // dot={<CustomDot />}
        />
        <Line
          type="monotone"
          dataKey="newRepayments"
          stroke="#ea1777"
          fill="#ea1777"
          name="New Loan"
          // dot={<CustomDot />}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default RefinanceChart;
