import { createSlice } from '@reduxjs/toolkit';
import { cookies } from '../../services/auth';
import { createReport,getReports,deleteReport,updateReport,getReport, getReportSummaey } from './reportActions';

const reportSlice = createSlice({
  name: 'report',
  initialState: {
    loading: false,
    reports:[],
    summary:[],
    report:{}
  },
  reducers: {
  },
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder
    .addCase(createReport.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createReport.fulfilled, (state, action) => {
      state.loading = false;
      if(action?.payload?.data){
        state.report = action?.payload?.data;
      }
    })
    .addCase(createReport.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(getReports.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getReports.fulfilled, (state, action) => {
      state.loading = false;
      if(action?.payload?.data){
        state.reports = action?.payload?.data;
      }
    })
    .addCase(getReports.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(deleteReport.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(deleteReport.fulfilled, (state, action) => {
      state.loading = false;
      if(action?.payload?.data){
        state.report = action?.payload?.data;
      }
    })

    .addCase(deleteReport.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(updateReport.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(updateReport.fulfilled, (state, action) => {
      state.loading = false;
      if(action?.payload?.data){
        state.report = action?.payload?.data;
      }
    })
    .addCase(updateReport.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(getReport.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getReport.fulfilled, (state, action) => {
      state.loading = false;
      if(action?.payload?.data){
        state.report = action?.payload?.data;
      }
    })
    .addCase(getReport.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(getReportSummaey.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getReportSummaey.fulfilled, (state, action) => {
      state.loading = false;
      if(action?.payload?.data){
        state.summary = action?.payload?.data;
      }
    })
    .addCase(getReportSummaey.rejected, (state, action) => {
      state.loading = false;
    })
    
  },
});

export default reportSlice.reducer;