import { Button, Container, Grid, Stack, TextField, Typography } from "@mui/material";
import { result } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import CommonSelect from "../../../common/CommonSelect";
import CurrencyInput from "../../../common/CurrencyInput";
import PercentageInput from "../../../common/PercentageInput";
import PropertyPopover from "../../../components/PropertyPopover";
import RefinanceChart from "../../../components/RefinanceChart";
import Home from "../../../components/SidebarWithTopBar";
import { Validate } from "../../../helpers/functions";
import { refinanceCalculatorSchema } from "./schema";
import { getProperties } from "../../../store/property/propertyActions";
import Header from "../../../components/Header";
import HomeFooter from "../HomeFooter";

const PublicRefinanceCalculator = () => {
  const properties = useSelector((state) => state.properties.properties);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [finalResult, setResult] = useState({});
  const [isCalculated, setIsCalculated] = useState(false);
  const [error, setError] = useState({
    path: null,
  });
  const [currentLoanData, setCurrentLoanData] = useState([]);
  const [newLoanData, setnewLoanData] = useState([]);
  const dispatch = useDispatch();

  const [data, setData] = useState({
    propertyType: "",
    // initialInvestment: null,
    loanAmount: null,
    interestRate: null,
    newIntroTerm: null,
    introTerm: null,
    frequency: "Monthly",
    introRate: null,
    newIntroRate: null,
    newFrequency: "Monthly",
    newInterestRate: null,
    loanTerm: null,
    newLoanTerm: null,
  });


  async function calculateAmortizationSchedule() {
    // Assuming calculateData() and newLoanCalculateData() are synchronous functions
    calculateData();
    newLoanCalculateData();
    try {
      let validationError = await Validate(refinanceCalculatorSchema, data);
      if (validationError) {
        setError(validationError);
        return;
      } else {
        setError({
          path: null,
        });
      }
      const {
        loanAmount,
        interestRate,
        newInterestRate,
        loanTerm,
        newLoanTerm,
        newIntroRate,
        introRate,
        newIntroTerm,
        introTerm,
      } = data;
      if (newIntroRate !== 0 && introRate !== 0) {
        const monthlyInterestRate = newIntroRate / 12 / 100;
        const numberOfPayments = newLoanTerm * 12;

        const currentmonthlyInterestRate = introRate / 12 / 100;
        const currentnumberOfPayments = loanTerm * 12;

        const monthlyPayment =
          (loanAmount * monthlyInterestRate) /
          (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

        const currentmonthlyPayment =
          (loanAmount * currentmonthlyInterestRate) /
          (1 -
            Math.pow(1 + currentmonthlyInterestRate, -currentnumberOfPayments));

        let currentoutstandingLoanBalance = loanAmount;
        let currenttotalInterestPaid = 0;
        for (let month = 1; month <= numberOfPayments; month++) {
          const interestPayment =
            currentoutstandingLoanBalance * monthlyInterestRate;
          const principalPayment = currentmonthlyPayment - interestPayment;
          currentoutstandingLoanBalance -= principalPayment;
          currenttotalInterestPaid += interestPayment;
          if (month === introTerm * 12) {
            break;
          }
        }
        let outstandingLoanBalance = loanAmount;
        let totalInterestPaid = 0;
        for (let month = 1; month <= currentnumberOfPayments; month++) {
          const interestPayment = outstandingLoanBalance * monthlyInterestRate;
          const principalPayment = monthlyPayment - interestPayment;
          outstandingLoanBalance -= principalPayment;
          totalInterestPaid += interestPayment;
          if (month === newIntroTerm * 12) {
            break;
          }
        }

        const monthlyInterestRate1 = interestRate / 12 / 100;
        const monthlyInterestRate2 = newInterestRate / 12 / 100;
        const numberOfPayments1 = (loanTerm - introTerm) * 12;
        const numberOfPayments2 = (newLoanTerm - newIntroTerm) * 12;

        const monthlyPayment1 =
          (outstandingLoanBalance * monthlyInterestRate1) /
          (1 - Math.pow(1 + monthlyInterestRate1, -numberOfPayments1));
        const monthlyPayment2 =
          (outstandingLoanBalance * monthlyInterestRate2) /
          (1 - Math.pow(1 + monthlyInterestRate2, -numberOfPayments2));

        const result = {
          monthlyPayment1: monthlyPayment1.toFixed(2),
          monthlyPayment2: monthlyPayment2.toFixed(2),
          totalInterest1: (
            monthlyPayment1 * numberOfPayments1 -
            loanAmount
          ).toFixed(2),
          totalInterest2: totalInterestPaid.toFixed(2),
          newIntroRateMonthlyPayment: monthlyPayment.toFixed(2),
          outstandingLoanBalance: outstandingLoanBalance.toFixed(2),
          totalPrincipalPaid: (loanAmount - outstandingLoanBalance).toFixed(2),
          introRateMonthlyPayment: currentmonthlyPayment.toFixed(2),
          currentoutstandingLoanBalance:
            currentoutstandingLoanBalance.toFixed(2),
        };
        setResult(result);
      } else if (introRate !== 0) {
        const monthlyInterestRate = introRate / 12 / 100;
        const numberOfPayments = loanTerm * 12;

        const monthlyPayment =
          (loanAmount * monthlyInterestRate) /
          (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

        let outstandingLoanBalance = loanAmount;
        let totalInterestPaid = 0;

        for (let month = 1; month <= numberOfPayments; month++) {
          const interestPayment = outstandingLoanBalance * monthlyInterestRate;
          const principalPayment = monthlyPayment - interestPayment;

          outstandingLoanBalance -= principalPayment;
          totalInterestPaid += interestPayment;

          if (month === newIntroTerm * 12) {
            break;
          }
        }

        const monthlyInterestRate1 = interestRate / 12 / 100;
        const monthlyInterestRate2 = newInterestRate / 12 / 100;
        const numberOfPayments1 = (loanTerm - introTerm) * 12;
        const numberOfPayments2 = newLoanTerm * 12;
        const monthlyPayment1 =
          (outstandingLoanBalance * monthlyInterestRate1) /
          (1 - Math.pow(1 + monthlyInterestRate1, -numberOfPayments1));

        const monthlyPayment2 =
          (loanAmount * monthlyInterestRate2) /
          (1 - Math.pow(1 + monthlyInterestRate2, -numberOfPayments2));

        const result = {
          monthlyPayment1: monthlyPayment1.toFixed(2),
          monthlyPayment2: monthlyPayment2.toFixed(2),
          // totalInterest1: (
          //   monthlyPayment1 * numberOfPayments1 -
          //   loanAmount
          // ).toFixed(2),
          totalInterest1: totalInterestPaid.toFixed(2),
          totalInterest2: (
            monthlyPayment2 * numberOfPayments2 -
            loanAmount
          ).toFixed(2),
          introRateMonthlyPayment: monthlyPayment.toFixed(2),
          outstandingLoanBalance: outstandingLoanBalance.toFixed(2),
          introRateTotalPrincipalPaid: (
            loanAmount - outstandingLoanBalance
          ).toFixed(2),
        };
        setResult(result);
      } else if (newIntroRate !== 0) {
        const monthlyInterestRate = newIntroRate / 12 / 100;
        const numberOfPayments = newLoanTerm * 12;

        const monthlyPayment =
          (loanAmount * monthlyInterestRate) /
          (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

        let outstandingLoanBalance = loanAmount;
        let totalInterestPaid = 0;

        for (let month = 1; month <= numberOfPayments; month++) {
          const interestPayment = outstandingLoanBalance * monthlyInterestRate;
          const principalPayment = monthlyPayment - interestPayment;

          outstandingLoanBalance -= principalPayment;
          totalInterestPaid += interestPayment;

          if (month === newIntroTerm * 12) {
            break;
          }
        }

        const monthlyInterestRate1 = interestRate / 12 / 100;
        const monthlyInterestRate2 = newInterestRate / 12 / 100;
        const numberOfPayments1 = loanTerm * 12;
        const numberOfPayments2 = (newLoanTerm - newIntroTerm) * 12;

        const monthlyPayment1 =
          (loanAmount * monthlyInterestRate1) /
          (1 - Math.pow(1 + monthlyInterestRate1, -numberOfPayments1));
        const monthlyPayment2 =
          (outstandingLoanBalance * monthlyInterestRate2) /
          (1 - Math.pow(1 + monthlyInterestRate2, -numberOfPayments2));
       
        const result = {
          monthlyPayment1: monthlyPayment1.toFixed(2),
          monthlyPayment2: monthlyPayment.toFixed(2),
          totalInterest1: (
            monthlyPayment1 * numberOfPayments1 -
            loanAmount
          ).toFixed(2),
          totalInterest2: totalInterestPaid.toFixed(2),
          newIntroRateMonthlyPayment: monthlyPayment2.toFixed(2),
          outstandingLoanBalance: outstandingLoanBalance.toFixed(2),
          newIntroRateTotalPrincipalPaid: (
            monthlyPayment2 * numberOfPayments2 -
            outstandingLoanBalance
          ).toFixed(2),
        };
        setResult(result);
      } else {
        const monthlyInterestRate1 = interestRate / 12 / 100;
        const monthlyInterestRate2 = newInterestRate / 12 / 100;
        const numberOfPayments1 = loanTerm * 12;
        const numberOfPayments2 = newLoanTerm * 12;

        const monthlyPayment1 =
          (loanAmount * monthlyInterestRate1) /
          (1 - Math.pow(1 + monthlyInterestRate1, -numberOfPayments1));
        const monthlyPayment2 =
          (loanAmount * monthlyInterestRate2) /
          (1 - Math.pow(1 + monthlyInterestRate2, -numberOfPayments2));
        const result = {
          monthlyPayment1: monthlyPayment1.toFixed(2),
          newIntroRateMonthlyPayment: monthlyPayment2.toFixed(2),
          totalInterest1: (
            monthlyPayment1 * numberOfPayments1 -
            loanAmount
          ).toFixed(2),
          totalInterest2: (
            monthlyPayment2 * numberOfPayments2 -
            loanAmount
          ).toFixed(2),
        };

        setResult(result);
      }

      setIsCalculated(true);
    } catch (error) {
      console.error("Error occurred during calculation or validation:", error);
    }
  }

  const calculateData = async () => {
    const { loanAmount, interestRate, loanTerm } = data;

    let Result = [];
    const monthlyInterestRate = interestRate / 12 / 100;
    const numberOfPayments = loanTerm * 12;
    const monthlyPayment =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));
    const monthlyPay =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -(numberOfPayments * 12)));

    let outstandingLoanBalance = loanAmount;
    const amortizationSchedule = [];

    for (let month = 1; month <= numberOfPayments; month++) {
      const interestPayment = outstandingLoanBalance * monthlyInterestRate;
      const principalPayment = monthlyPayment - interestPayment;
      outstandingLoanBalance -= principalPayment;
      const paymentDetails = {
        month: month,
        principalPayable: principalPayment.toFixed(2),
        interestPayable: interestPayment.toFixed(2),
        totalRepayment: monthlyPayment.toFixed(2),
        outstandingLoanBalance: outstandingLoanBalance.toFixed(2),
      };
      if (month % 12 === 0) {
        amortizationSchedule.push(paymentDetails);
      }

      // amortizationSchedule.push(paymentDetails);
    }
    setCurrentLoanData(amortizationSchedule);
    return amortizationSchedule;
  };

  const newLoanCalculateData = async () => {
    const { loanAmount, newInterestRate, newLoanTerm } = data;

    let Result = [];
    const monthlyInterestRate = newInterestRate / 12 / 100;
    const numberOfPayments = newLoanTerm * 12;
    const monthlyPayment =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));
    const monthlyPay =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -(numberOfPayments * 12)));

    let outstandingLoanBalance = loanAmount;
    const amortizationSchedule = [];
    for (let month = 1; month <= numberOfPayments; month++) {
      const interestPayment = outstandingLoanBalance * monthlyInterestRate;
      const principalPayment = monthlyPayment - interestPayment;
      outstandingLoanBalance -= principalPayment;
      const paymentDetails = {
        month: month,
        principalPayable: principalPayment.toFixed(2),
        interestPayable: interestPayment.toFixed(2),
        totalRepayment: monthlyPayment.toFixed(2),
        outstandingLoanBalance: outstandingLoanBalance.toFixed(2),
      };
      // amortizationSchedule.push(paymentDetails);
      if (month % 12 === 0) {
        amortizationSchedule.push(paymentDetails);
      }
    }
    setnewLoanData(amortizationSchedule);
    return amortizationSchedule;
  };

  const formatCurrency = (value) => {
    const numericValue = typeof value === "number" ? value : parseFloat(value);
    if (isNaN(numericValue)) {
      console.error("Invalid numeric value:", value);
      return "";
    }

    return numericValue.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  return (
    <>
      <Header />
      <Container>

      <Stack mt={9} px={1} spacing={1}>
        {/* <Typography sx={{ fontSize: "12px", fontWeight: 500, color: "gray" }}>
          What if analysis
        </Typography> */}
        <Stack
          direction={"row"}
          width={"100%"}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack spacing={1}>
            <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
              Refinance Calculator
            </Typography>
            <Typography
              sx={{
                cursor: "pointer",
                fontWeight: 500,
                color: "#B67DC4",
                fontSize: "13px",
                fontStyle: "italic",
              }}
            >
              Switching mortgage made easy - Use the comparison calculator to
              calculate your potential savings/ loss
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {/* <Stack
        mt={2}
        px={1}
        direction={"row"}
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
        width={"100%"}
      >
        <Stack>
          <Typography
            sx={{
              textAlign: "center",
              color: "#000",
              fontWeight: 400,
              fontSize: "16px",
            }}
          >
            Download
          </Typography>
          <Stack>
            <CommonSelect
              style={{ width: "200px" }}
              value="selectedOptionValue"
            />
          </Stack>
        </Stack>
      </Stack> */}

      <Grid container spacing={2} mt={3}>
        <Grid item xs={12} md={12}>
          <Stack>
            <Typography
              sx={{
                textAlign: "start",
                color: "#000",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Current Loan
            </Typography>
          </Stack>
        </Grid>

        {/* <Grid item xs={12} md={1.5}>
            <Stack>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 400,
                  fontSize: "16px",
                }}
              ></Typography>
              <Stack direction={"row"} mt={2} alignItems={"center"} spacing={1}>
                <Typography>Property 1</Typography>
              </Stack>
            </Stack>
          </Grid> */}

        <Grid item xs={12} md={2}>
          <Stack>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Loan Amount
            </Typography>
            <CurrencyInput
              placeholder="$"
              value={data.loanAmount}
              onChange={(newValue) => {
                const numericValue = parseFloat(newValue);
                setData({
                  ...data,
                  loanAmount: isNaN(numericValue) ? null : numericValue,
                });
              }}
              sx={{ maxWidth: "350px" }}
            />
          </Stack>
          <Stack>
            {error.path === "loanAmount" && (
              <Typography
                style={{
                  color: "purple",
                  fontSize: "0.75rem",
                  fontWeight: "600",
                  marginTop: "4px",
                }}
              >
                {error.message}
              </Typography>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} md={2}>
          <Stack>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Intro Rate
            </Typography>
            <PercentageInput
              placeholder="%"
              sx={{
                maxWidth: "350px",
              }}
              style={{ fontSize: "30px" }}
              value={data.introRate}
              onChange={(newValue) => {
                const numericValue = parseFloat(newValue);
                setData({
                  ...data,
                  introRate: isNaN(numericValue) ? null : numericValue,
                });
              }}
            />
          </Stack>
          <Stack>
            {error.path === "introRate" && (
              <Typography
                style={{
                  color: "purple",
                  fontSize: "0.75rem",
                  fontWeight: "600",
                  marginTop: "4px",
                }}
              >
                {error.message}
              </Typography>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} md={2}>
          <Stack>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Intro term
            </Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    height: "1.5rem",
                  },
                }}
                InputProps={{
                  sx: {
                    borderTopLeftRadius: "0rem",
                    borderBottomLeftRadius: "0rem",
                    //   marginTop: 1,
                    borderRadius: 5,
                    boxShadow: 3,
                  },
                }}
                size="small"
                margin="normal"
                variant="outlined"
                type="number"
                value={data.introTerm}
                onChange={(newValue) => {
                  const numericValue = parseFloat(newValue.target.value);
                  setData({
                    ...data,
                    introTerm: isNaN(numericValue) ? null : numericValue,
                  });
                }}
                name="introTerm"
                placeholder="Intro Term"
                fullWidth
              />
            </Stack>
          </Stack>
          <Stack>
            {error.path === "introTerm" && (
              <Typography
                style={{
                  color: "purple",
                  fontSize: "0.75rem",
                  fontWeight: "600",
                  marginTop: "4px",
                }}
              >
                {error.message}
              </Typography>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} md={2}>
          <Stack>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Interest Rate
            </Typography>
            <PercentageInput
              placeholder="%"
              sx={{
                maxWidth: "350px",
              }}
              // style={{fontSize:'30px'}}
              value={data.interestRate}
              onChange={(newValue) => {
                const numericValue = parseFloat(newValue);
                setData({
                  ...data,
                  interestRate: isNaN(numericValue) ? null : numericValue,
                });
              }}
            />
          </Stack>
          <Stack>
            {error.path === "interestRate" && (
              <Typography
                style={{
                  color: "purple",
                  fontSize: "0.75rem",
                  fontWeight: "600",
                  marginTop: "4px",
                }}
              >
                {error.message}
              </Typography>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} md={2}>
          <Stack>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Loan Term (yrs)
            </Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    height: "1.5rem",
                  },
                }}
                InputProps={{
                  sx: {
                    borderTopLeftRadius: "0rem",
                    borderBottomLeftRadius: "0rem",
                    //   marginTop: 1,
                    borderRadius: 5,
                    boxShadow: 3,
                  },
                }}
                size="small"
                margin="normal"
                variant="outlined"
                type="number"
                value={data.loanTerm}
                onChange={(newValue) => {
                  const numericValue = parseFloat(newValue.target.value);
                  setData({
                    ...data,
                    loanTerm: isNaN(numericValue) ? null : numericValue,
                  });
                }}
                name="loanTerm"
                placeholder="Loan Term"
                fullWidth
              />
            </Stack>
          </Stack>
          <Stack>
            {error.path === "loanTerm" && (
              <Typography
                style={{
                  color: "purple",
                  fontSize: "0.75rem",
                  fontWeight: "600",
                  marginTop: "4px",
                }}
              >
                {error.message}
              </Typography>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} md={2}>
          <Stack>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Frequency
            </Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    height: "1.5rem",
                    fontSize: "14px !important",
                  },
                }}
                InputProps={{
                  sx: {
                    borderTopLeftRadius: "0rem",
                    borderBottomLeftRadius: "0rem",
                    //   marginTop: 1,
                    borderRadius: 5,
                    boxShadow: 3,
                  },
                }}
                disabled
                size="small"
                margin="normal"
                variant="outlined"
                type="text"
                name="frequency"
                placeholder="Frequency"
                fullWidth
                value={data.frequency}
                inputProps={{
                  style: { fontSize: "13px" },
                }}
                onChange={(event) => {
                  setData({
                    ...data,
                    frequency: event.target.value,
                  });
                }}
              />
            </Stack>
            {/* <Stack>
                {error.path === "frequency" && (
                  <Typography
                    style={{
                      color: "purple",
                      fontSize: "0.75rem",
                      fontWeight: "600",
                      marginTop: "4px",
                    }}
                  >
                    {error.message}
                  </Typography>
                )}
              </Stack> */}
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={3}>
        <Grid item xs={12} md={12}>
          <Stack>
            <Typography
              sx={{
                textAlign: "start",
                color: "#000",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              New Loan
            </Typography>
          </Stack>
        </Grid>

        {/* <Grid item xs={12} md={1.5}>
            <Stack>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 400,
                  fontSize: "16px",
                }}
              ></Typography>
              <Stack direction={"row"} mt={1} alignItems={"center"} spacing={1}>
                <Typography>Property 1</Typography>
              </Stack>
            </Stack>
          </Grid> */}

        <Grid item xs={12} md={2}>
          <Stack>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Loan Amount
            </Typography>
            <CurrencyInput
              placeholder="$"
              value={data.loanAmount}
              // onChange={(data) => setInvestment(data)}
              onChange={(newValue) => {
                const numericValue = parseFloat(newValue);
                setData({
                  ...data,
                  loanAmount: isNaN(numericValue) ? null : numericValue,
                });
              }}
              sx={{ maxWidth: "350px" }}
            />
          </Stack>
          <Stack>
            {error.path === "loanAmount" && (
              <Typography
                style={{
                  color: "purple",
                  fontSize: "0.75rem",
                  fontWeight: "600",
                  marginTop: "4px",
                }}
              >
                {error.message}
              </Typography>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} md={2}>
          <Stack>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Intro Rate
            </Typography>
            <PercentageInput
              placeholder="%"
              sx={{
                maxWidth: "350px",
              }}
              style={{ fontSize: "30px" }}
              value={data.newIntroRate}
              onChange={(newValue) => {
                const numericValue = parseFloat(newValue);
                setData({
                  ...data,
                  newIntroRate: isNaN(numericValue) ? null : numericValue,
                });
              }}
            />
          </Stack>
          <Stack>
            {error.path === "newIntroRate" && (
              <Typography
                style={{
                  color: "purple",
                  fontSize: "0.75rem",
                  fontWeight: "600",
                  marginTop: "4px",
                }}
              >
                {error.message}
              </Typography>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} md={2}>
          <Stack>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Intro term
            </Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    height: "1.5rem",
                  },
                }}
                InputProps={{
                  sx: {
                    borderTopLeftRadius: "0rem",
                    borderBottomLeftRadius: "0rem",
                    //   marginTop: 1,
                    borderRadius: 5,
                    boxShadow: 3,
                  },
                }}
                size="small"
                margin="normal"
                variant="outlined"
                type="number"
                value={data.newIntroTerm}
                onChange={(newValue) => {
                  const numericValue = parseFloat(newValue.target.value);
                  setData({
                    ...data,
                    newIntroTerm: isNaN(numericValue) ? null : numericValue,
                  });
                }}
                name="introTerm"
                placeholder="Intro Term"
                fullWidth
              />
            </Stack>
          </Stack>
          <Stack>
            {error.path === "newIntroRate" && (
              <Typography
                style={{
                  color: "purple",
                  fontSize: "0.75rem",
                  fontWeight: "600",
                  marginTop: "4px",
                }}
              >
                {error.message}
              </Typography>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} md={2}>
          <Stack>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Interest Rate
            </Typography>
            <PercentageInput
              placeholder="%"
              sx={{
                maxWidth: "350px",
              }}
              // style={{fontSize:'30px'}}
              value={data.newInterestRate}
              onChange={(newValue) => {
                const numericValue = parseFloat(newValue);
                setData({
                  ...data,
                  newInterestRate: isNaN(numericValue) ? null : numericValue,
                });
              }}
            />
          </Stack>
          <Stack>
            {error.path === "newInterestRate" && (
              <Typography
                style={{
                  color: "purple",
                  fontSize: "0.75rem",
                  fontWeight: "600",
                  marginTop: "4px",
                }}
              >
                {error.message}
              </Typography>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} md={2}>
          <Stack>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Loan Term (yrs)
            </Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    height: "1.5rem",
                  },
                }}
                InputProps={{
                  sx: {
                    borderTopLeftRadius: "0rem",
                    borderBottomLeftRadius: "0rem",
                    //   marginTop: 1,
                    borderRadius: 5,
                    boxShadow: 3,
                  },
                }}
                size="small"
                margin="normal"
                variant="outlined"
                type="number"
                value={data.newLoanTerm}
                onChange={(newValue) => {
                  const numericValue = parseFloat(newValue.target.value);
                  setData({
                    ...data,
                    newLoanTerm: isNaN(numericValue) ? null : numericValue,
                  });
                }}
                name="newLoanTerm"
                placeholder="Loan Term"
                fullWidth
              />
            </Stack>
          </Stack>
          <Stack>
            {error.path === "newLoanTerm" && (
              <Typography
                style={{
                  color: "purple",
                  fontSize: "0.75rem",
                  fontWeight: "600",
                  marginTop: "4px",
                }}
              >
                {error.message}
              </Typography>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} md={2}>
          <Stack>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Frequency
            </Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    height: "1.5rem",
                    fontSize: "14px !important",
                  },
                }}
                InputProps={{
                  sx: {
                    borderTopLeftRadius: "0rem",
                    borderBottomLeftRadius: "0rem",
                    //   marginTop: 1,
                    borderRadius: 5,
                    boxShadow: 3,
                  },
                }}
                disabled
                size="small"
                margin="normal"
                variant="outlined"
                type="text"
                name="newFrequency"
                placeholder="Frequency"
                fullWidth
                value={data.newFrequency}
                inputProps={{
                  style: { fontSize: "13px" },
                }}
                onChange={(event) => {
                  setData({
                    ...data,
                    newFrequency: event.target.value,
                  });
                }}
              />
            </Stack>
            {/* <Stack>
                {error.path === "newFrequency" && (
                  <Typography
                    style={{
                      color: "purple",
                      fontSize: "0.75rem",
                      fontWeight: "600",
                      marginTop: "4px",
                    }}
                  >
                    {error.message}
                  </Typography>
                )}
              </Stack> */}
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Stack
          mb={2}
          mt={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Button
            variant="contained"
            disableElevation
            onClick={() => calculateAmortizationSchedule()}
            sx={{
              border: "1px solid lightgray",
              width: "fit-content",

              fontWeight: 400,
              color: "#fff",
              bgcolor: "#24003D !important",
              border: "1px solid #24003D !important",
              textTransform: "none",
              borderRadius: "5px",
              px: 3,
              boxShadow:
                "rgba(17, 17, 17, 0.04) 0px 2px 4px 0px, rgba(33, 33, 33, 0.08) 0px 8px 32px 0px",
              transition: "box-shadow 0.3s ease",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            Calculate
          </Button>

          <Button
            disableElevation
            variant="contained"
            onClick={() => {
              setData({
                loanAmount: "",
                interestRate: "",
                newIntroTerm: "",
                introTerm: "",
                frequency: "Monthly",
                introRate: "",
                newIntroRate: "",
                newFrequency: "Monthly",
                newInterestRate: "",
                loanTerm: "",
                newLoanTerm: "",
              });
            }}
            sx={{
              border: "1px solid lightgray",
              width: "fit-content",
              marginLeft: "1em",
              fontWeight: 400,
              letterSpacing: 0,
              color: "#000",
              bgcolor: "#fff !important",
              textTransform: "none",
              border: "2px solid #24003D !important",
              borderRadius: "8px",
            }}
          >
            Clear All
          </Button>
        </Stack>
      </Grid>
      {isCalculated && (
        <>
          <Grid container mt={4}>
            <Grid item xs={12} mb={2}>
              <Stack>
                <Typography
                  sx={{
                    color: "#000",
                    fontWeight: "bold",
                    fontSize: "19px",
                  }}
                >
                  Results
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} mt={2}>
              <RefinanceChart
                currentLoanData={currentLoanData}
                newLoanData={newLoanData}
                loanAmount={data.loanAmount}
              />
            </Grid>
            {/* <Grid item xs={6}>
                
                <Grid container mt={12} spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Stack>
                      <Typography
                        sx={{
                          color: "#000",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        New Rate
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Stack>
                      <Typography
                        sx={{
                          color: "#000",
                          fontWeight: 400,
                          fontSize: "16px",
                        }}
                      ></Typography>
                      <Stack
                        direction={"row"}
                        mt={4}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <Typography>Intro Rate</Typography>
                      </Stack>
                    </Stack>
                    {finalResult.newIntroRateMonthlyPayment && (
                      <Stack
                        direction={"row"}
                        mt={5}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <Typography>Default Rate</Typography>
                      </Stack>
                    )}
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Stack>
                      <Typography
                        sx={{
                          color: "#000",
                          fontWeight: 400,
                          fontSize: "16px",
                        }}
                      >
                        Monthly Repayment
                      </Typography>
                      <CurrencyInput
                        placeholder="$ Total Interest Paid"
                        value={finalResult.monthlyPayment2}
                        sx={{
                          maxWidth: "180px",
                          fontSize: "14px !important",
                        }}
                        disabled={true}
                      />
                      {finalResult.newIntroRateMonthlyPayment && (
                        <CurrencyInput
                          placeholder="$ Total Interest Paid"
                          value={finalResult.newIntroRateMonthlyPayment}
                          sx={{
                            maxWidth: "180px",
                            fontSize: "14px !important",
                            marginTop: "20px",
                          }}
                          disabled={true}
                        />
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Stack>
                      <Typography
                        sx={{
                          color: "#000",
                          fontWeight: 400,
                          fontSize: "16px",
                        }}
                      >
                        Total Interest Paid
                      </Typography>

                      <CurrencyInput
                        placeholder="$ Total Interest Paid"
                        value={finalResult.totalInterest2}
                        sx={{
                          maxWidth: "180px",
                          fontSize: "14px !important",
                        }}
                        disabled={true}
                      />
                      {finalResult.newIntroRateTotalPrincipalPaid && (
                        <CurrencyInput
                          placeholder="$ Total Interest Paid"
                          value={finalResult.newIntroRateTotalPrincipalPaid}
                          sx={{
                            maxWidth: "180px",
                            fontSize: "14px !important",
                            marginTop: "20px",
                          }}
                          disabled={true}
                        />
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid> */}
          </Grid>
          <Grid container mt={1} spacing={2} py={2}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                  <Stack alignItems={"center"}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      Current Rate
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Stack alignItems={"center"}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      New Rate
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Stack alignItems={"center"}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      Savings / Lost
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2}></Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography
                    marginTop={4}
                    sx={{
                      color: "#000",
                      // fontWeight: "bold",
                      fontSize: "16px",
                      // minHeight: '100px',
                      // display: 'flex',
                      // alignItems: 'center'
                    }}
                  >
                    Intro Interest Rate
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Stack alignItems={"center"}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontWeight: 400,
                        fontSize: "16px",
                      }}
                    >
                      Monthly Repayment
                    </Typography>
                    <CurrencyInput
                      placeholder="$ Total Interest Paid"
                      // value={finalResult.monthlyPayment}
                      value={finalResult.introRateMonthlyPayment || 0}
                      sx={{
                        maxWidth: "180px",
                        fontSize: "14px !important",
                        marginTop: "5px",
                      }}
                      disabled={true}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Stack alignItems={"center"}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontWeight: 400,
                        fontSize: "16px",
                      }}
                    >
                      Monthly Repayment
                    </Typography>
                    <CurrencyInput
                      placeholder="$ Total Interest Paid"
                      value={finalResult.monthlyPayment2}
                      sx={{
                        maxWidth: "180px",
                        fontSize: "14px !important",
                        marginTop: "5px",
                      }}
                      disabled={true}
                    />
                    {/* {finalResult.newIntroRateMonthlyPayment && (
                        <CurrencyInput
                          placeholder="$ Total Interest Paid"
                          value={finalResult.newIntroRateMonthlyPayment}
                          sx={{
                            maxWidth: "180px",
                            fontSize: "14px !important",
                            marginTop: "20px",
                          }}
                          disabled={true}
                        />
                      )} */}
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Stack alignItems={"center"}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontWeight: 400,
                        fontSize: "16px",
                      }}
                    >
                      Monthly Repayment
                    </Typography>

                    <CurrencyInput
                      placeholder="$ Total Interest Paid"
                      value={
                        finalResult.monthlyPayment1 -
                        finalResult.monthlyPayment2
                      }
                      sx={{
                        maxWidth: "180px",
                        fontSize: "14px !important",
                        marginTop: "5px",
                      }}
                      disabled={true}
                    />
                    {/* <CurrencyInput
                        placeholder="$ Total Interest Paid"
                        value={
                          finalResult.monthlyPayment1 -
                          finalResult.newIntroRateMonthlyPayment
                        }
                        sx={{
                          maxWidth: "180px",
                          fontSize: "14px !important",
                          marginTop: "20px",
                        }}
                        disabled={true}
                      /> */}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2}></Grid>
          </Grid>
          <Grid container mt={"4px"} spacing={2} py={2}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography
                    marginTop={4}
                    sx={{
                      color: "#000",
                      // fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Default Interest Rate
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Stack alignItems={"center"}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontWeight: 400,
                        fontSize: "16px",
                      }}
                    >
                      Monthly Repayment
                    </Typography>
                    {/* <CurrencyInput
                    placeholder="$ Total Interest Paid"
                    value={finalResult.monthlyPayment1}
                    sx={{
                      maxWidth: "180px",
                      fontSize: "14px !important",
                    }}
                    disabled={true}
                  /> */}
                    {/* {finalResult.introRateMonthlyPayment && ( */}
                    <CurrencyInput
                      placeholder="$ Total Interest Paid"
                      value={finalResult.monthlyPayment1}
                      sx={{
                        maxWidth: "180px",
                        fontSize: "14px !important",
                        marginTop: "5px",
                      }}
                      disabled={true}
                    />
                    {/* )} */}
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Stack alignItems={"center"}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontWeight: 400,
                        fontSize: "16px",
                      }}
                    >
                      Monthly Repayment
                    </Typography>
                    {/* <CurrencyInput
                    placeholder="$ Total Interest Paid"
                    value={finalResult.monthlyPayment2}
                    sx={{
                      maxWidth: "180px",
                      fontSize: "14px !important",
                    }}
                    disabled={true}
                  /> */}
                    {finalResult.newIntroRateMonthlyPayment && (
                      <CurrencyInput
                        placeholder="$ Total Interest Paid"
                        value={finalResult.newIntroRateMonthlyPayment}
                        sx={{
                          maxWidth: "180px",
                          fontSize: "14px !important",
                          marginTop: "5px",
                        }}
                        disabled={true}
                      />
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Stack alignItems={"center"}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontWeight: 400,
                        fontSize: "16px",
                      }}
                    >
                      Monthly Repayment
                    </Typography>

                    <CurrencyInput
                      placeholder="$ Total Interest Paid"
                      value={
                        finalResult.monthlyPayment1 -
                        finalResult.newIntroRateMonthlyPayment
                      }
                      sx={{
                        maxWidth: "180px",
                        fontSize: "14px !important",
                        marginTop: "5px",
                      }}
                      disabled={true}
                    />
                    {/* <CurrencyInput
                        placeholder="$ Total Interest Paid"
                        value={
                          finalResult.monthlyPayment1 -
                          finalResult.newIntroRateMonthlyPayment
                        }
                        sx={{
                          maxWidth: "180px",
                          fontSize: "14px !important",
                          marginTop: "20px",
                        }}
                        disabled={true}
                      /> */}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2}></Grid>
          </Grid>
          <Grid container mt={"4px"} mb={2} spacing={2}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography
                    marginTop={4}
                    sx={{
                      color: "#000",
                      // fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Total Interest
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Stack alignItems={"center"}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontWeight: 400,
                        fontSize: "16px",
                      }}
                    >
                      Interest Paid
                    </Typography>
                    <CurrencyInput
                      placeholder="$ Total Interest Paid"
                      value={finalResult.totalInterest1}
                      sx={{
                        maxWidth: "180px",
                        fontSize: "14px !important",
                        marginTop: "5px",
                      }}
                      disabled={true}
                    />
                    {/* {finalResult.introRateMonthlyPayment && (
                    <CurrencyInput
                      placeholder="$ Total Interest Paid"
                      value={finalResult.introRateMonthlyPayment}
                      sx={{
                        maxWidth: "180px",
                        fontSize: "14px !important",
                        marginTop: "20px",
                      }}
                      disabled={true}
                    />
                  )} */}
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Stack alignItems={"center"}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontWeight: 400,
                        fontSize: "16px",
                      }}
                    >
                      Interest Paid
                    </Typography>
                    <CurrencyInput
                      placeholder="$ Total Interest Paid"
                      value={
                        finalResult.newIntroRateTotalPrincipalPaid
                          ? parseFloat(finalResult.totalInterest2) +
                            parseFloat(
                              finalResult.newIntroRateTotalPrincipalPaid
                            )
                          : parseFloat(finalResult.totalInterest2)
                      }
                      sx={{
                        maxWidth: "180px",
                        fontSize: "14px !important",
                        marginTop: "5px",
                      }}
                      disabled={true}
                    />
                    {/* {finalResult.newIntroRateMonthlyPayment && (
                        <CurrencyInput
                          placeholder="$ Total Interest Paid"
                          value={finalResult.newIntroRateMonthlyPayment}
                          sx={{
                            maxWidth: "180px",
                            fontSize: "14px !important",
                            marginTop: "20px",
                          }}
                          disabled={true}
                        />
                      )} */}
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Stack alignItems={"center"}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontWeight: 400,
                        fontSize: "16px",
                      }}
                    >
                      Interest Paid
                    </Typography>

                    <CurrencyInput
                      placeholder="$ Total Interest Paid"
                      value={
                        finalResult.newIntroRateTotalPrincipalPaid
                          ? parseFloat(finalResult.totalInterest1) -
                            (parseFloat(finalResult.totalInterest2) +
                              parseFloat(
                                finalResult.newIntroRateTotalPrincipalPaid
                              ))
                          : parseFloat(finalResult.totalInterest1) -
                            parseFloat(finalResult.totalInterest2)
                      }
                      sx={{
                        maxWidth: "180px",
                        fontSize: "14px !important",
                        marginTop: "5px",
                      }}
                      disabled={true}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2}></Grid>
          </Grid>
        </>
      )}
      </Container>
      <HomeFooter />
    </>
  );
};

export default PublicRefinanceCalculator;
