import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../services/auth";

export const createReport = createAsyncThunk(
  "report/create",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "report/create",
      method: "POST",
      data,
    }).then(async (response) => {
      dispatch(getReports());
      return response;
    });
    return res;
  }
);


export const getReports = createAsyncThunk(
  "get/report",
  async (data, { dispatch }) => {
    const res = await apiRequest({
        url: "/report",
      method: "GET",
      data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getReport = createAsyncThunk(
  "property/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
        url: `/report/get/${data}`,
      method: "GET",
      data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);


export const deleteReport = createAsyncThunk(
  'report/delete',
  async (id, {dispatch}) => {
    const res = await apiRequest({
      url: `report/delete`,
      method: 'DELETE',
      data: id,
    }).then(response => {
      dispatch(getReports());
      return response;
    });
    return res;
  },
);

export const updateReport = createAsyncThunk(
  'report/update',
  async (data, {dispatch}) => {
    const res = await apiRequest({
      url: `report/update/${data.data.id}`,
      method: 'PUT',
      data: data.data.updateData,
    }).then(response => {
      dispatch(getReports());
      return response;
    });
    return res;
  },
);

export const getReportSummaey = createAsyncThunk(
  "summary/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
        url: `/report/summary`,
      method: "GET",
      data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);