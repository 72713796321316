import { io, Socket } from "socket.io-client";
import { SOCKET_URL } from "../../constants/portConstants";
import { notify } from "../../utlis/handler";
import { updateNewNotification } from "../notification/notificationReducers";

import socketEvents from "./socketEvents";

const socketMiddleware = () => {
  let socket = null;

  const onMessage = (store, payload) => {
    // const payload = JSON.parse(event.data);
    console.log("receiving server message", payload);

    switch (payload.type) {
        case socketEvents.NEW_NOTIFICATION:
          if(payload.data.msgContent) notify(payload.data.msgContent, 'success' )
          store.dispatch(updateNewNotification(payload.data));
          break;
      default:
        break;
    }
  };

  return (store) => (next) => (action) => {
    const userId = store.getState().profiles.profiles._id;
    switch (action.type) {
      case socketEvents.WS_CONNECT:
        if (socket !== null) {
          socket.close();
        }
        // connect to the remote host
        socket = io(SOCKET_URL, {
          transports: ["websocket", "polling", "flashsocket"],
        });
        console.log("WebSocket created in Middleware!");
        socket.on("connect", () => {
          socket.emit(socketEvents.JOIN, userId);
        });
        socket.on(socketEvents.RECEIVE_NOTIFICATION, (data) => {
          onMessage(store, data);
        });
        break;
      case socketEvents.WS_DISCONNECT:
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        // store.dispatch(actions.wsDisconnect());
        console.log("websocket closed");
        break;
      case socketEvents.SEND_MESSAGE:
        console.log("sending a message", action);
        socket.emit(socketEvents.NEW_NOTIFICATION, action.payload);
        break;
      default:
        // console.log("the next action:", action);
    }
    next(action);
  };
};

export default socketMiddleware();
