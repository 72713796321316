import { createSlice } from '@reduxjs/toolkit';
import { cookies } from '../../services/auth';
import { getCashFlow,updateCashFlow } from './cashFlowAction';

const cashFlowSlice = createSlice({
  name: 'cashFlow',
  initialState: {
    loading: false,
    cashFlow:{
      _id: null,
    },
  },
  reducers: {
  },
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder
   
    .addCase(getCashFlow.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getCashFlow.fulfilled, (state, action) => {
      state.loading = false;
      if(action?.payload?.data){
        state.cashFlow = action?.payload?.data;
      }
    })
    .addCase(getCashFlow.rejected, (state, action) => {
      state.loading = false;
    })

   

    .addCase(updateCashFlow.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(updateCashFlow.fulfilled, (state, action) => {
      state.loading = false;
      if(action?.payload?.data){
        state.cashFlow = action?.payload?.data;
      }
    })
    .addCase(updateCashFlow.rejected, (state, action) => {
      state.loading = false;
    })
  },
});

export default cashFlowSlice.reducer;