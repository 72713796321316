export const PROTOCOL = "https";
export const SERVER_URL = "test-api.investpulse.com.au";
export const CLIENT_URL = "test.investpulse.com.au";
export const CLIENT_FQDN = `${PROTOCOL}://${CLIENT_URL}/login`;
export const BASE_URL = `${PROTOCOL}://${SERVER_URL}/api/v1/`;
export const SOCKET_URL = `wss://${SERVER_URL}`;
export const FILE_SERVER_URL = `${PROTOCOL}://${SERVER_URL}/public/`;

export const S3_FILE_STORAGE_URL = "https://investpulse-storage.s3.ap-south-1.amazonaws.com";
export const GOOGLE_API_KEY = "AIzaSyAjRPKx0LqqRQpqQKzBWqPu5MHJIPgz_v4";
export const S3_BUCKET_NAME='investpulse-storage'
export const AWS_S3_REGION="ap-south-1"
export const AWS_S3_IDENTITY_POOL_ID="ap-south-1:acb323c9-8232-4d13-92ed-bd7d47590cc2"
export const STRIPE_PRIVATE_KEY=  "pk_test_51OXclxF8sUNkYCN2lXNhMRRjZeD9j2lfx9MaEJYXxYxE6iaZBL1WuAmYe8u5kIIzZQPSGKej8H5dBN9acqDSt3lQ00K3dMMkco"