import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { cookies } from "../../services/auth";
import {
  createReminder,
  getReminder,
  deleteReminder,
  updateReminder,
  getAllReminder,
} from "./reminderActions";

const reminderSlice = createSlice({
  name: "reminder",
  initialState: {
    loading: false,
    reminders: [],
    allReminders: [],
    reminderDates: [],
    statements: [],
    reminder: {},
    filterReminders: [],
    filter: {
      startDate: moment().startOf("month").format("DD/MM/YYYY"),
      endDate: moment().endOf("month").format("DD/MM/YYYY"),
      selectedDate: moment().format("DD/MM/YYYY"),
    },
  },
  reducers: {
    updateFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder
      .addCase(createReminder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createReminder.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.property = action?.payload?.data;
        }
      })
      .addCase(createReminder.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getReminder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getReminder.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.properties = action?.payload?.data;
        }
      })
      .addCase(getReminder.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getAllReminder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllReminder.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.allReminders = action?.payload?.data?.reminders;
          state.reminderDates = action?.payload?.data?.dates;
        }
      })
      .addCase(getAllReminder.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteReminder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteReminder.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.property = action?.payload?.data;
        }
      })
      .addCase(deleteReminder.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(updateReminder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateReminder.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.property = action?.payload?.data;
        }
      })
      .addCase(updateReminder.rejected, (state, action) => {
        state.loading = false;
      });
  },
});
export const { updateFilter } = reminderSlice.actions;

export default reminderSlice.reducer;
