import { createSlice } from "@reduxjs/toolkit";
import { cookies } from "../../services/auth";
import { login, getUserProfile, loginGoogle } from "./authActions";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    profile: {
      _id: null,
    },
    // currentViewedPost: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder
      .addCase(login.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          if (
            window.location.pathname.startsWith("/admin") &&
            action?.payload?.data.isAdmin
          ) {
            cookies.set("SID", action?.payload?.data.authToken);
          } else {
            cookies.set("SID", action?.payload?.data.authToken);
          }
          window.location.reload();
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(loginGoogle.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loginGoogle.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          if (
            window.location.pathname.startsWith("/admin") &&
            action?.payload?.data.isAdmin
          ) {
            cookies.set("SID", action?.payload?.data.authToken);
          } else {
            cookies.set("SID", action?.payload?.data.authToken);
          }
          window.location.reload();
        }
      })
      .addCase(loginGoogle.rejected, (state, action) => {
        state.loading = false;
      });
    //   .addCase(getPost.pending, (state, action) => {
    //     state.loading = true;
    //   })
    //   .addCase(getPost.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.currentViewedPost = action?.payload?.data;
    //   })
    //   .addCase(getPost.rejected, (state, action) => {
    //     state.loading = false;
    //   });
  },
});

export default authSlice.reducer;
