import * as React from "react";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, maxValue, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      fixedDecimalScale={2}
      style={{
        height: 30,
        marginTop: 2,
        fontSize: "14px",
        paddingLeft: "10px",
        // border: "1px solid #ccc",
        borderRadius: 20,
      }}
      decimalScale={2} // Set the number of decimal places to 2
      suffix="%"
      maxLength={15}
      isAllowed={(values) => {
        const { floatValue } = values;
        return maxValue ? floatValue <= parseFloat(maxValue) : true;
      }}
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function PercentageInput({
  onChange = () => {},
  value = "",
  placeholder = "",
  disabled,
  maxValue = null,
  ...restStyle
}) {
  const handleChange = (event) => {
    onChange(event.target.value);
  };
  const [edit, setEdit] = React.useState(false);

  return (
    <Stack direction="row" spacing={2}>
      <TextField
        label=""
        value={value}
        fullWidth
        onChange={handleChange}
        disabled={disabled}
        name="numberformat"
        id="formatted-numberformat-input"
        {...(placeholder ? { placeholder } : {})}
        InputProps={{
          inputComponent: NumericFormatCustom,
          inputProps: {
            maxValue,
          },
          disableUnderline: true,
        }}
        sx={{
          borderRadius: 5,
          boxShadow: 3,
          border: "unset !important",
          input: {
            fontSize: "14px",
            fontWeight: "normal",
          },
          "::placeholder": {
            fontSize: "14px",
            fontWeight: "normal",
          },
          ...(restStyle.sx ? restStyle.sx : {}),
        }}
        variant="standard"
        // sx={{ border: "none",  }}
      />
    </Stack>
  );
}
