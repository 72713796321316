import moment from "moment";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getCashFlow } from "../store/cashFlow/cashFlowAction";
import { getCommon } from "../store/common/commonActions";
import { getNotification } from "../store/notification/notificationActions";
import { getProfile } from "../store/profile/profileActions";
import { getProperties } from "../store/property/propertyActions";
import { getAllReminder } from "../store/reminder/reminderActions";
import { wsConnect, wsDisconnect } from "../store/socket/socketReducers";

const PrivateRoute = ({ children }) => {
  const [cookies, setCookie] = useCookies(["SID"]);
  const profiles = useSelector((state) => state.profiles.profiles);
  const properties = useSelector((state) => state.properties.properties);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(wsConnect());
    console.log("Socket should be connected", profiles?._id);
    return () => {
      dispatch(wsDisconnect());
    };
  }, [profiles?._id]);

  useEffect(() => {
    if (profiles._id) {
      if (!profiles.isProfileUpdated) {
        if (location.pathname !== "/settings") {
          navigate("/settings", { replace: true });
        }
      } else if (profiles.personalProperty) {
        if (profiles.ownerOccupiedPropertyExists) {
          if (location.pathname.startsWith("/add-owner-occupied-property")) {
            if (location.pathname !== "/property") {
              navigate("/property", {
                replace: true,
              });
            }
          }
        } 
        // else {
        //   if (location.pathname !== "/add-owner-occupied-property") {
        //     navigate("/add-owner-occupied-property?isOwner=true", {
        //       replace: true,
        //     });
        //   }
        // }
      }
    }
  }, [profiles]);

  useEffect(() => {
    if (cookies.SID) {
      dispatch(getProfile());
      dispatch(getCommon());
      dispatch(getNotification());
      dispatch(
        getAllReminder()
      );
      dispatch(getCashFlow());
      dispatch(
        getProperties({
          sort: `property.type|1`,
        })
      );
    }
  }, [location]);

  if (!cookies.SID) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return children;
};

export default PrivateRoute;
