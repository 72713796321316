import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest, { cookies } from "../../services/auth";

export const getUser = createAsyncThunk(
  "user/get",
  async ({ data={}, onSuccess = () => {} }, { dispatch }) => {
    const res = await apiRequest({
      url: "/profile",
      method: "GET",
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const transferCashback = createAsyncThunk(
  "user/transfer",
  async ({ data={}, onSuccess = () => {} }, { dispatch }) => {
    const res = await apiRequest({
      url: "user/cashback/transfer",
      method: "POST",
      data
    }).then((response) => {
      dispatch(getUser({}))
      return response;
    });
    return res;
  }
);


export const getEndpoints = createAsyncThunk(
  "endpoint/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/endpoint/get",
      method: "GET",
    }).then((response) => {
      return response;
    });
    return res;
  }
);