import React, { useState, useRef } from "react";
import Home from "../../../components/SidebarWithTopBar";
import { Box, Grid, Stack, Typography } from "@mui/material";
import CurrencyInput from "../../../common/CurrencyInput";
import { useNavigate, useParams } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_API_KEY } from "../../../constants/portConstants";
import TextFieldComponent from "../../../common/TextFieldComponent";

const Marketplace = () => {
  const [data, setData] = useState();
  const dataRef = useRef(data);

  const [error, setError] = useState({
    path: null,
  });
  const navigate = useNavigate();

  return (
    <Home>
      <Stack marginTop={5} marginLeft={2}>
        <Grid container spacing={1} padding={5}>
          <Grid xs={12}>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: 30, fontWeight: 800 }}>
                Welcome to Marketplace
              </Typography>
              <Typography sx={{ textAlign: "center", fontSize: 18 }}>
                Great place to find investment properties curated for Property
                Investprs like yourself.
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={5}>
          <Grid
            item
            xs={6}
            width={"100%"}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                backgroundColor: "#24003D",
                padding: 1,
                width: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              <Typography>BUY</Typography>
            </Stack>
          </Grid>

          <Grid
            item
            xs={6}
            width={"100%"}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                backgroundColor: "#24003D",
                padding: 1,
                width: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              <Typography>SELL</Typography>
            </Stack>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          py={2}
          padding={10}
          style={{
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
            borderRadius: 10,
            backgroundColor: "#ffffff",
            borderRight:10
            
          }}
        >
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <Typography sx={{ fontSize: 20 }}>
                  Where would you like to buy ?
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Address</Typography>

                <Autocomplete
                  defaultValue={data?.property?.address?.fullAddress}
                  style={{
                    minHeight: "42px",
                    paddingLeft: "10px",
                    fontSize: "13px",
                    border: "1.7px solid lightgray",
                    borderWidth: 1,
                    backgroundColor: "#f6f6f6",

                    width: "100%",
                    //   boxShadow: "3px 3px 3px rgba(10, 10, 10, 0.3)",
                  }}
                  apiKey={GOOGLE_API_KEY}
                  options={{
                    types: ["geocode", "establishment"],
                    componentRestrictions: { country: "aus" },
                  }}
                  // apiKey={"AIzaSyAjRPKx0LqqRQpqQKzBWqPu5MHJIPgz_v4"}
                  onPlaceSelected={(place) => {
                    if (!place?.geometry) return;
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    const parsedAddress = {};
                    // Loop through the address components and map them to the Australian address format
                    place.address_components?.forEach((component) => {
                      const types = component.types;
                      if (types.includes("locality")) {
                        parsedAddress["suburb"] = component.long_name;
                        parsedAddress["city"] = component.long_name;
                      } else if (
                        types.includes("administrative_area_level_1")
                      ) {
                        parsedAddress["state"] = component.short_name;
                      } else if (types.includes("country")) {
                        parsedAddress["country"] = component.long_name;
                      } else if (types.includes("postal_code")) {
                        parsedAddress["postalCode"] = component.short_name;
                      } else if (
                        types.includes("route") ||
                        types.includes("street_number")
                      ) {
                        parsedAddress["addressLine1"] = parsedAddress[
                          "addressLine1"
                        ]
                          ? `${parsedAddress["addressLine1"]} ${component.short_name}`
                          : component.short_name;
                      }
                    });
                    setError({
                      path: null,
                    });
                    setData({
                      ...dataRef.current,
                      property: {
                        ...dataRef.current.property,
                        address: {
                          ...parsedAddress,
                          lat: `${lat}`,
                          lng: `${lng}`,
                          fullAddress: place.formatted_address,
                        },
                      },
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <Typography sx={{ fontSize: 20 }}>Property Type</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  Any, Acerage, Apartment/ Unit, Block of Units, House, Land,
                  Rural, Villas
                </Typography>

                <CurrencyInput
                  sx={{
                    borderRadius: 0,
                    boxShadow: 0,
                    backgroundColor: "#f6f6f6",
                    borderWidth: 2,
                  }}
                  placeholder="House, Land, Rural, Villas"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <Typography sx={{ fontSize: 20 }}>Budget</Typography>
              </Grid>
              <Grid item xs={6}>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack>
                    <Typography>Min</Typography>
                    <CurrencyInput
                      sx={{
                        border: 0,
                        boxShadow: 0,
                        borderRadius: 0,
                        borderWidth: 2,
                        borderColor: "#cccccc",
                        backgroundColor: "#f6f6f6",
                      }}
                      placeholder="$100000"
                    />
                  </Stack>
                  <Stack>
                    <Typography>Max</Typography>
                    <CurrencyInput
                      sx={{
                        border: 0,
                        boxShadow: 0,
                        borderRadius: 0,
                        borderWidth: 2,
                        borderColor: "#cccccc",
                        backgroundColor: "#f6f6f6",
                      }}
                      placeholder="$2,000,000"
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <Typography sx={{ fontSize: 20 }}>Bedrooms</Typography>
              </Grid>
              <Grid item xs={6}>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack>
                    <Typography>Min</Typography>
                    <TextFieldComponent
                      sx={{
                        border: 0,
                        boxShadow: 0,
                        borderRadius: 0,
                        borderWidth: 2,
                        borderColor: "#cccccc",
                        padding: "5px",
                        backgroundColor: "#f6f6f6",
                      }}
                      placeholder="0"
                    />
                  </Stack>
                  <Stack>
                    <Typography>Max</Typography>
                    <TextFieldComponent
                      sx={{
                        border: 0,
                        boxShadow: 0,
                        borderRadius: 0,
                        borderWidth: 2,
                        borderColor: "#cccccc",
                        padding: "5px",
                        backgroundColor: "#f6f6f6",
                      }}
                      placeholder="2"
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <Typography sx={{ fontSize: 20 }}>Bathrooms</Typography>
              </Grid>
              <Grid item xs={6}>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack>
                    <Typography>Min</Typography>
                    <TextFieldComponent
                      sx={{
                        border: 0,
                        boxShadow: 0,
                        borderRadius: 0,
                        borderWidth: 2,
                        borderColor: "#cccccc",
                        padding: "5px",
                        backgroundColor: "#f6f6f6",
                      }}
                      placeholder="0"
                    />
                  </Stack>
                  <Stack>
                    <Typography>Max</Typography>
                    <TextFieldComponent
                      sx={{
                        border: 0,
                        boxShadow: 0,
                        borderRadius: 0,
                        borderWidth: 2,
                        borderColor: "#cccccc",
                        padding: "5px",
                        backgroundColor: "#f6f6f6",
                      }}
                      placeholder="3+"
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <Typography sx={{ fontSize: 20 }}>Carpark</Typography>
              </Grid>
              <Grid item xs={6}>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack>
                    <Typography>Min</Typography>
                    <TextFieldComponent
                      sx={{
                        border: 0,
                        boxShadow: 0,
                        borderRadius: 0,
                        borderWidth: 2,
                        borderColor: "#cccccc",
                        padding: "5px",
                        backgroundColor: "#f6f6f6",
                      }}
                      placeholder="0"
                    />
                  </Stack>
                  <Stack>
                    <Typography>Max</Typography>
                    <TextFieldComponent
                      sx={{
                        border: 0,
                        boxShadow: 0,
                        borderRadius: 0,
                        borderWidth: 2,
                        borderColor: "#cccccc",
                        padding: "5px",
                        backgroundColor: "#f6f6f6",
                      }}
                      placeholder="3+"
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <Typography sx={{ fontSize: 20 }}>ROI</Typography>
              </Grid>
              <Grid item xs={6}>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack>
                    <Typography>Min</Typography>

                    <TextFieldComponent
                      sx={{
                        border: 0,
                        boxShadow: 0,
                        borderRadius: 0,
                        borderWidth: 2,
                        borderColor: "#cccccc",
                        padding: "5px",
                        backgroundColor: "#f6f6f6",
                      }}
                      placeholder="0"
                    />
                  </Stack>
                  <Stack>
                    <Typography>Max</Typography>

                    <TextFieldComponent
                      sx={{
                        border: 0,
                        boxShadow: 0,
                        borderRadius: 0,
                        borderWidth: 2,
                        borderColor: "#cccccc",
                        padding: "5px",
                        backgroundColor: "#f6f6f6",
                      }}
                      placeholder="10"
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <Typography sx={{ fontSize: 20 }}>Vacancy Rate</Typography>
              </Grid>
              <Grid item xs={6}>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack>
                    <Typography>Min</Typography>
                    <TextFieldComponent
                      sx={{
                        border: 0,
                        boxShadow: 0,
                        borderRadius: 0,
                        borderWidth: 2,
                        borderColor: "#cccccc",
                        padding: "5px",
                        backgroundColor: "#f6f6f6",
                      }}
                      placeholder="0.00"
                    />
                  </Stack>
                  <Stack>
                    <Typography>Max</Typography>
                    <TextFieldComponent
                      sx={{
                        border: 0,
                        boxShadow: 0,
                        borderRadius: 0,
                        borderWidth: 2,
                        borderColor: "#cccccc",
                        padding: "5px",
                        backgroundColor: "#f6f6f6",
                      }}
                      placeholder="5.0"
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <Typography sx={{ fontSize: 20 }}>Keywords</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Keywords</Typography>
                <TextFieldComponent
                  sx={{
                    border: 0,
                    boxShadow: 0,
                    borderRadius: 0,
                    borderWidth: 2,
                    borderColor: "#cccccc",
                    padding: "5px",
                    backgroundColor: "#f6f6f6",
                  }}
                  placeholder="Keywords"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack
              mt={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack
                onClick={() => navigate("/market-search-result")}
                sx={{
                  backgroundColor: "#24003D",
                  padding: 1,
                  width: "20%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                <Typography>Search</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          mt={5}
          padding={10}
          style={{
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
            borderRadius: 10,
            backgroundColor: "#ffffff",
          }}
        >
          <Stack
            direction={"row"}
            width={"100%"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack>
              <Typography
                textAlign={"center"}
                sx={{ fontSize: 25, fontWeight: 800 }}
              >
                8+
              </Typography>
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: "gray" }}>
                Properties Matched
              </Typography>
            </Stack>
            <Stack>
              <Typography
                textAlign={"center"}
                sx={{ fontSize: 25, fontWeight: 800 }}
              >
                78+
              </Typography>
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: "gray" }}>
                Properties Matched
              </Typography>
            </Stack>
            <Stack>
              <Typography
                textAlign={"center"}
                sx={{ fontSize: 25, fontWeight: 800 }}
              >
                47+
              </Typography>
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: "gray" }}>
                Properties Matched
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Stack>
    </Home>
  );
};

export default Marketplace;
