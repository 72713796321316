import React from "react";
// import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiToggleButton from '@mui/material/ToggleButton';

const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: selectedColor,
  },
}));

const theme = createTheme({
  palette: {
    text: {
      primary: '#00ff00',
    },
  },
});



function CustomSwitch({ value, onChange,disabled }) {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={onChange}
      size="small"
      disabled={disabled}
      sx={{ border: "1px solid #ccc", borderRadius: '15px' }}
    >
      <ToggleButton value="yes" selectedColor="#24003D" sx={{ width: "50px",borderRadius: '15px', }}>
        Yes
      </ToggleButton>
      <ToggleButton value="no" selectedColor="#808080" sx={{ width: "50px",borderRadius: '15px' }}>
        No
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default CustomSwitch;
