import { createAsyncThunk } from "@reduxjs/toolkit";
import { buildQueryParams } from "../../helpers/functions";
import apiRequest from "../../services/auth";
import { getProfile } from "../profile/profileActions";

export const createProperty = createAsyncThunk(
  "property/create",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/property/create",
      method: "POST",
      data,
    }).then(async (response) => {
      return response;
    });
    return res;
  }
);

export const getProperties = createAsyncThunk(
  "get/property",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: `/property?${buildQueryParams(data)}`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getfilteredProperties = createAsyncThunk(
  "get/filterproperty",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: `/property/filterProperties?${buildQueryParams(data)}`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getProperty = createAsyncThunk(
  "property/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/property/get?${buildQueryParams(data)}`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const deleteProperty = createAsyncThunk(
  "property/delete",
  async (id, { dispatch }) => {
    const res = await apiRequest({
      url: `property/delete`,
      method: "DELETE",
      data: id,
    }).then((response) => {
      dispatch(getProperties({
        sort: `property.type|1`
      }))
      dispatch(getProfile())
      return response;
    });
    return res;
  }
);

export const updateProperty = createAsyncThunk(
  "property/update",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `property/update/${data.data.property}`,
      method: "PUT",
      data: data.data.updateData,
    }).then((response) => {
      dispatch(getProperties({
        sort: `property.type|1`
      }))
      dispatch(getProperty({ propertyId: data.data.property }));
      return response;
    });
    return res;
  }
);

//add expense

export const createExpense = createAsyncThunk(
  "expense/create",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/expense/create",
      method: "POST",
      data,
    }).then(async (response) => {
      dispatch(getProperty({ propertyId: response?.data.property }));
      return response;
    });
    return res;
  }
);

export const bulkUploadExpense = createAsyncThunk(
  "expense/create",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/expense/bulk-upload",
      method: "POST",
      data,
    }).then(async (response) => {
      // dispatch(getProperty({ propertyId: response?.data.property }));
      return response;
    });
    return res;
  }
);

export const getExpense = createAsyncThunk(
  "expense/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/expense/get/${data}`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const updateExpense = createAsyncThunk(
  "expense/update",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `expense/update/${data.data.documentId}`,
      method: "PUT",
      data: data.data.update,
    }).then((response) => {
      dispatch(getProperty({ propertyId: response?.data.property }));
      return response;
    });
    return res;
  }
);

//add document

export const createDocument = createAsyncThunk(
  "Document/create",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/document/create",
      method: "POST",
      data,
    }).then(async (response) => {
      dispatch(getProperty({ propertyId: response?.data.property }));
      return response;
    });
    return res;
  }
);

export const getDocument = createAsyncThunk(
  "document/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/document/get/${data}`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getAllDocuments = createAsyncThunk(
  "getAllDocuments/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/document`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getDocumentsType = createAsyncThunk(
  "documents/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/document/property/${data.propertyId}`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getStatementType = createAsyncThunk(
  "documentType/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/document/property/${data.propertyId}/${data.documentType}`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const updateDocument = createAsyncThunk(
  "document/update",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `document/update/${data.data.documentId}`,
      method: "PUT",
      data: data.data.update,
    }).then((response) => {
      dispatch(getProperty({ propertyId: response?.data.property }));
      return response;
    });
    return res;
  }
);

//add income

export const createIncome = createAsyncThunk(
  "income/create",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/income/create",
      method: "POST",
      data,
    }).then(async (response) => {
      dispatch(getProperty({ propertyId: response?.data.property }));
      return response;
    });
    return res;
  }
);

export const bulkUploadIncomes = createAsyncThunk(
  "income/bulk-upload",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/income/bulk-upload",
      method: "POST",
      data,
    }).then(async (response) => {
      // dispatch(getProperty({ propertyId: response?.data.property }));
      return response;
    });
    return res;
  }
);

export const getIncome = createAsyncThunk(
  "income/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/income/get/${data}`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const updateIncome = createAsyncThunk(
  "income/update",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `income/update/${data.data.documentId}`,
      method: "PUT",
      data: data.data.update,
    }).then((response) => {
      dispatch(getProperty({ propertyId: response?.data.property }));
      return response;
    });
    return res;
  }
);
