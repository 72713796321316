import { createAsyncThunk } from "@reduxjs/toolkit";
import { buildQueryParams } from "../../helpers/functions";
import apiRequest, { cookies } from "../../services/auth";

export const createNotification = createAsyncThunk(
  "notification/create",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/notification/create",
      method: "POST",
      data,
    }).then(async (response) => {
      dispatch(getAllNotification());
      return response;
    });
    return res;
  }
);

export const getNotification = createAsyncThunk(
  "notification/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/notification/`,
      method: "GET",
      data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const deleteNotification = createAsyncThunk(
  "notification/delete",
  async (id, { dispatch }) => {
    const res = await apiRequest({
      url: `notification/delete`,
      method: "DELETE",
      data: id,
    }).then((response) => {
      dispatch(getAllNotification());
      return response;
    });
    return res;
  }
);


export const clearNotification = createAsyncThunk(
  "notification/clear",
  async (id, { dispatch }) => {
    const res = await apiRequest({
      url: `notification/clear`,
      method: "POST",
    }).then((response) => {
      dispatch(getAllNotification());
      return response;
    });
    return res;
  }
);

export const updateNotification = createAsyncThunk(
  "notification/update",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `notification/update/${data.data.property}`,
      method: "PUT",
      data: data.data.updateData,
    }).then((response) => {
      dispatch(getNotification({ propertyId: data.data.property }));
      return response;
    });
    return res;
  }
);

export const getAllNotification = createAsyncThunk(
  "get/notification",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/notification",
      method: "GET",
      data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

