import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest, { cookies } from "../../services/auth";

export const getCommon = createAsyncThunk(
  "user/common",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "common/",
      method: "GET",
      data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);
