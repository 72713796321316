import * as yup from "yup";

const ownershipErrorMessage =
  "Ownership is required and must be a number between 1 and 100.";
const nameErrorMessage =
  "This field is required and must be at most 30 characters.";
const requiredErrorMessage = "This field is required.";

export const cashflowSchema = yup.object().shape({
  investmentPeriod: yup.string().required(requiredErrorMessage),
  annualDeductions: yup.string().required(requiredErrorMessage),
  weeklyRent: yup.string().required(requiredErrorMessage),
  rentalGrowth: yup.string().required(requiredErrorMessage),
  interestRate: yup.string().required(requiredErrorMessage),
});
