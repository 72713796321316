import axios from "axios";
import { BASE_URL } from "../../constants/portConstants";
import { Cookies } from "react-cookie";
import { notify } from "../../utlis/handler";
export const cookies = new Cookies();

axios.defaults.baseURL = BASE_URL;
const timeout = 250000;
let prevErrorMessage = "";

export default async function apiRequest(apiParams) {
  const token = await cookies.get("SID");
  if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  axios.defaults.crossDomain = true;
  const parseErrorMessage = (error) => {
    if (error.code === "ECONNABORTED") {
      return "Please check your network connection and try again.";
    }
    return typeof error.message === "object"
      ? Object.values(error.message).join(", ")
      : error.message;
  };
  const cancelSource = axios.CancelToken.source(); 
  apiParams.cancelToken = cancelSource.token;

  return await axios({ timeout, ...apiParams })
    .then((response) => {
      let resp = {
        data: response?.data
          ? response?.data.data
            ? response?.data.data
            : response?.data.data === null
            ? response?.data.data
            : response?.data
          : response,
        message: response?.data
          ? response?.data.data && response?.data.data.message
            ? response?.data.data.message
            : response?.data.message && response?.data.message
          : null,
        status: response.status,
        success: response?.data.error ? false : true,
      };
      if (resp.data && resp.message) {
        notify(resp.message, "success");
      }

      return resp;
    })
    .catch((error) => {
      // Check if the request was cancelled
      if (axios.isCancel(error)) {
        return; // Return or handle as necessary
      }

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (
          !apiParams.skipError &&
          (error.response.status == 401 ||
            (error.response?.data &&
              error.response?.data.message &&
              [
                "jwt malformed",
                "The user does not exist or inactive",
                "The user does not exist or session is expired!",
                "User not found!",
              ].includes(error.response?.data.message)))
        ) {
          if (prevErrorMessage !== error.response?.data.message) {
            cancelSource.cancel("Request cancelled due to logout");
            notify(error.response?.data.message || "Unauthorized");
            prevErrorMessage = error.response?.data.message;
            setTimeout(() => {
              // Cancel all pending requests
              cookies.remove("SID");
              prevErrorMessage = "";
              // window.location.reload();
              return;
            }, 1000);
          }
        } else if (
          error?.response?.data?.message &&
          error?.response?.data?.message !== "Validation failed"
        ) {
          notify(error.response?.data.message);
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
      } else {
        // Something happened in setting up the request that triggered an Error
      }
      const errorObj =
        error &&
        error.response &&
        error.response?.data &&
        error.response?.data &&
        error.response?.data
          ? error.response?.data
          : error.toJSON();
      let message = parseErrorMessage(errorObj);
      // notify(message);
      return {
        ...errorObj,
        message,
        // data: errorObj.data&& errorObj.data. data ? errorObj.data.data : {},
        // name: err.name,
        // stack: exception.stack,
        success: false,
      };
    });
}
