import {
  DownloadOutlined,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  Dialog,
  DialogActions,
  MenuItem,
  Paper,
  Popover,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import CircularProgress from "@mui/material/CircularProgress";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonDatePicker from "../../../common/CommonDatePicker";
import apiRequest from "../../../services/auth";
import { getProperties } from "../../../store/property/propertyActions";
import Home from "../../../components/SidebarWithTopBar";
// import CloseIcon from "@material-ui/icons/Close";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import CloseIcon from "../../../assests/close.png";
import ExcelIcon from "../../../assests/excel.png";
import PropertyPopover from "../../../components/PropertyPopover";
import Emailicon from "../../../assests/emailicon.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  filterFinancialYears,
  findStartFinancialYearDate,
} from "../../../helpers/reports";
import { usePDF } from "react-to-pdf";
import { notify } from "../../../utlis/handler";
const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: "100%",
    maxWidth: "100%",
    overflowX: "scroll",
  },
});

const RentalYieldView = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [initialState, setInitialState] = useState({
    startDate: null,
    endDate: null,
    category: [],
  });
  const [appliedFilter, setAppliedFilter] = useState({
    startDate: null,
    endDate: null,
    category: [],
  });
  const [filter, setFilter] = useState(appliedFilter);

  const dispatch = useDispatch();
  const [result, setResult] = useState({
    columns: [],
    rows: [],
  });
  const [selectedAction, setSelectedAction] = useState([]);
  const { EXPENSE_TYPES, FINANCIAL_YEARS } = useSelector(
    (state) => state.common.data
  );
  const [fy, setFy] = useState([]);
  const allProperties = useSelector((state) => state.properties.properties);
  const [selectedFullAddress, setSelectedFullAddress] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [category, setCategory] = React.useState([]);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [exportAsPDF, setExportAsPDF] = React.useState(false);
  const [exportAsExcel, setExportAsExcel] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState("PDF");
  const [selectedProperties, setSelectedProperties] = React.useState([]);
  const [propertyModal, setNoPropertyModal] = React.useState(false);
  const [fiscalYears, setFiscalYears] = useState([]);
  const [appliedFy, setAppliedFy] = useState([]);
  const { toPDF, targetRef } = usePDF({ filename: "Rental Yield.pdf" });
  const navigate = useNavigate();
  const mounted = useRef();

  const { PROPERTY_TYPES } = useSelector((state) => state.common.data);
  const [selectedPropertiesPurchasedate, setSelectedPropertiesPurchasedate] =
    React.useState([]);

  const properties = allProperties.filter(
    (property) => !property?.property.personalProperty
  );

  const handleOpen1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleApplyButtonClick = () => {
    // if (selectedOption === "PDF") {
    //   toPDF();
    // } else if (selectedOption === "Excel") {
    //   getReport(selectedOption.toLowerCase());
    // } else {
      getReport(selectedOption.toLowerCase());
    // }
    // handleClose1();
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getReport = async (format) => {
    try {
      setLoading(true); // Start loading
      const currentDate = moment();
      const formattedDate = currentDate.format("DDMMYYYY");
  
      let endpoint = `/report/rental-yield-view?property=${selectedProperties.join(
        ","
      )}&fy=${appliedFy
        .map((f) => f.value)
        .sort()
        .join(",")}`;
  
      if (format) endpoint = `${endpoint}&export=${format}`;
  
      const response = await apiRequest({
        url: endpoint,
        method: "GET",
        ...(!format
          ? {}
          : { responseType: format === "pdf" ? "blob" : "blob" }),
      });
  
      setLoading(false); // Stop loading
  
      if (!format) {
        if (response.data) {
          setResult({ ...response.data });
        } else {
          setResult({
            columns: [],
            rows: [],
          });
        }
        return;
      }
  
      if (response.data && format !== "email") {
        if (format === "pdf") {
          const blob = new Blob([response.data], {
            type: "application/pdf",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `Rental Yield ${formattedDate}.pdf`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }  else if (format === "email") {
          notify(
            "Your Rental Yield report has been sent to your email successfully",
            "success"
          );
        } else {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
  
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `Rental Yield ${formattedDate}.xlsx`;
  
          document.body.appendChild(link);
          link.click();
  
          document.body.removeChild(link);
        }
      }
    } catch (error) {
      console.error(`Error exporting ${format ? format.toUpperCase() : "report"}:`, error);
      setLoading(false); // Stop loading on error
    }
  };
  
  useEffect(() => {
    if (
      selectedProperties.length == 0 &&
      properties.length > 0 &&
      !mounted.current
    ) {
      setSelectedProperties(properties.map((property) => property._id));
      mounted.current = true;
    }
  }, [selectedProperties, properties]);

  useEffect(() => {
    if (selectedProperties.length > 0 && appliedFy.length > 0) {
      getReport();
    } else {
      setResult({
        columns: [],
        rows: [],
      });
    }
  }, [appliedFy, selectedProperties]);

  React.useEffect(() => {
    if (selectedProperties?.length == 0 && properties.length === 0) {
      setNoPropertyModal(true);
    } else {
      setNoPropertyModal(false);
    }
  }, [selectedProperties]);

  useEffect(() => {
    if (selectedProperties.length > 0) {
      const oldestDate = findStartFinancialYearDate(
        properties.filter((property) =>
          selectedProperties.includes(property._id)
        )
      );
      if (oldestDate && FINANCIAL_YEARS.length > 0) {
        const fiscalYears = filterFinancialYears(
          FINANCIAL_YEARS,
          new Date(oldestDate)
        );
        setFiscalYears(fiscalYears.reverse());
        setFy(fiscalYears);
        setAppliedFy(fiscalYears);
        setFilter({
          ...filter,
          startDate: moment.utc(fiscalYears[0].startDate).startOf("day"),
          endDate: moment
            .utc(fiscalYears[fiscalYears.length - 1].endDate)
            .endOf("day"),
        });
        setAppliedFilter({
          ...appliedFilter,
          startDate: moment.utc(fiscalYears[0].startDate).startOf("day"),
          endDate: moment
            .utc(fiscalYears[fiscalYears.length - 1].endDate)
            .endOf("day"),
        });
      }
    }
  }, [selectedProperties, FINANCIAL_YEARS]);

  useEffect(() => {
    if (fiscalYears.length > 0 && !appliedFilter.startDate) {
      setFilter({
        ...filter,
        startDate: moment.utc(fiscalYears[0].startDate).startOf("day"),
        endDate: moment
          .utc(fiscalYears[fiscalYears.length - 1].endDate)
          .endOf("day"),
      });
      setAppliedFilter({
        ...appliedFilter,
        startDate: moment.utc(fiscalYears[0].startDate).startOf("day"),
        endDate: moment
          .utc(fiscalYears[fiscalYears.length - 1].endDate)
          .endOf("day"),
      });
    }
  }, [fiscalYears, appliedFilter]);

  return (
    <>
      <Home>
        <Stack mt={9}>
          <Stack
            direction={"row"}
            width={"100%"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          ></Stack>
        </Stack>

        <Stack
          display={"flex"}
          direction={"row"}
          justifyContent={"space-between"}
          mt={1}
        >
          <Stack direction={"row"}>
            <Stack>
              <PropertyPopover
                properties={properties}
                propertyTypes={PROPERTY_TYPES}
                setSelectedProperties={setSelectedProperties}
                selectedProperties={selectedProperties}
                buttonLabel={
                  properties.find(
                    (p) =>
                      p._id ==
                      (selectedProperties.length > 0 && selectedProperties[0])
                  )?.property.address.fullAddress || ""
                }
              />
            </Stack>
          </Stack>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Typography
              sx={{
                color: "#0f2e5a",
                fontWeight: 600,
                fontSize: "16px",

                cursor: "pointer",
              }}
              py={1}
              px={1}
              display={"flex"}
              alignItems={"center"}
              onClick={handleOpen}
            >
              <FilterListIcon sx={{ fontSize: 20, marginRight: "2px" }} />
              Filters
            </Typography>
            <Box>
              <Typography
                sx={{
                  color: "#0f2e5a",
                  fontWeight: 600,
                  fontSize: "16px",

                  cursor: "pointer",
                }}
                display={"flex"}
                alignItems={"center"}
                onClick={handleOpen1}
              >
                <DownloadOutlined sx={{ fontSize: 20, marginRight: "2px" }} />
                Download
              </Typography>

              <Popover
                open={Boolean(anchorEl1)}
                anchorEl={anchorEl1}
                onClose={handleClose1}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Box sx={{ p: 2, width: "200px" }}>
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{
                          color: "#89309c",
                        }}
                        checked={selectedOption === "PDF"}
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={
                          <RadioButtonChecked style={{ color: "#24003D" }} />
                        }
                        onChange={handleRadioChange}
                        value="PDF"
                      />
                    }
                    label={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <PictureAsPdfIcon sx={{ marginRight: 1 }} />
                        PDF
                      </Box>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{
                          color: "#89309c",
                        }}
                        checked={selectedOption === "Excel"}
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={
                          <RadioButtonChecked style={{ color: "#24003D" }} />
                        }
                        onChange={handleRadioChange}
                        value="Excel"
                      />
                    }
                    label={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={ExcelIcon}
                          style={{
                            marginRight: "10px",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        Excel
                      </Box>
                    }
                  />

                  <FormControlLabel
                    control={
                      <Radio
                        sx={{
                          color: "#89309c",
                        }}
                        checked={selectedOption === "Email"}
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={
                          <RadioButtonChecked style={{ color: "#24003D" }} />
                        }
                        onChange={handleRadioChange}
                        value="Email"
                      />
                    }
                    label={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={Emailicon}
                          style={{
                            marginRight: "10px",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        Email
                      </Box>
                    }
                  />

                  <Button
                    variant="contained"
                    sx={{
                      width: "170px",
                      background: "#24003D !important",
                      py: 3,
                      color: "#fff !important",
                      textTransform: "capitalize",
                      "&:hover": {
                        background: "#24003D !important",
                      },
                      paddingTop: "10px !important",
                      paddingBottom: "10px !important",
                    }}
                    onClick={handleApplyButtonClick}
                    style={{ marginTop: "10px" }}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} sx={{ color: "white" }} />
                    ) : (
                      "Apply"
                    )}
                  </Button>
                </Box>
              </Popover>
            </Box>
          </Stack>
        </Stack>
        <Popover
          open={anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Paper
            sx={{
              position: "relative",
              padding: 3,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                Filters
              </Typography>
              <Typography sx={{ cursor: "pointer" }} onClick={handleClose}>
                <img
                  src={CloseIcon}
                  width={"25px"}
                  height={"25px"}
                  loading="lazy"
                  alt="close-icon"
                />
                {/* <CloseIcon onClick={handleClose} /> */}
              </Typography>
            </div>

            <Stack
              direction={"row"}
              justifyContent={"space-around"}
              spacing={2}
            >
              <Stack>
                <Typography
                  sx={{
                    color: "#0f2e5a",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  Financial Year
                </Typography>
                <FormControl>
                  <Select
                    sx={{
                      fontSize: "13px",
                      width: 200, // Set the initial width of the select component
                      height: 41,
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          overflowY: "scroll",
                          position: "relative",
                          width: 200,
                          height: "300px",
                          scrollbarWidth: "thin",
                          scrollbarColor: "gray lightgray",
                        },
                      },
                    }}
                    value={fy}
                    multiline={true}
                    multiple={true}
                    // MenuProps={{
                    //   PaperProps: {
                    //     style: {
                    //       overflowY: "auto",
                    //       position: "relative",
                    //       width: 200, // Set the width of the dropdown menu
                    //     },
                    //   },
                    // }}
                    renderValue={(selected) => (
                      <Stack direction={"row"}>
                        {selected.map(({ value, label }, idx) => (
                          <Typography key={value}>
                            {label}
                            {idx < selected.length - 1 && (
                              <Typography component={"span"}>,</Typography>
                            )}
                          </Typography>
                        ))}
                      </Stack>
                    )}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"end"}
                      justifyContent={"end"}
                      sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        width: 200,
                        bgcolor: "#fff",
                        height: "30px",
                        marginLeft: "-8px",
                      }}
                    >
                      <Button
                        sx={{
                          textTransform: "capitalize",
                          color: "#24003D",
                          fontSize: "12px",
                        }}
                        onClick={() => {
                          if (
                            fy.length > 0 &&
                            fy.length === fiscalYears.length
                          ) {
                            setFy([]);
                          } else {
                            setFy(fiscalYears);
                          }
                        }}
                      >
                        {fy.length > 0 && fy.length === fiscalYears.length
                          ? "Unselect All"
                          : "Select All"}
                      </Button>
                    </Stack>

                    {fiscalYears.slice().map((financialYear, index) => (
                      <MenuItem
                        onClick={() => {
                          let tmp = [...fy];
                          if (
                            tmp.some((t) => t.value === financialYear.value)
                          ) {
                            tmp = tmp.filter(
                              (t) => t.value !== financialYear.value
                            );
                          } else {
                            tmp.push(financialYear);
                          }
                          setFy(tmp);
                        }}
                        key={index}
                        value={financialYear}
                      >
                        <Checkbox
                          checked={fy.some(
                            (f) => f.value === financialYear.value
                          )}
                          icon={<RadioButtonUnchecked />}
                          checkedIcon={
                            <RadioButtonChecked style={{ color: "#24003D" }} />
                          }
                        />
                        {financialYear.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
            <Stack direction={"row"} justifyContent={"end"} spacing={2} mt={5}>
              <Button
                variant="outlined"
                sx={{
                  color: "#24003D !important",
                  borderColor: "#24003D !important",
                  textTransform: "capitalize",
                  "&.MuiButton-outlined": {
                    borderColor: "#24003D !important",
                  },
                  "&:hover": {
                    background: "#fff",
                    color: "#24003D !important",
                  },
                }}
                onClick={() => {
                  setFy(fiscalYears);
                  setAppliedFy(fiscalYears);
                  setAppliedFilter({ ...initialState });
                  setFilter({ ...initialState });
                  setAnchorEl(null);
                }}
              >
                Clear All
              </Button>
              <Button
                variant="contained"
                sx={{
                  background: "#24003D !important",
                  color: "#fff !important",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: "#24003D !important",
                  },
                }}
                onClick={() => {
                  const { minStartDate, maxEndDate } = fy.reduce(
                    (acc, curr) => {
                      if (
                        !acc.minStartDate ||
                        curr.startDate < acc.minStartDate
                      ) {
                        acc.minStartDate = curr.startDate;
                      }
                      if (!acc.maxEndDate || curr.endDate > acc.maxEndDate) {
                        acc.maxEndDate = curr.endDate;
                      }
                      return acc;
                    },
                    { minStartDate: null, maxEndDate: null }
                  );

                  setFy([...fy]);
                  setAppliedFy([...fy]);
                  setAppliedFilter({
                    ...filter,
                    startDate: moment.utc(minStartDate).startOf("day"),
                    endDate: moment.utc(maxEndDate).endOf("day"),
                  });
                  setAnchorEl(null);
                }}
              >
                Apply
              </Button>
            </Stack>
          </Paper>
        </Popover>
        <span ref={targetRef}>
          <Stack spacing={2} mt={2}>
            <Stack
              display={"flex"}
              justifyContent={"space-between"}
              direction={"row"}
              alignItems={"center"}
            >
              <Stack spacing={1}>
                <Typography
                  sx={{
                    color: "#0F2E5A",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  Property Address
                </Typography>

                {properties.map((property, index) => {
                  return (
                    selectedProperties.includes(property._id) && (
                      <Typography
                        px={1}
                        key={index}
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <AddHomeWorkIcon style={{ paddingRight: "7px" }} />
                          {property?.property?.address?.fullAddress}
                        </Typography>
                      </Typography>
                    )
                  );
                })}
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  <span style={{ fontWeight: 600, color: "#0F2E5A" }}>
                    From
                  </span>{" "}
                  {appliedFilter?.startDate?.format("DD-MM-YYYY")}{" "}
                  <span style={{ fontWeight: 600, color: "#0F2E5A" }}>to</span>{" "}
                  {appliedFilter?.endDate?.format("DD-MM-YYYY")}
                </Typography>
              </Stack>
            </Stack>
            {/* </Paper> */}
          </Stack>
          <Grid
            px={1}
            container
            justifyContent={"center"}
            spacing={{ xs: 0, md: 2 }}
            mt={{ xs: 8, md: 4 }}
          >
            <Grid item xs={12}>
              <Paper sx={{ boxShadow: 0 }} className={classes.root}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: "#24003D",
                      }}
                    >
                      <TableCell
                        sx={{
                          position: "sticky",
                          left: 0,
                          zIndex: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "17px",
                            backgroundColor: "#24003D",
                          }}
                        ></Typography>
                      </TableCell>
                      {result.columns.map((column, index) => (
                        <TableCell key={index} align="center">
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: "#fff",
                              minWidth: "80px",
                              maxWidth: "100%",
                            }}
                            align="center"
                          >
                            {column}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {result.rows.map((report, index) => {
                    return (
                      <TableBody>
                        <TableRow
                          style={{
                            backgroundColor: "#24003D",
                          }}
                        >
                          <TableCell
                            align="start"
                            sx={{
                              position: "sticky",
                              left: 0,
                              zIndex: 1,
                              backgroundColor: "#24003D",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: "17px",
                                textTransform: "capitalize",
                                color: "#fff",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {report.type}
                            </Typography>
                          </TableCell>
                          {[...Array(result.columns.length)].map((_, index) => (
                            <TableCell key={`empty-${index - 1}`}></TableCell>
                          ))}
                        </TableRow>
                        {report.report.map((row, rowIndex) => {
                          const expense = EXPENSE_TYPES.find(
                            ({ label }) => label === row.label
                          );
                          const isShow =
                            appliedFilter.category.length == 0 ||
                            appliedFilter.category.includes(expense?.value);
                          return (
                            <React.Fragment key={rowIndex}>
                              {isShow && (
                                <TableRow
                                  style={{
                                    backgroundColor:
                                      row.label === "Total Income" ||
                                      row.label === "Net Rental Yield(%)"
                                        ? "#24003D"
                                        : row.label === "Gross Rental Yield(%)"
                                        ? "#888888"
                                        : "#f6f6f6",
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      position: "sticky",
                                      left: 0,
                                      zIndex: 1,
                                    }}
                                    style={{
                                      backgroundColor:
                                        row.label === "Total Income" ||
                                        row.label === "Net Rental Yield(%)"
                                          ? "#24003D"
                                          : row.label ===
                                            "Gross Rental Yield(%)"
                                          ? "lightgray"
                                          : "#f6f6f6",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight:
                                          row.label === "Total Income" ||
                                          row.label === "Net Rental Yield(%)"
                                            ? "bold"
                                            : row.label ===
                                              "Gross Rental Yield(%)"
                                            ? "bold"
                                            : "normal",
                                        fontSize: "15px",
                                        color:
                                          row.label === "Total Income" ||
                                          row.label === "Net Rental Yield(%)"
                                            ? "#fff"
                                            : "#202020",
                                      }}
                                    >
                                      {expense?.label || row.label}
                                    </Typography>
                                  </TableCell>
                                  {result.columns.map((column, columnIndex) => (
                                    <TableCell
                                      key={`${rowIndex}-${columnIndex}`}
                                      align="center"
                                      style={{
                                        fontWeight:
                                          column == "Total" ||
                                          row.label === "Total Income" ||
                                          row.label === "Net Rental Yield(%)"
                                            ? "bold"
                                            : row.label ===
                                              "Gross Rental Yield(%)"
                                            ? "bold"
                                            : "normal",
                                        fontSize: "15px",
                                        backgroundColor:
                                          row.label === "Total Income" ||
                                          row.label === "Net Rental Yield(%)"
                                            ? "#24003D"
                                            : row.label ===
                                              "Gross Rental Yield(%)"
                                            ? "lightgray"
                                            : "#f6f6f6",
                                        color:
                                          row.label === "Total Income" ||
                                          row.label === "Net Rental Yield(%)"
                                            ? "#fff"
                                            : "#202020",
                                      }}
                                    >
                                      {/* {row &&
                                    row[column] !== undefined &&
                                    row.label === "Net Rental Yield(%)"
                                      ? row[column] + "%"
                                      : row[column] !== 0
                                      ? "$" + row[column]
                                      : "-"} */}

                                      {row &&
                                      row[column] !== undefined &&
                                      [
                                        "Net Rental Yield(%)",
                                        "Gross Rental Yield(%)",
                                      ].includes(row.label)
                                        ? `${row[column]}%`
                                        : row[column] !== 0
                                        ? new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                          }).format(row[column])
                                        : "-"}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </TableBody>
                    );
                  })}
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </span>
      </Home>
      <Dialog
        open={propertyModal}
        hideBackdrop={true}
        maxWidth="sm"
        onClose={() => setNoPropertyModal(false)}
        fullWidth
      >
        <Box p={6}>
          {/* <Stack>
            <Typography
              sx={{
                alignSelf: "center",
                mt: 2,
                color: "grey",
                fontSize: 20,
              }}
            >
              No Properties Found
            </Typography>
          </Stack> */}
          <DialogActions
            sx={{
              marginBottom: 2,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* <Button
            disableElevation
            sx={{
              border: "1px solid lightgray",
              width: "fit-content",

              fontWeight: 600,
              letterSpacing: 0,
              color: "#000",
              bgcolor: "#fff !important",
              textTransform: "none",
              border: "2px solid #24003D !important",
              borderRadius: "8px",
            }}
            color="primary"
            variant="contained"
            onClick={() => navigate("/reports")}
          >
            Back
          </Button> */}
            <Button
              onClick={() => navigate("/add-property")}
              disableElevation
              sx={{
                border: "1px solid lightgray",
                width: "fit-content",
                fontWeight: 600,
                color: "#fff",
                bgcolor: "#24003D !important",
                border: "1px solid #24003D !important",
                textTransform: "none",
                borderRadius: "5px",
                px: 4,
                boxShadow:
                  "rgba(17, 17, 17, 0.04) 0px 2px 4px 0px, rgba(33, 33, 33, 0.08) 0px 8px 32px 0px",
                transition: "box-shadow 0.3s ease",
                "&:hover": {
                  boxShadow: "none",
                },
              }}
            >
              Add Property
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default RentalYieldView;
