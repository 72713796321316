import { createSlice } from "@reduxjs/toolkit";
import {
  getPortfolioHealthLvr,
  getPortfolioIncomeExpense,
  getPortfolioRentalYield,
  getPortfolios,
  getPortfolioProperties,
  getPortfolioExpenseCategories,
  getPropertyChart
} from "./portfolioHoldingActions";

const portfolioSlice = createSlice({
  name: "portfolio",
  initialState: {
    loading: false,
    portfolios: [],
    portfolioProperties: [],
    portfolioIncomeExpense: [],
    portfolioHealthLvr: [],
    portfolioRentalYield: [],
    portfolioExpenseCategories:[],
    propertyCharts:[]
  },
  reducers: {},
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder

      .addCase(getPortfolios.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPortfolios.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.portfolios = action?.payload?.data;
        }
      })
      .addCase(getPortfolios.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getPropertyChart.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPropertyChart.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.propertyCharts = action?.payload?.data;
        }
      })
      .addCase(getPropertyChart.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getPortfolioExpenseCategories.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPortfolioExpenseCategories.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.portfolioExpenseCategories = action?.payload?.data;
        }
      })
      .addCase(getPortfolioExpenseCategories.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getPortfolioProperties.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPortfolioProperties.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.portfolioProperties = action?.payload?.data;
        }
      })
      .addCase(getPortfolioProperties.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getPortfolioIncomeExpense.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPortfolioIncomeExpense.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.portfolioIncomeExpense = action?.payload?.data;
        }
      })
      .addCase(getPortfolioIncomeExpense.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getPortfolioHealthLvr.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPortfolioHealthLvr.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.portfolioHealthLvr = action?.payload?.data;
        }
      })
      .addCase(getPortfolioHealthLvr.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getPortfolioRentalYield.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPortfolioRentalYield.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.portfolioRentalYield = action?.payload?.data;
        }
      })
      .addCase(getPortfolioRentalYield.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default portfolioSlice.reducer;
