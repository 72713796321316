import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Pasted from "../../../assests/Calculater Theme.jpg";
import Home from "../../../assests/Calculator.jpg";
import bgImg from "../../../assests/Maths.jpg";
import Header from "../../../components/Header";
import HomeFooter from "../HomeFooter";
import { useLocation, useNavigate, useParams } from "react-router-dom";
const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const email = "info@investpulse.com.au";

  return (
    <>
      <Header />
      <Container maxWidth="lg">
        <Grid container mb={10}>
          <Grid item xs={12} md={12}>
            <Stack mt={3} alignItems={"center"} justifyContent={"center"}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                PRIVACY POLICY
              </Typography>
            </Stack>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                1
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  INTRODUCTION
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={2}>
                  This document sets out the privacy policy of Gritpro Pty Ltd
                  ACN 613 057 097 (referred to in this privacy policy as
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    ‘we’, ‘us’,
                  </span>{" "}
                  or{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    ‘our’
                  </span>{" "}
                  )
                </Typography>
                <Typography>
                  We take our privacy obligations seriously and we’ve created
                  this privacy policy to explain how we store, maintain, use and
                  disclose personal information.
                </Typography>
                <Typography>
                  By providing personal information to us, you consent to our
                  storage, maintenance, use and disclosing of personal
                  information in accordance with this privacy policy.
                </Typography>
                <Typography>
                  We may change this privacy policy from time to time by posting
                  an updated copy on our website and we encourage you to check
                  our website regularly to ensure that you are aware of our most
                  current privacy policy
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                2
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  TYPES OF PERSONAL INFORMATION WE COLLECT
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={2}>
                  The personal information we collect may include the following:{" "}
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>name;</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>mailing or street address;</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>email address;</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(d)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>social media information;</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(e)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    telephone number and other contact details;
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(f)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>age;</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(g)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>date of birth;</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(h)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    credit card or other payment information;
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(i)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    information about your business or personal circumstances;
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(j)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    information in connection with client surveys,
                    questionnaires and promotions;
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(k)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    your device identity and type, I.P. address, geo-location
                    information, page view statistics, advertising data and
                    standard web log information;
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(l)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>information about third parties; and</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(m)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    any other information provided by you to us via our website
                    or our online presence, or otherwise required by us or
                    provided by you.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                3
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  HOW WE COLLECT PERSONAL INFORMATION{" "}
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={2}>
                  We may collect personal information either directly from you,
                  or from third parties, including where you{" "}
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>contact us through our website;</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>receive goods or services from us;</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    submit any of our online sign up forms;
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(d)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    communicate with us via email, telephone, SMS, social
                    applications (such as LinkedIn or Facebook) or otherwise;
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(e)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    interact with our website, social applications, services,
                    content and advertising; and
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(f)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    invest in our business or enquire as to a potential purchase
                    in our business.
                  </Typography>
                </Grid>
              </Grid>
              <Stack spacing={1} mt={1}>
                <Typography mt={2}>
                  We may also collect personal information from you when you use
                  or access our website or our social media pages. This may be
                  done through use of web analytics tools, ‘cookies’ or other
                  similar tracking technologies that allow us to track and
                  analyse your website usage. Cookies are small files that store
                  information on your computer, mobile phone or other device and
                  enable and allow the creator of the cookie to identify when
                  you visit different websites. If you do not wish information
                  to be stored as a cookie, you can disable cookies in your web
                  browser.{" "}
                </Typography>
                <Typography>
                  We may use Google Analytics to collect and process data,
                  including when you use third party websites or apps. To find
                  out more see&nbsp;
                  <span>
                    <a
                    style={{
                        color:"#33c7c1"
                    }}
                      href="https://policies.google.com/technologies/partner-sites"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      How Google uses data when you use our partners’ sites or
                      apps.
                    </a>
                  </span>
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                4
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  USE OF YOUR PERSONAL INFORMATION{" "}
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={2}>
                  We collect and use personal information for the following
                  purposes:{" "}
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    to provide goods, services or information to you;
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    for record keeping and administrative purposes;
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    to provide information about you to our contractors,
                    employees, consultants, agents or other third parties for
                    the purpose of providing goods or services to you;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(d)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    to improve and optimise our service offering and customer
                    experience;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(e)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    to comply with our legal obligations, resolve disputes or
                    enforce our agreements with third parties;{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(f)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    to send you marketing and promotional messages and other
                    information that may be of interest to you and for the
                    purpose of direct marketing (in accordance with the Spam
                    Act). In this regard, we may use email, SMS, social media or
                    mail to send you direct marketing communications. You can
                    opt out of receiving marketing materials from us by using
                    the opt-out facility provided (e.g. an unsubscribe link);{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(g)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    to send you administrative messages, reminders, notices,
                    updates, security alerts, and other information requested by
                    you; and{" "}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={1}>
                  <Typography>(h)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    to consider an application of employment from you.{" "}
                  </Typography>{" "}
                </Grid>
              </Grid>
              <Stack spacing={1} mt={1}>
                <Typography mt={2}>
                  We may disclose your personal information to cloud-providers,
                  contractors and other third parties located inside or outside
                  of Australia. If we do so, we will take reasonable steps to
                  ensure that any overseas recipient deals with such personal
                  information in a manner consistent with how we deal with it{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                5
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  SECURITY
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={3}>
                  We take reasonable steps to ensure your personal information
                  is secure and protected from misuse or unauthorised access.
                  Our information technology systems are password protected, and
                  we use a range of administrative and technical measures to
                  protect these systems. However, we cannot guarantee the
                  security of your personal information.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                6
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  LINKS
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={3}>
                  Our website may contain links to other websites. Those links
                  are provided for convenience and may not remain current or be
                  maintained. We are not responsible for the privacy practices
                  of those linked websites and we suggest you review the privacy
                  policies of those websites before using them.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                7
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  REQUESTING ACCESS OR CORRECTING YOUR PERSONAL INFORMATION
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={3}>
                  If you wish to request access to the personal information we
                  hold about you, please contact us using the contact details
                  set out below including your name and contact details. We may
                  need to verify your identity before providing you with your
                  personal information. In some cases, we may be unable to
                  provide you with access to all your personal information and
                  where this occurs, we will explain why. We will deal with all
                  requests for access to personal information within a
                  reasonable timeframe.
                </Typography>
                <Typography>
                  If you think that any personal information we hold about you
                  is inaccurate, please contact us using the contact details set
                  out below and we will take reasonable steps to ensure that it
                  is corrected.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                8
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  COMPLAINTS{" "}
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={3}>
                  If you wish to complain about how we handle your personal
                  information held by us, please contact us using the details
                  set out below including your name and contact details. We will
                  investigate your complaint promptly and respond to you within
                  a reasonable timeframe.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                9
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  CONTACT US
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={3}>
                  For further information about our privacy policy or practices,
                  or to access or correct your personal information, or make a
                  complaint, please contact us using the details set out below:{" "}
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>Name:</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>Customer Support </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>Email:</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography color={"#33c7c1"}>
                    <a style={{
                        color:"#33c7c1"
                    }} href={`mailto:${email}`}>{email}</a>
                  </Typography>
                </Grid>
              </Grid>
              <Stack spacing={1}>
                <Typography>
                  Our privacy policy was last updated on 3 April 2024.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <HomeFooter />
    </>
  );
};

export default PrivacyPolicy;
