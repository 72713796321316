import * as yup from "yup";

const ownershipErrorMessage =
  "Ownership is required and must be a number between 1 and 100.";
const nameErrorMessage =
  "This field is required and must be at most 30 characters.";
const requiredErrorMessage = "This field is required.";

export const lvrSchema = yup.object().shape({
  investmentPeriod: yup.string().required(requiredErrorMessage),
  capitalGrowth: yup.string().required(requiredErrorMessage),
  interestRate: yup.string().required(requiredErrorMessage),
});
