import { createAsyncThunk } from "@reduxjs/toolkit";
import { buildQueryParams } from "../../helpers/functions";
import apiRequest, { cookies } from "../../services/auth";

export const createReminder = createAsyncThunk(
  "reminder/create",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/reminder/create",
      method: "POST",
      data,
    }).then(async (response) => {
      dispatch(getAllReminder());
      return response;
    });
    return res;
  }
);

export const getReminder = createAsyncThunk(
  "reminder/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/reminder/get?${buildQueryParams(data)}`,
      method: "GET",
      data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const deleteReminder = createAsyncThunk(
  "reminder/delete",
  async (_id, { dispatch }) => {
    const res = await apiRequest({
      url: `reminder/delete`,
      method: "DELETE",
      data: {_id},
    }).then((response) => {
      dispatch(getAllReminder());
      return response;
    });
    return res;
  }
);

export const updateReminder = createAsyncThunk(
  "property/update",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `property/update/${data.data.property}`,
      method: "PUT",
      data: data.data.updateData,
    }).then((response) => {
      dispatch(getReminder({ propertyId: data.data.property }));
      return response;
    });
    return res;
  }
);

export const getAllReminder = createAsyncThunk(
  "get/reminder",
  async (data, { dispatch, getState }) => {
    const { startDate, endDate, selectedDate } = getState().reminders.filter;
    const res = await apiRequest({
      url: `/reminder?${buildQueryParams({startDate, endDate, selectedDate})}`,
      method: "GET",
      data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);
