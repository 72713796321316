import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from "recharts";
import "./index.css";
const LoanRepaymentChart = ({ data, loanAmount }) => {
  const chartData =
    data &&
    data.map((entry) => ({
      years: entry.month / 12,
      repayments: parseFloat(entry.outstandingLoanBalance),
    }));
  const existingChartData = [
    {
      years: 0,
      repayments: loanAmount,
    },
  ];
  const combinedChartData = [
    ...existingChartData,
    ...(Array.isArray(chartData) ? chartData : []),
  ];
  const formatYAxis = (tick) => `$${tick.toLocaleString()}`;

  const tooltipFormatter = (value, name, props) => {
    return (
      <>
        <div>
          <p>Remaining Loan: ${props.payload.repayments.toLocaleString()}</p>
          {/* {/ <p>Years: {props.payload.years.toFixed(2)}</p> /} */}
        </div>
      </>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const years = payload[0].payload.years.toFixed(2);
      const repayments = payload[0].payload.repayments.toLocaleString();

      return (
        <div className="custom-tooltip" style={tooltipStyle}>
          <div style={tooltipContentStyle}>
            <p>Years: {years}</p>
            <p>Remaining Loan: ${repayments}</p>
          </div>
        </div>
      );
    }

    return null;
  };

  const tooltipStyle = {
    background: "#fff",
    width: "200px",
  };

  const tooltipContentStyle = {
    paddingTop: "5%",
    paddingBottom: "5%",
    paddingLeft: "5%",
  };
  const formatTotalValue = (total) => {
    let formattedTotal = "";
    const isValidTotal = typeof total === "number" && !isNaN(total);

    if (isValidTotal) {
      if (total >= 1000000000) {
        formattedTotal =
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(total / 1000000000) + " B";
      } else if (total >= 1000000) {
        formattedTotal =
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(total / 1000000) + " M";
      } else if (total >= 1000) {
        formattedTotal =
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(total / 1000) + " K";
      } else {
        formattedTotal = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(total);
      }
    }
    return formattedTotal;
  };

  return (
    <LineChart width={500} height={300} data={combinedChartData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="years" padding={{ left: 0, right: 0 }} height={50}>
        <Label
          value="Years"
          position="insideBottom"
          style={{
            fontSize: "16px",
            fontWeight: "bold",
          }}
        />
      </XAxis>
      <YAxis tickFormatter={formatTotalValue} width={100}>
        <Label
          value="Loan Amount"
          position="insideLeft"
          angle={-90}
          style={{ textAnchor: "middle", fontSize: "16px", fontWeight: "bold" }}
        />
      </YAxis>
      <Tooltip content={<CustomTooltip />} />

      <Legend />
      <Line
        type="monotone"
        dataKey="repayments"
        stroke="#ea1777"
        activeDot={{ r: 8 }}
        fill="#ea1777"
        name="Loan Repayments"
      />
    </LineChart>
  );
};

export default LoanRepaymentChart;
