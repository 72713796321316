import { createSlice } from "@reduxjs/toolkit";
import { cookies } from "../../services/auth";
import {
  getPricings,
} from "./paymentActions";

const pricingSlice = createSlice({
  name: "pricing",
  initialState: {
    loading: false,
    pricings: [],
    income: null
    
  },
  reducers: {},
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder
    
      .addCase(getPricings.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPricings.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.pricings = action?.payload?.data;
        }
      })
      .addCase(getPricings.rejected, (state, action) => {
        state.loading = false;
      })


  },
});

export default pricingSlice.reducer;
