import React, { useState, useEffect, useRef } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Tooltip from "@mui/material/Tooltip";
import {
  Box,
  Popper,
  Typography,
  Stack,
  Avatar,
  ClickAwayListener,
  Button,
  Divider,
  Badge,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import apiRequest from "../../services/auth";
import {
  clearNotification,
  deleteNotification,
} from "../../store/notification/notificationActions";

const Notifications = () => {
  const dispatch = useDispatch();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [notificationNotSeen, setNotificationNotSeen] = useState(false);
  const [lastNotificationId, setLastNotificationId] = useState(null);
  const profiles = useSelector((state) => state.profiles.profiles);
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState([]);

  const notifications = useSelector(
    (state) => state.notifications.notifications
  );

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleToggleExpand = (index) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };

  const handleClick = async (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (notifications.length > 0) {
      const response = await apiRequest({
        url: "/user/notification/read",
        method: "PUT",
        data: {
          notificationId: notifications[0]._id,
        },
      });
      setNotificationNotSeen(false);
      setLastNotificationId(notifications[0]._id);
    }
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  useEffect(() => {
    if (notifications?.length > 0) {
      if (lastNotificationId != notifications[0]._id) {
        setNotificationNotSeen(true);
      } else {
        setNotificationNotSeen(false);
        setLastNotificationId(notifications[0]._id);
      }
    }
  }, [lastNotificationId, notifications]);

  useEffect(() => {
    if (profiles?._id) {
      setLastNotificationId(profiles.lastReadNotificationId || null);
    }
  }, [profiles]);

  return (
    <>
      <Tooltip
        title="Notifications"
        open={tooltipOpen}
        onClose={handleTooltipClose}
        disableFocusListener
        disableTouchListener
      >
        <Badge variant={notificationNotSeen ? "dot" : "standard"} color="error">
          <NotificationsIcon
            onClick={handleClick}
            onMouseEnter={handleTooltipOpen}
            onMouseLeave={handleTooltipClose}
            sx={{ fontSize: 20, cursor: "pointer" }}
          />{" "}
        </Badge>
      </Tooltip>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ position: "fixed", top: 0, left: 0, zIndex: 9999 }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            sx={{
              border: 1,
              borderRadius: 2,
              boxShadow: 2,
              bgcolor: "background.paper",
              width: 400,
              height: "500px",
              overflow: "scroll",
              position: "relative",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Stack
              direction={"row"}
              sx={{
                bgcolor: "#24003d",
                height: "60px",
                padding: 1,
                justifyContent: "space-between",
                alignItems: "center",
                position: "sticky",
                top: 0,
                zIndex: 1,
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "18px",
                }}
              >
                Notifications
              </Typography>
              <CloseIcon
                sx={{
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={handleClickAway}
              />
            </Stack>
            <Stack padding={2} spacing={1}>
              {/* Notifications */}
              {notifications.length > 0 && (
                <Stack alignItems={"flex-end"}>
                  <Typography
                    sx={{
                      // fontWeight: "500",
                      fontSize: "15px",
                      color: "#24003D",
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontWeight: "bold",
                      // fontStyle:'italic'
                    }}
                    onClick={() => dispatch(clearNotification())}
                  >
                    Clear all
                  </Typography>
                </Stack>
              )}
              {notifications.map((notification, index) => (
                <Stack key={index} spacing={1}>
                  <Box display="flex" alignItems="center" width="100%">
                    <Typography
                      sx={{
                        fontSize: "15px",
                        color: "#24003D",
                        whiteSpace: expanded[index] ? "normal" : "nowrap",
                        overflow: expanded[index] ? "visible" : "hidden",
                        textOverflow: expanded[index] ? "clip" : "ellipsis",
                        flexGrow: 1,
                      }}
                    >
                      {notification.msgContent}
                    </Typography>
                    <Button
                      sx={{
                        fontSize: "13px",
                        textTransform: "none",
                        padding: 0,
                        minWidth: "auto",
                        marginLeft: "8px",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => handleToggleExpand(index)}
                    >
                      {expanded[index] ? "see less" : "see more"}
                    </Button>
                  </Box>
                  <Typography
                    sx={{
                      color: "gray",
                      fontWeight: "400",
                      fontSize: "13px",
                    }}
                  >
                    {moment(notification.createdAt).fromNow()}
                  </Typography>
                  <Divider />
                </Stack>
              ))}
            </Stack>
            {/* <Stack
              direction={"row"}
              sx={{
                bgcolor: "#ffffff",
                height: "60px",
                padding: 1,
                justifyContent: "space-between",
                alignItems:"center",
                position: "sticky",
                bottom: 0,
                zIndex: 1,
                boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)", // Box shadow
                borderBottom: "1px solid #ccc", // Line
              }}
            >
              <Stack sx={{
                cursor:"pointer"
              }} direction={"row"} alignItems={"center"} spacing={1}>
                <DoneAllIcon sx={{
                  color:"#000"
                }} />
                <Typography
                  sx={{
                    color: "#000",
                    fontWeight: "500",
                    fontSize: "13px",
                  }}
                >
                  Mark all as read
                </Typography>
              </Stack>
              <Button
                variant="contained"
                disableElevation
                sx={{
                  border: "1px solid lightgray",
                  width: "fit-content",
                  height: "33px",
                  fontWeight: 600,
                  color: "#fff",
                  bgcolor: "#24003D !important",
                  border: "1px solid #24003D !important",
                  textTransform: "none",
                  borderRadius: "5px",
                  px: 2,
                  boxShadow:
                    "rgba(17, 17, 17, 0.04) 0px 2px 4px 0px, rgba(33, 33, 33, 0.08) 0px 8px 32px 0px",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
              >
                Clear all
              </Button>
            </Stack> */}
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default Notifications;
