import * as yup from "yup";

const ownershipErrorMessage =
  "Ownership is required and must be a number between 1 and 100.";
const nameErrorMessage =
  "This field is required and must be at most 30 characters.";
const requiredErrorMessage = "This field is required.";
const maxRupeesErrorMessage = "Loan Terms years cannot be more than 30 years";

export const lvrSchema = yup.object().shape({
  finalPayment: yup.string().required(requiredErrorMessage),
  loanTerms: yup.number()
               .typeError("Loan terms must be a number")
               .max(30, maxRupeesErrorMessage)
               .required(requiredErrorMessage),
  paymentFrequency: yup.string().required(requiredErrorMessage),
  firstPaymentDate: yup.string().required(requiredErrorMessage),
  interestRate: yup.string().required(requiredErrorMessage),
  loanAmount: yup.string().required(requiredErrorMessage),
});
