import { createSlice } from '@reduxjs/toolkit';
import { cookies } from '../../services/auth';
import { getPrivacy,updatePrivacy } from './PrivacyAction';

const privacySlice = createSlice({
  name: 'privacy',
  initialState: {
    loading: false,
    privacy:{
      _id: null,
    },
  },
  reducers: {
  },
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder
   
    .addCase(getPrivacy.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getPrivacy.fulfilled, (state, action) => {
      state.loading = false;
      if(action?.payload?.data){
        state.privacy = action?.payload?.data;
      }
    })
    .addCase(getPrivacy.rejected, (state, action) => {
      state.loading = false;
    })

   

    .addCase(updatePrivacy.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(updatePrivacy.fulfilled, (state, action) => {
      state.loading = false;
      if(action?.payload?.data){
        state.privacy = action?.payload?.data;
      }
    })
    .addCase(updatePrivacy.rejected, (state, action) => {
      state.loading = false;
    })
  },
});

export default privacySlice.reducer;