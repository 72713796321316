export const PROTOCOL = "https";
export const SERVER_URL = "api.investpulse.com.au";
export const CLIENT_URL = "investpulse.com.au";
export const CLIENT_FQDN = `${PROTOCOL}://${CLIENT_URL}/login`;
export const BASE_URL = `${PROTOCOL}://${SERVER_URL}/api/v1/`;
export const SOCKET_URL = `wss://${SERVER_URL}`;
export const FILE_SERVER_URL = `${PROTOCOL}://${SERVER_URL}/public/`;

export const S3_FILE_STORAGE_URL = "https://investpulse-prod-storage.s3.ap-southeast-2.amazonaws.com";
export const GOOGLE_API_KEY = "AIzaSyAjRPKx0LqqRQpqQKzBWqPu5MHJIPgz_v4";
export const S3_BUCKET_NAME='investpulse-prod-storage'
export const AWS_S3_REGION="ap-southeast-2"
export const AWS_S3_IDENTITY_POOL_ID="ap-southeast-2:f05db543-eae7-4a17-9724-86670cd266e3"
export const STRIPE_PRIVATE_KEY= "pk_live_51OUG81J5xVVWK3Q2dvl8UhaAh3n84Bd4Md031sJlQvc4NYwOULyIUUynuo8v4i1xpR3GSBYlQjhEsKB19F3MsoyY00DjqBV33x"